import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import './DashboardPage.scss';
import DashboardTable from '../../components/app-dashboard/DashboardTable.js';
import LoaderDots from '../../components/loader/Loader.js';
import i18n from '../../components/translate/i18n.js';
import { fetchAllAnalyses, fetchAllGroups, fetchAllTags } from '../../features/api/apiSlice.js';
import { setPagination } from '../../features/paginationSlice.js';

function DashboardPage() {
  const { t } = useTranslation();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { analyses, status } = useSelector((state) => state.allAnalyses);

  const defaultLimit = parseInt(process.env.REACT_APP_DEFAULT_LIMIT, 10);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await dispatch(fetchAllGroups()).unwrap();
      await dispatch(fetchAllTags()).unwrap();
      dispatch(fetchAllAnalyses(defaultLimit)).unwrap();
      setIsLoading(false);
    };
    fetchData();
  }, [location]);

  useEffect(() => {
    dispatch(setPagination(1));
  }, [i18n.language]);

  return isLoading ? (
    <div className="loader-dots">
      <LoaderDots />
    </div>
  ) : (
    <div className="dashboard-screen">
      <div className="title-container">
        <div className="title">{t('DashboardPage.screenTitle')}</div>
        <div className="bottom-border" />
      </div>

      {analyses.length > 0 && (
        <div className="dashboard-container">
          <DashboardTable />
        </div>
      )}

      {status === 'succeeded' && analyses.length < 1 && (
        <div className="dashboard-container">
          <div className="no-analyses-msg">
            {t('DashboardPage.dashboardTable.noResultsFound')}
          </div>
        </div>
      )}
    </div>
  );
}

export default DashboardPage;
