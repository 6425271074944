import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import './OfflineFallback.scss';
import Icon from '../../assets/images/fail.png';

function OfflineFallback() {
  const { t } = useTranslation();

  return (
    <div className="offline_fallback_ctr">
      <img src={Icon} alt="file-icon" width={100} />
      <div className="offline_error">{t('Alerts.noNetworkTitle')}</div>
      <div className="offline_txt">{t('Alerts.noNetwork')}</div>
      <Link reloadDocument to className="offline_refresh_txt">
        {t('Message.refresh')}
      </Link>
    </div>
  );
}

function OfflineBoundary() {
  return <OfflineFallback />;
}

export default OfflineBoundary;
