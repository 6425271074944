import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import ApplicationItem from '../application-item/ApplicationItem.js';
import ShortcutItem from '../shortcut-item/ShortcutItem.js';
import dashboard from '../../assets/images/dashboard.png';

function DashboardApp({ child }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const useStyles = makeStyles()({
    dashboard: {
      backgroundColor: '#33a237',
      borderColor: '#33a237',
      '&:hover': { backgroundColor: '#2f9638' },
    },
  });
  const { classes } = useStyles();

  const handleClick = () => {
    navigate('/dashboard');
  };

  const defaultApp = (
    <ApplicationItem
      title={t('DashboardPage.screenTitle')}
      color={classes.dashboard}
      icon={dashboard}
      size={80}
      action={handleClick}
    />
  );

  const shortcutApp = (
    <ShortcutItem
      title={t('DashboardPage.screenTitle')}
      color={classes.dashboard}
      icon={dashboard}
      size={30}
      action={handleClick}
    />
  );

  return (
    <>
      {child === 'defaultApp' && defaultApp}

      {child === 'shortcutApp' && shortcutApp}
    </>
  );
}

export default DashboardApp;
