/* eslint-disable import/no-extraneous-dependencies */
import { Mutex } from 'async-mutex';
import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { fetchLogout, setRefreshToken } from '../userSlice.js';
import instance from '../../services/instanceApi.js';
import { setProductEndpoint } from '../../services/setProductEndpoint.js';
import { cancelFile, fileUploadProgress } from '../../utils/handleUploadFile.js';

const mutex = new Mutex(); // https://redux-toolkit.js.org/rtk-query/usage/customizing-queries#automatic-re-authorization-by-extending-fetchbasequery
const baseQuery = fetchBaseQuery({
  baseUrl: instance.defaults.baseURL,
  prepareHeaders: (headers, { getState }) => {
    const token = getState().user.accessToken;
    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

export const fetchRefreshToken = createAsyncThunk(
  'auth/refreshToken',
  async (_, { dispatch, getState }) => {
    const { refreshToken } = getState().user;
    try {
      const response = await instance.post('/refresh', {
        refreshToken,
      });

      dispatch(setRefreshToken(response.data));
      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  },
);

const baseQueryWithReauth = async (args, api, extraOptions) => {
  // wait until the mutex is available without locking it
  await mutex.waitForUnlock();
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    // checking whether the mutex is locked
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      try {
        const refreshResult = await api.dispatch(fetchRefreshToken());
        if (refreshResult.payload) {
          // store the new token
          api.dispatch(setRefreshToken(refreshResult.payload));
          // retry the initial query
          result = await baseQuery(args, api, extraOptions);
        } else {
          api.dispatch(fetchLogout());
          api.util.resetApiState();
        }
      } finally {
        // release must be called once the mutex should be released again.
        release();
      }
    } else {
      // wait until the mutex is available without locking it
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }
  return result;
};

export const fetchAllGroups = createAsyncThunk(
  'groups/fetchAll',
  async (_, { getState }) => {
    const { productsList } = getState().products;
    try {
      const data = await Promise.all(
        productsList.map(async (item) => {
          const { productName } = item;
          const response = await instance.get(
            `/products/${productName}/groups`,
          );
          return response.data;
        }),
      );
      return data;
    } catch (error) {
      throw new Error(error.message);
    }
  },
);

export const fetchTags = createAsyncThunk(
  'tags/fetch',
  async (_, { getState }) => {
    const { productName } = getState().products?.product || {};
    const { groupId } = (typeof getState().groups?.group[0] !== 'undefined' && getState().groups?.group[0]) || {};
    try {
      const response = await instance.get(
        `/products/${productName}/tags?groupId=${groupId}`,
      );
      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  },
);

export const fetchAllTags = createAsyncThunk(
  'tags/fetchAll',
  async (_, { getState }) => {
    const { productsList } = getState().products;
    const { groupList } = getState().groups;
    try {
      const data = await Promise.all(
        await groupList.map(async (item, i) => {
          const product = productsList.find(
            (key) => key.productId === item.productConfiguration.productId,
          );
          const { groupId } = groupList[i];
          const response = await instance.get(
            `/products/${product?.productName}/tags?groupId=${groupId}`,
          );
          return response.data;
        }),
      );
      return data;
    } catch (error) {
      throw new Error(error.message);
    }
  },
);

export const fetchAnalyses = createAsyncThunk(
  'analyses/fetchAll',
  async (args, { getState }) => {
    const {
      startDate, endDate, limit, offset,
    } = args;
    const { productName } = getState().products.product;
    const productEndpoint = setProductEndpoint(productName);
    const { groupId } = typeof getState().groups.group[0] !== 'undefined'
        && getState().groups.group[0];
    const timeout = { timeout: 15000 };
    const checkStartDate = !!(typeof startDate !== 'undefined' && startDate !== null && !Number.isNaN(startDate));
    const checkEndDate = !!(typeof endDate !== 'undefined' && endDate !== null && !Number.isNaN(endDate));
    try {
      let url = `/products/${productName}/${productEndpoint}?groupId=${groupId}&limit=${limit}&offset=${offset}`;
      if (checkStartDate) url += `&startDate=${startDate}`;
      if (checkEndDate) url += `&endDate=${endDate}`;
      const response = await instance.get(url, timeout);
      const totalCount = response.headers['x-total-count'];
      return { data: response.data, totalCount };
    } catch (error) {
      throw new Error(error.message);
    }
  },
);

export const fetchAllAnalyses = createAsyncThunk(
  'dashboard_analyses/fetchAll',
  async (limit, { getState }) => {
    const { productsList } = getState().products;
    try {
      const data = await Promise.all(
        await productsList.map(async (item) => {
          const response = await instance.get(
            `/products/${item.productName}/analyses?limit=${limit}`,
          );
          const dataWithProductName = response.data.map((element) => Object.assign(
            element,
            { product: item.name },
          ));
          return dataWithProductName;
        }),
      );
      return data.reduce((accumulator, currentValue) => accumulator.concat(currentValue), []);
    } catch (error) {
      throw new Error(error.message);
    }
  },
);

export const deleteAnalysis = createAsyncThunk(
  'analyses/delete',
  async (_, { getState }) => {
    const { selectedAnalyses } = getState().filters;
    const { productsList } = getState().products;

    try {
      const data = await Promise.all(
        selectedAnalyses.map(async (item) => {
          const product = productsList.find(
            (p) => p?.name === item?.product && p.productName,
          );
          const response = await instance.delete(`/products/${product?.productName}/analyses/${item?.id}?deleteInput=${true}`);
          return response.data;
        }),
      );
      return data;
    } catch (error) {
      throw new Error(error.message);
    }
  },
);

export const fetchActivityAnalyses = createAsyncThunk(
  'activity/fetch',
  async (args, { getState }) => {
    const {
      tagIds, startDate, endDate, timeStep,
    } = args;
    const { productName } = getState().products.product;
    const checkStartDate = !!(typeof startDate !== 'undefined' && startDate !== null && !Number.isNaN(startDate));
    const checkEndDate = !!(typeof endDate !== 'undefined' && endDate !== null && !Number.isNaN(endDate));
    const timeout = { timeout: 30000 };
    try {
      let url = `/products/${productName}/activities?timeStep=${timeStep}`;
      if (checkStartDate) url += `&startDate=${startDate}`;
      if (checkEndDate) url += `&endDate=${endDate}`;
      if (typeof tagIds !== 'undefined' && tagIds?.length > 0) url += `&tagIds=${[tagIds]}`;
      const response = await instance.get(url, timeout);
      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  },
);

export const fetchInput = createAsyncThunk(
  'input/fetch',
  async (args, { getState }) => {
    const { product, inputId } = args;
    const { productsList } = getState().products;
    const prd = productsList.find((p) => p?.name === product);
    try {
      const response = await instance.get(
        `/products/${prd.productName}/inputs/${inputId}`,
      );
      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  },
);

export const editTagInput = createAsyncThunk(
  'input/edit',
  async (_, { getState }) => {
    const { selectedAnalyses } = getState().filters;
    const { productName } = getState().products.product;
    const { tagId } = getState().tags?.tag || {};
    try {
      const data = await Promise.all(
        selectedAnalyses.map(async (item) => {
          const response = await instance.put(`/products/${productName}/inputs/${item?.input?.id}`, {
            tagId,
          });
          return response.data;
        }),
      );
      return data;
    } catch (error) {
      throw new Error(error.message);
    }
  },
);

export const deleteTagInput = createAsyncThunk(
  'input/delete',
  async (_, { getState }) => {
    const { selectedAnalyses } = getState().filters;
    const { productName } = getState().products.product;
    try {
      const data = await Promise.all(
        selectedAnalyses.map(async (item) => {
          // PUT tagId to null in order to remove tag from input
          const response = await instance.put(`/products/${productName}/inputs/${item?.input?.id}`, {
            tagId: null,
          });
          return response.data;
        }),
      );
      return data;
    } catch (error) {
      throw new Error(error.message);
    }
  },
);

let cancel = [];
export const uploadFiles = createAsyncThunk(
  'transmission/upload',
  async (args, { getState }) => {
    const {
      fileInfo, fileIndex, tag, transmission, setTransmission,
    } = args;
    const { productName } = getState().products.product;
    const { groupId } = getState().groups?.group[0] || {};
    const { CancelToken } = axios;

    const createFormS3 = (fileS3, jsonPreSignedRequest) => {
      const form = new FormData();
      Object.keys(jsonPreSignedRequest.fields).forEach(
        (key) => form.append(key, jsonPreSignedRequest.fields[key]),
      );
      form.append('file', fileS3);
      return form;
    };

    const createBodyRequest = () => {
      let bodyRequest = {
        filename: fileInfo.name,
        groupId,
      };
      if (tag.length !== 0) {
        bodyRequest = Object.assign(bodyRequest, { tagId: tag?.tagId });
      }
      return bodyRequest;
    };

    const formPreSigned = createBodyRequest();

    const config = {
      cancelToken: new CancelToken((c) => {
        if (transmission > cancel.length) {
          cancel = [...cancel, c];
        } else {
          cancel[fileIndex] = c;
        }
      }),
      onUploadProgress(progressEvent) {
        const { loaded, total } = progressEvent;
        const percentCompleted = Math.floor((loaded * 100) / total);
        fileUploadProgress(fileIndex, percentCompleted, setTransmission);
      },
    };

    try {
      const response = await instance.post(
        `/products/${productName}/inputs/url`,
        formPreSigned,
      );

      const preSignedResponseData = response.data;
      const FormDataSendImage = createFormS3(fileInfo.file, preSignedResponseData);
      await axios.post(preSignedResponseData.url, FormDataSendImage, config);
    } catch (error) {
      if (!axios.isCancel(error)) {
        throw new Error(error);
      }
    }
  },
);

export function cancelTransmission(indexCancel, setTransmission) {
  if (indexCancel >= cancel.length) {
    cancel.forEach((element) => element('Operation canceled by the user.'));
    cancel = [];
  } else {
    cancel[indexCancel]('Operation canceled by the user.');
  }
  cancelFile(indexCancel, setTransmission);
}

export const apiSlice = createApi({
  reducerPath: 'apiSlice',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['User', 'Products', 'Groups', 'Tags'],
  endpoints: (build) => ({
    logout: build.mutation({
      queryFn: (_, { getState }) => ({
        url: '/logout',
        method: 'POST',
        body: getState().user.refreshToken,
        invalidatesTags: ['User', 'Products'],
      }),
    }),
    getGroup: build.query({
      query: (productName) => `/products/${productName}/groups`,
      providesTags: ['Groups'],
    }),
    editTag: build.mutation({
      query: ({
        productName, tagId, tenantId, groupId, newTag,
      }) => ({
        url: `/products/${productName}/tags/${tagId}`,
        method: 'PUT',
        body: {
          tagId, tenantId, groupId, name: newTag,
        },
      }),
    }),
    deleteTag: build.mutation({
      query: ({ productName, tagId }) => ({
        url: `/products/${productName}/tags/${tagId}`,
        method: 'DELETE',
      }),
    }),
    addTag: build.mutation({
      query: ({ productName, tagName, groupId }) => ({
        url: `/products/${productName}/tags`,
        method: 'POST',
        body: { name: tagName, groupId },
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useLogoutMutation,
  useGetUserQuery,
  useGetGroupQuery,
  useEditTagMutation,
  useDeleteTagMutation,
  useAddTagMutation,
} = apiSlice;
