import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import './NotFoundPage.scss';
import LanguagePicker from '../../components/language-picker/LanguagePicker.js';

function NotFoundPage() {
  const { t } = useTranslation();

  return (
    <div className="page-404-ctr">
      <LanguagePicker
        className="language-picker-container legal"
        iconSize={25}
        iconHeight={25}
        flagSize={30}
      />
      <div className="not-found-ctr">
        <div className="page-404">{t('404.pageNotFound')}</div>
        <div className="not-found-txt">{t('404.pageNotFoundTxt')}</div>

        <Link to="/home" className="not-found-back-txt">
          {t('Message.back')}
        </Link>
      </div>
    </div>
  );
}

export default NotFoundPage;
