import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import './ApplicationsContainer.scss';
import DashboardApp from '../components/app-dashboard/DashboardApp.js';
import AnalysisReportApp from '../components/app-analysis-report/AnalysisReportApp.js';
import TransmissionApp from '../components/app-transmission/TransmissionApp.js';

function Applications(props) {
  const { t } = useTranslation();
  const { productsList } = useSelector((state) => state.products);

  return (
    <div className="select-app">
      <div className="title">{t('HomePage.applications')}</div>
      <div className="bottom-border" />

      <div className="app-container">
        {productsList.length <= 0 && (
          <div className="no-app">{t('Message.noApp')}</div>
        )}
        {productsList.length > 0 && <TransmissionApp child="defaultApp" />}
        {productsList.length > 0 && <DashboardApp child="defaultApp" {...props} />}
        {productsList.length > 0 && <AnalysisReportApp child="defaultApp" />}
      </div>
    </div>
  );
}

export default Applications;
