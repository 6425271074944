/**
 * Login component used to login into the webApp
 */

import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import './LoginPage.scss';
import logo from '../../assets/images/dilepix_logo_vertical.png';
import Button from '../../components/button/Button.js';
import LanguagePicker from '../../components/language-picker/LanguagePicker.js';
import { fetchLogin, getUser } from '../../features/userSlice.js';

function LoginPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const dispatch = useDispatch();

  function validateForm() {
    return username.length > 0 && password.length > 0;
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await dispatch(fetchLogin({ username, password })).unwrap()
        .then(() => dispatch(getUser()).unwrap())
        .then(() => navigate('/home'));
    } catch (err) {
      setErrorMessage(err?.message);
    }
  };

  const handleClick = (event) => {
    event.preventDefault();
    navigate('/reset');
  };

  return (
    <div className="auth-screen">
      <div className="auth-container">
        <LanguagePicker
          className="language-picker-container"
          iconSize={25}
          iconHeight={25}
          flagSize={30}
        />
        <Form onSubmit={handleSubmit} className="login">
          <div className="auth-logo-container">
            <img className="auth-logo" src={logo} alt="logo-dilepix" />
          </div>
          <Form.Group size="lg" controlId="username">
            <Form.Label>{t('Labels.labelUsername')}</Form.Label>
            <Form.Control
              autoComplete="off"
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </Form.Group>
          <Form.Group size="lg" controlId="password">
            <Form.Label>{t('Labels.labelPassword')}</Form.Label>
            <Form.Control
              autoComplete="off"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Group>
          <div className="forgotten-password-container">
            <span
              role="button"
              className="forgotten-password-text"
              onClick={(e) => handleClick(e)}
              tabIndex={0}
              onKeyDown={(e) => handleClick(e)}
            >
              {t('LoginPage.forgottenPassword')}
            </span>
          </div>

          {errorMessage && <div className="login-error">{t('LoginPage.loginFailed')}</div>}

          <Button
            className="auth-button"
            variant="contained"
            size="large"
            type="submit"
            disabled={!validateForm()}
            fullWidth
          >
            {t('Button.login')}
          </Button>
        </Form>
      </div>
    </div>
  );
}

export default LoginPage;
