import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

import ModalProduct from '../modal-product/ModalProduct.js';
import transmission from '../../assets/images/transmission.png';
import '../../containers/ApplicationsContainer.scss';

export default function TransmissionApp({ child }) {
  const { t } = useTranslation();
  const useStyles = makeStyles()({
    transmission: {
      backgroundColor: '#33a237',
      borderColor: '#33a237',
      '&:hover': { backgroundColor: '#2f9638' },
    },
  });
  const { classes } = useStyles();

  return (
    <ModalProduct child={child} icon={transmission} title={t('UploadPage.applicationTitle')} color={classes.transmission} page="/upload" />
  );
}
