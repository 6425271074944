/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  limit: 10,
  offset: 0,
  currentPage: 1,
  status: null,
  error: null,
};

export const paginationSlice = createSlice({
  name: 'pagination',
  initialState,
  reducers: {
    setLimitPerPage: (state, { payload }) => {
      state.limit = payload;
    },
    setOffsetPagination: (state, { payload }) => {
      state.offset = payload;
    },
    setPagination: (state, { payload }) => {
      state.currentPage = payload;
    },
    resetPagination: () => initialState,
  },
});

export const {
  setLimitPerPage, setOffsetPagination, setPagination, resetPagination,
} = paginationSlice.actions;
export default paginationSlice.reducer;
