/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Popper } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { BlockPicker } from 'react-color';
import { BsSquareFill } from 'react-icons/bs/index.js';

function ColorPicker({
  parentColor, index, passData, parentClose,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [localColor, setLocalColor] = useState(parentColor);

  useEffect(() => {
    setLocalColor(parentColor);
  }, [parentColor]);

  useEffect(() => {
    setAnchorEl(null);
  }, [parentClose]);

  const convertTGBAToString = (rgba) => `rgba(${rgba.r},${rgba.g},${rgba.b},1)`;

  const handleClick = (e) => {
    e.preventDefault();
    setAnchorEl(anchorEl ? null : e.currentTarget);
  };

  const handleChange = (color, event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setLocalColor(convertTGBAToString(color.rgb));
    passData(index, convertTGBAToString(color.rgb));
  };

  const chartColors = [
    '#006E00',
    '#008CF9',
    '#EBAC23',
    '#B80058',
    '#00BBAD',
    '#D163E6',
    '#B24502',
    '#FF9287',
    '#5954D6',
    '#00C6D6',
    '#878500',
  ];

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  return (
    <div>
      <span
        onClick={handleClick}
        role="button"
        aria-label="Color picker"
        tabIndex={0}
        data-testid="color_picker"
      >
        <BsSquareFill color={localColor} size={30} />
      </span>

      <Popper
        style={{ zIndex: 10000 }}
        id={id}
        open={open}
        anchorEl={anchorEl}
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, 9],
            },
          },
        ]}
      >
        <BlockPicker
          color={localColor}
          onChange={handleChange}
          colors={chartColors}
        />
      </Popper>
    </div>
  );
}

export default ColorPicker;
