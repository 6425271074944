import React, { useState } from 'react';
import { AiOutlineInfoCircle } from 'react-icons/ai/index.js';

import './Tooltip.scss';
import colors from '../../assets/colors.js';

function Tooltip({ children, content }) {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  return (
    <div className="tooltip-container">
      <div
        className="trigger"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {children}
      </div>
      {showTooltip && (
      <div className="tooltip">
        <AiOutlineInfoCircle color={colors.lightgrey2} size={20} />
        <span className="tooltip_content">{content}</span>
      </div>
      )}
    </div>
  );
}

export default Tooltip;
