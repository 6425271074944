import { createAsyncThunk } from '@reduxjs/toolkit';

import { translateObjKey } from '../assets/analysesKeys.js';
import { currentLanguage } from '../components/translate/languages.js';
import { formatCsvAnalysesResults } from '../hooks/useFormatProduct.js';

const formatCsvData = createAsyncThunk(
  'csv/formatData',
  async (_, { getState }) => {
    const { analysesResults } = getState().analyses;
    const { productName } = getState().products?.product || {};
    const { tagsList } = getState().tags;
    try {
      const data = [];
      await Promise.all(
        analysesResults.map(async (item) => {
          const obj = formatCsvAnalysesResults(item, productName, tagsList);
          const renameKeys = translateObjKey(obj);
          data.push(await Object.assign(
            {},
            ...(function _flatten(o) {
              return [].concat(
                ...Object.keys(o).map((key) => (typeof o[key] === 'object' ? _flatten(o[key]) : { [key]: o[key] })),
              );
            }({
              Date: new Intl.DateTimeFormat(currentLanguage().dateTimeFormat, {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
              }).format(new Date(item.analysis.createdOn)),
              key: renameKeys,
            })),
          ));
        }),
      );
      return data;
    } catch (error) {
      throw new Error(error.message);
    }
  },
);

export default formatCsvData;
