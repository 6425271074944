import React from 'react';
import { ThreeDots } from 'react-loader-spinner';

import './Loader.scss';
import colors from '../../assets/colors.js';

function LoaderDots() {
  return (
    <div className="loader" data-testid="loader">
      <ThreeDots color={colors.loader} height={50} width={50} />
    </div>
  );
}

export default LoaderDots;
