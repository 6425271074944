import React from 'react';
import { FiEdit } from 'react-icons/fi/index.js';

function EditCell(row, handleClick, setModalId) {
  return (
    <div className="table-item">
      <FiEdit
        size={25}
        onClick={() => {
          handleClick(row);
          setModalId('edit');
        }}
        data-testid="edit_icon"
      />
    </div>
  );
}

export default EditCell;
