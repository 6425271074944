/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { fetchAllTags, fetchTags } from './api/apiSlice.js';

const initialState = {
  tagsList: [],
  tag: [],
  status: null,
};

export const tagSlice = createSlice({
  name: 'tags',
  initialState,
  reducers: {
    setTag: (state, { payload }) => {
      state.tag = payload;
    },
    resetTag: (state) => {
      state.tag = initialState.tag;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTags.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchTags.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.tagsList = action.payload;
      })
      .addCase(fetchTags.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })

      .addCase(fetchAllTags.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchAllTags.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.tagsList = action.payload.flat(Infinity);
      })
      .addCase(fetchAllTags.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export const { setTag, resetTag } = tagSlice.actions;
export default tagSlice.reducer;
