import React from 'react';

import './ApplicationsContainer.scss';
import ShortcutMenu from '../components/shortcut-menu/ShortcutMenu.js';

function ShortcutApplications(props) {
  return (
    <ShortcutMenu {...props} />
  );
}

export default (ShortcutApplications);
