/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { fetchAllAnalyses } from './api/apiSlice.js';

const initialState = {
  analyses: [],
  status: null,
  error: null,
};

export const allAnalysesSlice = createSlice({
  name: 'allAnalyses',
  initialState,
  reducers: {
    resetAnalyses: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllAnalyses.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchAllAnalyses.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const sortedAnalyses = action.payload.slice().sort((a, b) => {
          const dateA = new Date(a.createdOn);
          const dateB = new Date(b.createdOn);
          return dateB - dateA;
        });

        state.analyses = sortedAnalyses;
      })
      .addCase(fetchAllAnalyses.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export const { resetAnalyses } = allAnalysesSlice.actions;
export default allAnalysesSlice.reducer;
