import { BarChart as BarChartIcon, Download as DownloadIcon } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import './AnalysisReportPage.scss';
import colors from '../../assets/colors.js';
import ActivityReportTable from '../../components/app-analysis-report/ActivityReportTable.js';
import AnalysesReportTable from '../../components/app-analysis-report/AnalysesReportTable.js';
import Chart from '../../components/chart/Chart.js';
import LoaderDots from '../../components/loader/Loader.js';
import CustomPagination from '../../components/table-pagination/CustomPagination.js';
import i18n from '../../components/translate/i18n.js';
import ActivityFiltersContainer from '../../containers/ActivityFiltersContainer.js';
import GenericFiltersContainer from '../../containers/GenericFiltersContainer.js';
import { fetchTags, useGetGroupQuery, fetchAnalyses } from '../../features/api/apiSlice.js';
import { setGroup } from '../../features/groupSlice.js';
import { resetPagination } from '../../features/paginationSlice.js';
import { setProductEndpoint } from '../../services/setProductEndpoint.js';
import formatCsvData from '../../utils/formatCsvData.js';

function AnalysisReportPage(props) {
  const { t } = useTranslation();
  const { filteredItems } = props;
  const { startDate, endDate } = filteredItems;
  const [isLoading, setIsLoading] = useState(false);
  const [showChart, setShowChart] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);
  const [noDataMsg, setNoDataMsg] = useState(false);
  const csvLink = useRef();
  const location = useLocation();
  const dispatch = useDispatch();
  const { pathname } = location;
  const { productName, name } = useSelector((state) => state?.products?.product);
  const { analysesResults, totalAnalyses } = useSelector((state) => state.analyses);
  const { analysesCsvResults } = useSelector((state) => state.csvData);
  const { activityAnalyses } = useSelector((state) => state.activities);
  const { data, isFetching, isSuccess } = useGetGroupQuery(productName);

  const productEndpoint = setProductEndpoint(productName);

  useEffect(() => {
    const fetchData = async () => {
      if (isSuccess && !isFetching) {
        dispatch(setGroup(data));
        await dispatch(fetchTags()).unwrap();
      }
    };
    fetchData();
  }, [data, isSuccess]);

  useEffect(() => {
    setIsFiltered(false);
    setShowChart(false);
    setNoDataMsg(false);
  }, [location, productName]);

  const handleNoDataMsg = (value) => {
    setNoDataMsg(value);
  };

  const handleChart = async () => {
    setShowChart(!showChart);
  };

  useEffect(() => {
    if (showChart === false) {
      window.scrollTo(0, 0, { behavior: 'smooth' });
    }
  }, [showChart]);

  // Set to csv data all analyses depending on filters
  const handleDownloadCSV = async () => {
    await dispatch(fetchAnalyses({
      startDate,
      endDate,
      limit: totalAnalyses,
      offset: 0,
    })).unwrap();
    await dispatch(formatCsvData()).unwrap();
    if (csvLink && csvLink.current) {
      csvLink.current.link.click();
    }
    dispatch(resetPagination());
  };

  return (
    <div className="analysisReport-scr">
      <div className="title-container">
        <div className="title">
          {t('AnalysisReportPage.applicationTitle')}
        </div>
        <div className="bottom-border" />
      </div>

      <div className="selected-product-name">
        <span className="selected-product">
          {t('AnalysisReportPage.selectedProduct')}
        </span>
        {name}
      </div>

      <div className="analysisReport-ctr">
        <div>
          <div className="datePicker-ctr">
            {productEndpoint === 'activities' ? (
              <ActivityFiltersContainer
                setIsLoading={setIsLoading}
                setIsFiltered={setIsFiltered}
                setNoDataMsg={setNoDataMsg}
                setShowChart={setShowChart}
                product={productName}
                key={location.key}
              />
            ) : (
              <GenericFiltersContainer
                setIsLoading={setIsLoading}
                handleNoDataMsg={handleNoDataMsg}
                setShowChart={setShowChart}
                product={productName}
                key={location.key}
              />
            )}
          </div>
        </div>

        {isLoading ? (
          <div className="loader-dots-activities">
            <LoaderDots />
          </div>
        ) : (
          <div>
            {analysesResults.length > 0 && (
              <div>
                {productEndpoint !== 'activities' && (
                  <AnalysesReportTable language={i18n.language} />
                )}
                <div className="footer-generic-ctr">
                  {analysesResults.length > 0 && <div className="footer-tbl-pagination"><CustomPagination /></div>}
                  <div className="option-footer-table-ctr">
                    {!(productName?.toLowerCase().includes('deficiency')) && (
                      <IconButton onClick={handleChart} className="chart-button" style={{ backgroundColor: showChart ? colors.green : colors.white }}>
                        <BarChartIcon
                          style={{ color: showChart ? colors.white : colors.dark_grey }}
                        />
                      </IconButton>
                    )}

                    {pathname === '/analysis-report' && (
                      <div className="csv-icon-ctr">
                        <IconButton className="csv-icon" onClick={() => handleDownloadCSV()} data-testid="csv-download">
                          <DownloadIcon fontSize="small" />
                        </IconButton>
                        <CSVLink
                          filename="AnalysisReport.csv"
                          data={analysesCsvResults}
                          ref={csvLink}
                          target="_blank"
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}

            {isFiltered === true && activityAnalyses.length > 0 && (
              <div>
                {productEndpoint === 'activities' && (
                  <ActivityReportTable language={i18n.language} />
                )}
              </div>
            )}

            {noDataMsg === true && analysesResults?.length < 1
              && activityAnalyses?.length < 1 && (
                <div className="no-analyses-msg">
                  {t('AnalysisReportPage.noAnalysesFound')}
                </div>
            )}
            {showChart && (<Chart />)}
          </div>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  filteredItems: state.filters.filteredItems,
});

export default connect(mapStateToProps)(AnalysisReportPage);
