import React from 'react';
import './ProgressBar.scss';

function ProgressBar(props) {
  const { className, uploadProgress } = props;

  return (
    <div className="main-progress">
      <div className="progress">
        <div
          className={className}
          role="progressbar"
          aria-valuenow={uploadProgress}
          aria-valuemin="0"
          aria-valuemax="100"
          style={{ width: `${uploadProgress}%` }}
        >
          {uploadProgress}
          %
        </div>
      </div>
    </div>
  );
}

export default ProgressBar;
