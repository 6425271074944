/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import chartTypes from '../components/generic-chart/ChartType.js';
import i18n from '../components/translate/i18n.js';

const initialState = {
  data: {
    labels: [],
    datasets: [],
  },
  options: {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'bottom',
      },
      title: {
        display: true,
        text: i18n.t('Chart.title'),
      },
    },
    scales: {
      x: { title: '' },
      y: { title: '' },
    },
  },
  chartType: chartTypes.Line,
  status: null,
  error: null,
};

export const chartSlice = createSlice({
  name: 'chart',
  initialState,
  reducers: {
    setChartType: (state, { payload }) => {
      state.chartType = payload;
      state.options = {
        ...state.options,
        scales: {
          x: {
            ...state.options?.scales?.x,
            ...chartTypes.option[payload]?.scales.x,
          },
          y: {
            ...state.options?.scales?.y,
            ...chartTypes.option[payload]?.scales.y,
          },
        },
      };
      state.data = {
        ...state.data,
        datasets: state.data?.datasets.map((item) => ({
          ...item,
          fill: payload === chartTypes.Line,
        })),
      };
    },
    setChartData: (state, { payload }) => {
      state.data = payload;
    },
    setChartOptions: (state, { payload }) => {
      state.options = payload;
    },
    resetChart: () => initialState,
  },
});

export const {
  setChartType, setChartData, setChartOptions, resetChart,
} = chartSlice.actions;
export default chartSlice.reducer;
