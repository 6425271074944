/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { fetchInput } from './api/apiSlice.js';

const initialState = {
  input: [],
  status: null,
  error: null,
};

export const inputSlice = createSlice({
  name: 'input',
  initialState,
  reducers: {
    resetInput: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInput.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchInput.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.input = action.payload;
      })
      .addCase(fetchInput.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export const { resetInput } = inputSlice.actions;
export default inputSlice.reducer;
