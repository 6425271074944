/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-param-reassign */
import * as db from 'mime-db';
import React, { useState, useEffect } from 'react';
import { Table } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';

import './UploadFiles.scss';
import Button from '../button/Button.js';
import ModalTag from '../modal-tag/ModalTag.js';
import ProgressBar from '../progress-bar/ProgressBar.js';
import closeGrey from '../../assets/images/close_grey.png';
import closeRed from '../../assets/images/close_red.png';
import Icon from '../../assets/images/fail.png';
import greenCloud from '../../assets/images/green_cloud.png';
import { cancelTransmission, uploadFiles } from '../../features/api/apiSlice.js';
import { resetTag } from '../../features/tagSlice.js';
import { createArrayFiles } from '../../services/uploadFile.utils.js';
import { calculateUploadProgress, uncancelFile } from '../../utils/handleUploadFile.js';

function UploadFiles(props) {
  const { t } = useTranslation();
  const { tag } = props;
  const [errorMessage, setErrorMessage] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const [indexCancel, setIndexCancel] = useState(null);
  const [open, setOpen] = useState(false);
  const [transmission, setTransmission] = useState([]);
  const dispatch = useDispatch();
  const location = useLocation();
  const { product } = useSelector((state) => state.products);

  const uploadProgress = calculateUploadProgress(transmission);

  useEffect(() => {
    setTransmission([]);
  }, [product.productName]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getExtensions = [];
  if ('inputMimeTypes' in product) {
    product.inputMimeTypes
      .filter((item) => item in db && 'extensions' in db[item])
      .map((item) => getExtensions.push(db[item].extensions));
  }

  const acceptedFileType = Array.from(
    new Set([].concat(...getExtensions).map((item) => ''.concat('.') + item)),
  );

  const onDrop = (files) => {
    if (isUploading) {
      setErrorMessage(`${t('Alerts.uploadInProgress')}`);
      return;
    }
    const hasCanceledFile = transmission.some((file) => file.cancel);
    if (hasCanceledFile) {
      setErrorMessage(`${t('Alerts.uploadInProgress')}`);
      return;
    }
    if (uploadProgress === 100 && transmission.length > 0) {
      setTransmission([]);
      setErrorMessage('');
      setIsUploading(false);
      setTransmission((prevTransmission) => [...prevTransmission, ...createArrayFiles(files)]);
    } else {
      setTransmission((prevTransmission) => [...prevTransmission, ...createArrayFiles(files)]);
    }
  };

  const filePathValidator = (file) => {
    if (transmission.find((item) => item.name === file.path)) {
      return true;
    }
    return false;
  };

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    onDrop,
    maxFiles: 5,
    accept: acceptedFileType,
    validator: filePathValidator,
  });

  const dropzoneReject = fileRejections.map(({ errors }) => errors.map(
    (e) => (e.message === 'Too many files' && `${t('Message.tooManyFiles')}`)
        || (e === true && `${t('Message.duplicateFiles')}`),
  ));

  const removeFile = (fileIndex) => {
    setTransmission((prevTransmission) => {
      const updatedTransmission = prevTransmission.filter((index) => index !== fileIndex);
      return updatedTransmission;
    });
  };

  const removeTag = () => {
    dispatch(resetTag());
  };

  const files = transmission.map((file) => (
    <li className="file-info" key={file.name}>
      {file.name}
    </li>
  ));

  const limitFile = files.length >= 5;

  useEffect(() => {
    setIndexCancel(files.length);
    if (files.length < 1 && tag.length > 0) {
      dispatch(resetTag());
    }
  }, [files]);

  const deleteTag = (
    <img
      alt="close"
      src={closeGrey}
      className="table-close-icon-tag"
      draggable="false"
      onClick={removeTag}
      onKeyDown={removeTag}
    />
  );

  const validateUpload = () => files.length > 0 && !isUploading && transmission.length <= 5;

  const onSubmitUpload = async (e) => {
    e?.preventDefault();
    if (files.length !== 0) {
      setIsUploading(true);
      try {
        const uploadPromises = transmission.map(
          async (fileInfo, fileIndex) => {
            const data = new FormData();
            data.append('image', fileInfo);
            if (fileIndex === indexCancel || indexCancel === files.length) {
              await dispatch(uploadFiles({
                fileInfo, fileIndex, tag, transmission, setTransmission,
              })).unwrap();
            }
          },
        );
        Promise.all(uploadPromises);
      } catch (err) {
        setErrorMessage(<div>{t('UploadPage.uploadError')}</div>);
      }
    }
  };

  const resetUpload = () => {
    setIsUploading(false);
    setTransmission([]);
    setErrorMessage('');
    dispatch(resetTag());
  };

  useEffect(() => () => {
    if (isUploading) {
      cancelTransmission(files.length, setTransmission);
      resetUpload();
    }
  }, [location, isUploading]);

  useEffect(() => {
    const onUnload = () => {
      if (isUploading) {
        cancelTransmission(files.length, setTransmission);
        resetUpload();
      }
      return undefined;
    };

    window.addEventListener('beforeunload', onUnload);

    return () => window.removeEventListener('beforeunload', onUnload);
  }, [isUploading]);

  const showAlert = () => {
    const customErrorAlert = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger',
      },
      buttonsStyling: false,
    });
    customErrorAlert
      .fire({
        imageUrl: `${Icon}`,
        imageHeight: 129,
        imageWidth: 150,
        title: `${t('UploadPage.titleStopUploading')}`,
        text: `${t('UploadPage.stopUploading')}`,
        showCancelButton: true,
        confirmButtonText: `${t('Button.yes')}`,
        cancelButtonText: `${t('Button.no')}`,
        allowOutsideClick: false,
      })
      .then((result) => {
        if (result.isConfirmed) {
          handleClose();
          if (indexCancel === files.length) {
            setIsUploading(false);
          }
        } else {
          handleClose();
          uncancelFile(indexCancel, setTransmission);
          onSubmitUpload();
        }
      });
  };

  useEffect(() => {
    if (open) showAlert();
  }, [open]);

  return (
    <>
      <section className="container">
        <div {...getRootProps({ className: 'dropzone' })} data-testid="dropzone-ctr">
          <input {...getInputProps()} disabled={limitFile} data-testid="dropzone" />
          <img
            alt="cloud"
            src={greenCloud}
            width="100"
            className="dropzone-upload-icon"
            draggable="false"
          />
          <p className="dropzone-text">
            {t('UploadPage.dropzone1')}
            <span className="dropzone-span">
              {t('UploadPage.dropzone2')}
            </span>
            {t('UploadPage.dropzone3')}
          </p>
          <p className="dropzone-text2">
            {t('UploadPage.dropzoneLimit')}
            {acceptedFileType.join(', ')}
            {t('UploadPage.dropzoneLimit2')}
          </p>
        </div>

        <div className="dropzone-err-msg">{dropzoneReject[0]}</div>
        {transmission.length > 5 && <div className="dropzone-err-msg">{t('Message.tooManyFiles')}</div>}
        <div className="dropzone-err-msg">{errorMessage}</div>

        {transmission.length > 0 && (
          <div>
            <p className="selected-files-text">
              {t('UploadPage.selectedFiles')}
            </p>
            <div className="table-ctr">
              <Table className="table" size="sm" responsive>
                <thead className="thead-light">
                  <tr>
                    <th className="table-header upload" scope="col">
                      {t('UploadPage.uploadFilesTable.headerName')}
                    </th>
                    <th
                      className="table-header upload file-mobile-view"
                      scope="col"
                    >
                      {t('UploadPage.uploadFilesTable.headerType')}
                    </th>
                    <th
                      className="table-header upload file-mobile-view"
                      scope="col"
                    >
                      {t('UploadPage.uploadFilesTable.headerSize')}
                    </th>
                    <th
                      className="table-header upload file-mobile-view"
                      scope="col"
                    >
                      {t('UploadPage.uploadFilesTable.headerStatus')}
                    </th>
                    <th scope="col" aria-label="close-icons" />
                  </tr>
                </thead>
                <tbody>
                  {transmission.map((file, fileIndex, fileSize) => {
                    if (file.size >= 1073741824) {
                      fileSize = `${(file.size / 1073741824).toFixed(2)} Gb`;
                    } else if (file.size >= 1048576) {
                      fileSize = `${(file.size / 1048576).toFixed(2)} Mb`;
                    } else if (file.size >= 1024) {
                      fileSize = `${(file.size / 1024).toFixed(2)} Kb`;
                    } else {
                      fileSize = `${file.size.toFixed(2)} b`;
                    }
                    return (
                      <tr key={file.name}>
                        <td
                          label={t('UploadPage.uploadFilesTable.headerName')}
                          className={!file.cancel ? 'td-mobile' : 'td-mobile-hidden'}
                          data-testid="file-name"
                        >
                          {file.name}
                        </td>
                        <td
                          label={t('UploadPage.uploadFilesTable.headerType')}
                          className={`file-mobile-view ${file.cancel && 'td-text'}`}
                          data-testid="file-type"
                        >
                          {file.type}
                        </td>
                        <td
                          label={t('UploadPage.uploadFilesTable.headerSize')}
                          className={`file-mobile-view ${file.cancel && 'td-text'}`}
                          data-testid="file-size"
                        >
                          {fileSize}
                        </td>
                        <td
                          label={t('UploadPage.uploadFilesTable.headerStatus')}
                          className="file-mobile-view"
                          data-testid="file-status"
                        >
                          <li className="file-info" key={file.name}>
                            <div className="progress-container">
                              <div className="progress">
                                <div
                                  className={file.cancel === false ? 'progress-bar bg-success' : 'progress-bar bg-danger'}
                                  role="progressbar"
                                  aria-valuenow={file.progress}
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                  style={{ width: `${file.progress}%` }}
                                >
                                  {file.progress}
                                  %
                                </div>
                              </div>
                            </div>
                          </li>
                        </td>
                        <td
                          label={t('UploadPage.uploadFilesTable.headerDelete')}
                        >
                          <li className="file-info" key={file.name}>
                            <div
                              className={file.cancel === false && file.progress < 100 ? 'close-icon-container' : 'close-icon-container-hidden'}
                              role="button"
                              data-testid="delete-icon"
                              tabIndex={0}
                              onClick={() => {
                                if (isUploading) {
                                  setIndexCancel(fileIndex);
                                  cancelTransmission(fileIndex, setTransmission);
                                  setOpen(true);
                                } else {
                                  removeFile(file, fileIndex);
                                }
                              }}
                              onKeyDown={() => {
                                if (isUploading) {
                                  setIndexCancel(fileIndex);
                                  cancelTransmission(fileIndex, setTransmission);
                                  setOpen(true);
                                } else {
                                  removeFile(file, fileIndex);
                                }
                              }}
                            >

                              <img
                                alt="close"
                                src={closeRed}
                                className="table-close-icon"
                                draggable="false"
                              />
                            </div>
                          </li>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>

            {files.length === 0 ? (
              null
            ) : (
              <div className="selectTag-container">
                <div className="info-container">
                  {t('UploadPage.informations')}
                </div>
                <div>
                  {tag.length === 0 ? (
                    null
                  ) : (
                    <div className="tag-container">
                      <div className="selected-tag" role="button" name="addTag-btn" data-testid="addTag-btn">
                        <div className="tag-name">{tag?.name}</div>
                        <div className="delete-icon">{deleteTag}</div>
                      </div>
                    </div>
                  )}
                </div>
                <ModalTag />
              </div>
            )}
            <ProgressBar
              uploadProgress={uploadProgress}
              className={transmission.filter((element) => element.cancel === false)
                .length === 0 ? 'progress-bar bg-danger' : 'progress-bar bg-success'}
            />
          </div>
        )}
      </section>

      <form onSubmit={onSubmitUpload}>
        {uploadProgress === 100 || (transmission.filter((element) => element.cancel === false)
          .length === 0 && transmission.length) ? (
            <div className="goBack-container">
              <Button
                className="goBack-button"
                variant="contained"
                size="large"
                action={() => {
                  resetUpload();
                }}
                name="reset-btn"
              >
                <span className="reset-txt">{t('Button.reset')}</span>
              </Button>
            </div>
          ) : !isUploading ? (
            <div className="button-container">
              <Button
                type="submit"
                className="goBack-button"
                variant="contained"
                size="large"
                disabled={!validateUpload()}
                name={t('Button.send')}
              >
                <span className="reset-txt">{t('Button.send')}</span>
              </Button>
            </div>
          ) : (
            <div className="button-container">
              <Button
                className="goBack-button"
                variant="contained"
                size="large"
                action={() => {
                  setIndexCancel(files.length);
                  handleClickOpen();
                  cancelTransmission(files.length, setTransmission);
                }}
              >
                <span className="reset-txt">{t('Button.cancel')}</span>
              </Button>
            </div>
          )}
        <section className="container">
          {uploadProgress === 100 ? (
            <div className="upload-message">
              <div
                className="alert alert-info alert-dismissible fade show"
                role="alert"
              >
                {t('UploadPage.fileUploaded')}
              </div>
            </div>
          ) : null}
        </section>
      </form>
    </>
  );
}

const mapStateToProps = (state) => ({
  tag: state.tags.tag,
});

export default connect(mapStateToProps)(UploadFiles);
