export const setProductEndpoint = (productName) => {
  const productType = ['activity', 'counting', 'deficiency'];
  let productEndpoint;
  if (productName?.toLowerCase().includes(productType[0].toLowerCase())) {
    productEndpoint = 'activities';
  } else if (productName?.toLowerCase().includes(productType[1].toLowerCase())) {
    productEndpoint = 'countings';
  } else if (productName?.toLowerCase().includes(productType[2].toLowerCase())) {
    productEndpoint = 'deficiencies';
  }
  return productEndpoint;
};

export default setProductEndpoint;
