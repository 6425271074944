import React, { useMemo } from 'react';
import { connect } from 'react-redux';

import './AnalysisReportTable.scss';
import CustomCell from '../table/CustomCell.js';
import DateCell from '../table/DateCell.js';
import Table from '../table/Table.js';
import i18n from '../translate/i18n.js';
import { currentLanguage } from '../translate/languages.js';
import { analysesKeysMap, analysesKeysMapEN, translateObjValue } from '../../assets/analysesKeys.js';
import { setProductEndpoint } from '../../services/setProductEndpoint.js';

function AnalysisReportTable(props) {
  const { analysesResults, tagsList, product } = props;
  const { productName } = product;

  const productEndpoint = setProductEndpoint(productName);

  const refactorAnalysesResults = analysesResults.map((item) => ({
    ...item,
    ...(productEndpoint === 'countings' && {
      output: {
        count: item.count,
        tagId: item.input.tagId,
      },
    }),
    ...(productEndpoint === 'deficiencies' && {
      output: {
        deficiency: item.deficiency,
        tagId: item.input.tagId,
      },
    }),
  }));

  // Translate object keys with french names for columns header
  const objD = refactorAnalysesResults.length > 0 && refactorAnalysesResults[0].output;

  const translatedHeader = Object.keys(objD).reduce(
    (acc, key) => ({
      ...acc,
      ...(i18n.language === 'fr'
        ? { [analysesKeysMap[key] || key]: objD[key] }
        : { [analysesKeysMapEN[key] || key]: objD[key] }),
    }),
    {},
  );

  // Map columns depending on selected product
  const columns = useMemo(() => [
    {
      Header: 'Date',
      accessor: 'date',
      Cell: DateCell,
    },
    refactorAnalysesResults.length > 0
    && Object.keys(translatedHeader).map((key) => ({
      Header: key,
      accessor: key,
      Cell: CustomCell,
    })),
  ].flat());

  const preData = [];
  // set table data for generic product
  refactorAnalysesResults
    .sort((a, b) => {
      const dateA = new Date(a.createdOn);
      const dateB = new Date(b.createdOn);
      return dateB - dateA;
    })
    .map((item, i) => {
      const deficiencyTranslation = productEndpoint === 'deficiencies' && translateObjValue(item, currentLanguage());
      // Loop on analyses output and rename keys
      const tagName = tagsList
        .map(
          (tag) => (tag.tagId === refactorAnalysesResults[i].input.tagId && tag.name),
        )
        .filter(Boolean)
        .join('');
      const obj = refactorAnalysesResults.length > 0
        && Object.assign(refactorAnalysesResults[i], {
          deficiency: deficiencyTranslation?.deficiency,
          tagId:
            tagName !== '' && tagName !== null && tagName !== undefined
              ? tagName
              : '-',
        });
      const renameKeys = Object.keys(obj).reduce(
        (acc, key) => ({
          ...acc,
          ...(i18n.language === 'fr'
            ? { [analysesKeysMap[key] || key]: obj[key] }
            : { [analysesKeysMapEN[key] || key]: obj[key] }),
        }),
        {},
      );

      // Flatten output object
      preData.push(
        Object.assign(
          {},
          ...(function _flatten(o) {
            return [].concat(
              ...Object.keys(o).map((k) => {
                if (o[k] === null) {
                  return { [k]: '-' };
                }
                if (typeof o[k] === 'object') {
                  return _flatten(o[k]);
                }
                return { [k]: o[k] };
              }),
            );
          }({ date: item.analysis.createdOn, key: renameKeys })),
        ),
      );
      return preData;
    });

  const data = useMemo(() => preData);

  return analysesResults.length > 0 ? (
    <div className="analysisReport-table-container">
      <Table
        columns={columns}
        data={data}
        showFilters={false}
        showPagination={false}
        chart
      />
    </div>
  )
    : null;
}

const mapStateToProps = (state) => ({
  analysesResults: state.analyses.analysesResults,
  tagsList: state.tags.tagsList,
  product: state.products.product,
});

export default connect(mapStateToProps)(AnalysisReportTable);
