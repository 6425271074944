/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import instance from '../services/instanceApi.js';

const initialState = {
  accessToken: '',
  idToken: '',
  refreshToken: '',
  username: '',
  email: '',
  tenantName: '',
};

export const fetchLogin = createAsyncThunk(
  'auth/loginUser',
  async ({ username, password }, thunkAPI) => {
    try {
      const response = await instance.post('/login', { username, password });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const getUser = createAsyncThunk(
  'auth/getUser',
  async () => {
    try {
      const response = await instance.get('/profile');
      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  },
);

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setRefreshToken: (state, action) => {
      state.accessToken = action.payload.accessToken;
    },
    fetchLogout: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLogin.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchLogin.fulfilled, (state, action) => {
        state.accessToken = action.payload.accessToken;
        state.idToken = action.payload.idToken;
        state.refreshToken = action.payload.refreshToken;
        state.status = 'succeeded';
      })
      .addCase(fetchLogin.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })

      .addCase(getUser.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.username = action.payload.username;
        state.email = action.payload.email;
        state.tenantName = action.payload.tenantName;
        state.status = 'succeeded';
      })
      .addCase(getUser.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export const {
  setCredentials, setRefreshToken, fetchLogout, setProfile,
} = userSlice.actions;
export default userSlice.reducer;
