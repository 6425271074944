import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import {
  AccordionSummary,
  AccordionDetails,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@mui/material';
import MuiAccordion from '@mui/material/Accordion/index.js';
import { styled } from '@mui/material/styles/index.js';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import ColorPicker from '../color-picker/ColorPicker.js';
import { setChartData } from '../../features/chartSlice.js';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  border: '1px solid #b9c0c4',
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

function ChartStylePicker({ hidden, open }) {
  const { data } = useSelector((state) => state.charts);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const changeColorDataset = (localIndex, localColor) => {
    const localDataset = data.datasets.map((item, index) => {
      if (index === localIndex) {
        return { ...item, backgroundColor: localColor };
      }
      return item;
    });
    dispatch(setChartData({ ...data, datasets: localDataset }));
  };

  const listDataset = data?.datasets?.map((item, index) => (
    <ListItem key={item.label}>
      <ListItemAvatar>
        <ColorPicker
          parentColor={item.backgroundColor}
          index={index}
          passData={changeColorDataset}
          parentClose={open}
        />
      </ListItemAvatar>
      <ListItemText>
        <Typography>{item.label}</Typography>
      </ListItemText>
    </ListItem>
  ));

  return hidden ? (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>{t('Chart.chartStylePicker.title')}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {listDataset}
      </AccordionDetails>
    </Accordion>
  )
    : null;
}

export default ChartStylePicker;
