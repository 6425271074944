import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import {
  AccordionDetails,
  AccordionSummary,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Typography,
} from '@mui/material';
import MuiAccordion from '@mui/material/Accordion/index.js';
import { styled } from '@mui/material/styles/index.js';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { setChartOptions } from '../../features/chartSlice.js';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  border: '1px solid #b9c0c4',
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

function ChartLegendPicker({ hidden }) {
  const { options } = useSelector((state) => state.charts);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const legendPositions = [
    {
      label: t('Chart.chartLegendPicker.legendPositions.top'),
      value: 'top',
    },
    {
      label: t('Chart.chartLegendPicker.legendPositions.left'),
      value: 'left',
    },
    {
      label: t('Chart.chartLegendPicker.legendPositions.right'),
      value: 'right',
    },
    {
      label: t('Chart.chartLegendPicker.legendPositions.bottom'),
      value: 'bottom',
    },
  ];

  const [legendPosition, setLegendPosition] = useState(legendPositions[3].value);
  const [legendDisplay, setLegendDisplay] = useState(true);

  const handleChangeLegendDisplay = (event) => {
    setLegendDisplay(event.target.checked);
    dispatch(setChartOptions({
      ...options,
      plugins: {
        ...options.plugins,
        legend: {
          ...options.plugins.legend,
          display: event.target.checked,
        },
      },
    }));
  };

  const handleChangeLegendPosition = (event) => {
    setLegendPosition(event.target.value);
    dispatch(setChartOptions({
      ...options,
      plugins: {
        ...options.plugins,
        legend: {
          ...options.plugins.legend,
          position: event.target.value,
        },
      },
    }));
  };

  const listColumnData = legendPositions.map((name) => (
    <MenuItem
      key={name.value}
      value={name.value}
    >
      {name.label}
    </MenuItem>
  ));

  return hidden ? (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>{t('Chart.chartLegendPicker.title')}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container direction="column" spacing={1.5} sx={{ p: 2 }}>
          <Grid item>
            <FormControl>
              <FormControlLabel
                control={(
                  <Switch checked={legendDisplay} onChange={handleChangeLegendDisplay} />
                )}
                label={t('Chart.chartLegendPicker.legendDisplay')}
              />
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">{t('Chart.chartLegendPicker.legendPositionLabel')}</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={legendPosition}
                label={t('Chart.chartLegendPicker.legendPositionLabel')}
                onChange={handleChangeLegendPosition}
              >
                {listColumnData}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
    : null;
}

export default ChartLegendPicker;
