import clsx from 'clsx';
import {
  Divider,
  List,
  ListItem,
  SwipeableDrawer,
} from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiMenu } from 'react-icons/fi/index.js';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import '../navbar/Navbar.scss';
import './Sidebar.scss';
import Button from '../button/Button.js';
import logo from '../../assets/images/dilepix_logo_vertical.png';
import { apiSlice } from '../../features/api/apiSlice.js';
import { fetchLogout } from '../../features/userSlice.js';

function SideBar(props, { ...rest }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const useStyles = makeStyles()({
    list: {
      width: 250,
    },
    fullList: {
      width: 'auto',
    },
  });

  const { classes } = useStyles();
  const [state, setState] = useState({ right: false });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const profile = async (event) => {
    event.preventDefault();
    navigate('/profile');
  };

  const handleLogout = async (event) => {
    event.preventDefault();
    dispatch(fetchLogout());
    dispatch(apiSlice.util.resetApiState());
    navigate('/login');
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <div className="shortcut-logo">
          <img alt="Dilepix logo" src={logo} width="80" />
        </div>
      </List>
      <Divider />
      <List>
        <ListItem button onClick={profile} className="side-item">
          <span className="side-label">{t('Navbar.headerBar.profilePage')}</span>
        </ListItem>
        <ListItem button onClick={handleLogout} className="side-item">
          <span className="side-label">{t('Navbar.headerBar.logoutButton')}</span>
        </ListItem>
      </List>
    </div>
  );

  return (
    <div>
      {['right'].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button action={toggleDrawer(anchor, true)}>
            <FiMenu className="menu-icon" data-testid="side-menu" size={40} />
          </Button>
          <SwipeableDrawer
            {...rest}
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            {list(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  );
}

export default SideBar;
