import React, { useState, useEffect } from 'react';
import { Dropdown } from 'react-bootstrap';
import { IoMdArrowDropdown } from 'react-icons/io/index.js';

import './LanguagePicker.scss';
import i18n from '../translate/i18n.js';
import { resources, currentLanguage } from '../translate/languages.js';

function LanguagePicker({
  className, iconSize, iconHeight, flagSize,
}) {
  const [language, setLanguage] = useState([]);

  useEffect(() => {
    setLanguage(resources);
  }, []);

  const changeLanguage = async (event, item) => {
    event.preventDefault();
    i18n.changeLanguage(item);
    localStorage.setItem('lang', item);
  };

  const renderFlagList = (list) => list.map((item) => (
    <img
      className="language-element"
      alt="flag"
      src={item}
      key={item}
      width={flagSize}
    />
  ));

  const renderLanguageList = language.map((item, index) => (
    <div key={item.id}>
      <Dropdown.Item
        className="dropdown-language"
        onClick={(event) => changeLanguage(event, item.id)}
      >
        {item.label}
        <div className="list-flag">{renderFlagList(item.icon)}</div>
      </Dropdown.Item>
      {language.length - 1 > index && (
        <Dropdown.Divider className="dropdown-language-divider" />
      )}
    </div>
  ));

  return (
    <div className={className}>
      <Dropdown className="language-dropdown">
        <Dropdown.Toggle className="nav-language">
          <div className="language-ctr">
            <img alt="flag" className="language-flag" src={currentLanguage().icon[0]} width={iconSize} height={iconHeight} />
            <span className="language-txt">{i18n.language}</span>
            <IoMdArrowDropdown size={20} />
          </div>
        </Dropdown.Toggle>

        <Dropdown.Menu className="dropdown-language-menu">
          {renderLanguageList}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}

export default LanguagePicker;
