import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import './EditTagsPage.scss';
import Button from '../../components/button/Button.js';
import ProductList from '../../components/product-list/ProductList.js';
import EditTagTable from '../../components/table/EditTagTable.js';
import { resetProduct } from '../../features/productSlice.js';

function EditTagsPage() {
  const { t } = useTranslation();
  const { product } = useSelector((state) => state.products);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(resetProduct());
  }, []);

  const isProduct = typeof product?.productId !== 'undefined';

  const handleClick = () => {
    navigate(-1);
  };

  return (
    <div className="edit_tag_screen">
      <div className="title_container">
        <Button variant="contained" title={t('Button.back')} action={handleClick} className="back_btn">{t('Button.back')}</Button>
        <div className="title">{t('EditTagPage.screenTitle')}</div>
        <div className="bottom-border" />
      </div>

      <ProductList />

      <div>{isProduct && <EditTagTable />}</div>
    </div>
  );
}

export default EditTagsPage;
