import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import './UploadPage.scss';
import UploadFiles from '../../components/app-transmission/UploadFiles.js';
import LoaderDots from '../../components/loader/Loader.js';
import { useGetGroupQuery } from '../../features/api/apiSlice.js';
import { setGroup } from '../../features/groupSlice.js';
import { resetTag } from '../../features/tagSlice.js';

function UploadPage() {
  const location = useLocation();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { productName, name } = useSelector((state) => state?.products?.product);
  const { data, isSuccess } = useGetGroupQuery(productName);

  useEffect(() => {
    setIsLoading(true);
    if (isSuccess) dispatch(setGroup(data));
    setIsLoading(false);
  }, [data, isSuccess]);

  useEffect(() => {
    dispatch(resetTag());
  }, [location]);

  return isLoading ? (
    <div className="loader-dots">
      <LoaderDots />
    </div>
  ) : (
    <div className="page-screen">
      <div className="title-container">
        <div className="title">{t('UploadPage.screenTitle')}</div>
        <div className="bottom-border" />
      </div>
      <div className="upload-container">
        <div className="upload-title">
          <span className="selected-files-text">
            {t('UploadPage.selectedProduct')}
          </span>
          {name}
        </div>
        <UploadFiles />
      </div>
    </div>

  );
}

export default UploadPage;
