/**
 * HomePage component - landing page after logged in
 */

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import './HomePage.scss';
import LoaderDots from '../../components/loader/Loader.js';
import Applications from '../../containers/ApplicationsContainer.js';
import { fetchProducts } from '../../features/productSlice.js';

function HomePage() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    const asyncFetchProducts = async () => {
      await dispatch(fetchProducts()).unwrap()
        .then(() => setIsLoading(false));
    };
    asyncFetchProducts();
  }, []);

  if (isLoading) {
    return (
      <div className="home-container">
        <div className="loader-dots">
          <LoaderDots />
        </div>
      </div>
    );
  }

  return (
    <div className="home-container">
      <Applications />
    </div>
  );
}

export default HomePage;
