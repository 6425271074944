import React from 'react';

import './FilterOption.scss';
import Checkbox from '../checkbox/Checkbox.js';

function FilterOption({ option, checkbox, handleChange }) {
  return (
    <div className="item-filters" key={option}>
      <Checkbox
        checked={checkbox[option] || false}
        onChange={handleChange}
        id={option}
        value={option}
        className="checkbox-filters"
        data-testid={option}
      />
      <div className="item-list-filters">{option}</div>
    </div>
  );
}

export default FilterOption;
