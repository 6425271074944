import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import './ModalProduct.scss';
import ApplicationItem from '../application-item/ApplicationItem.js';
import Button from '../button/Button.js';
import ModalHook from '../modal/ModalHook.js';
import ShortcutItem from '../shortcut-item/ShortcutItem.js';
import { selectProduct } from '../../features/productSlice.js';
import useModal from '../../hooks/useModal.js';

function AnalysisReportApp({
  child, icon, title, color, page,
}) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const useStyles = makeStyles()({
    button: {
      font: '20px Poppins-rg', // <---- change to var from _base.scss ($primary-font-rg)
      backgroundColor: '#f3f3f3', // <---- change to var from _base.scss ($color-lightgrey)
      borderRadius: '0.5rem',
      margin: '0 0rem 1rem 0rem',
      padding: '1rem 1rem 1rem 1rem',
      boxShadow: 'none',
      '&:hover': {
        backgroundColor: '#2f9638', // <---- change to var from _base.scss ($color-green-hover)
        borderColor: '#2f9638', // <---- change to var from _base.scss ($color-green-hover)
        color: '#ffffff', // <---- change to var from _base.scss ($color-white)
        boxShadow: 'none',
      },
    },
  });

  const { classes } = useStyles();
  const productsList = useSelector((state) => state.products?.productsList);
  const { isShowing, showModal, closeModal } = useModal();

  const productOptions = productsList.map((product) => (
    <div className="list-item" key={product.productId}>
      <Button
        className={classes.button}
        variant="contained"
        fullWidth
        action={() => {
          dispatch(selectProduct(product));
          navigate(page);
          closeModal();
        }}
      >
        {product.name}
      </Button>
    </div>
  ));

  const defaultApp = (
    <ApplicationItem
      title={title}
      color={color}
      icon={icon}
      size={80}
      action={showModal}
    />
  );

  const shortcutApp = (
    <ShortcutItem
      title={title}
      color={color}
      icon={icon}
      size={30}
      action={showModal}
    />
  );

  return (
    <>
      {child === 'defaultApp' && defaultApp}

      {child === 'shortcutApp' && shortcutApp}

      <ModalHook
        title={t('Modal.modalTitle')}
        isShowing={isShowing}
        hide={showModal}
        onClose={closeModal}
      >
        {productsList.length > 0 ? (
          <div className="app-modal">{productOptions}</div>
        ) : (
          <div className="no-products-message">
            {t('Message.noProducts')}
          </div>
        )}
      </ModalHook>
    </>
  );
}

export default AnalysisReportApp;
