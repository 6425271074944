import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import './ConfirmPasswordPage.scss';
import logo from '../../assets/images/dilepix_logo_vertical.png';
import Button from '../../components/button/Button.js';
import instanceApi from '../../services/instanceApi.js';

function ConfirmPasswordPage(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { error } = props;
  const location = useLocation();
  const [code, setCode] = useState('');
  const [username, setUsername] = useState(location?.state?.username);
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  function validateForm() {
    return code.length && newPassword.length && confirmNewPassword.length > 0;
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      if (code === '' || newPassword === '' || confirmNewPassword === '') {
        setErrorMessage(
          <div className="input-error" data-testid="error-message">
            {t('Labels.labelValidateFailed')}
          </div>,
        );
        return;
      }
      if (newPassword !== confirmNewPassword) {
        setErrorMessage(
          <div className="input-error" data-testid="error-message">
            {t('Message.passwordMatch')}
          </div>,
        );
        return;
      }
      await instanceApi.post('/password/confirm', {
        username,
        newPassword,
        code,
      });
      navigate('/login');
    } catch (err) {
      if (err.response !== undefined && err.response.status === 400) {
        if (
          err.response.data.name === 'CodeMismatchException'
          || err.response.data.name === 'ExpiredCodeException'
        ) {
          setErrorMessage(
            <div className="input-error" data-testid="error-message">
              {t('ConfirmPasswordPage.codeFailed')}
            </div>,
          );
        } else if (err.response.data.name === 'InvalidParameterException') {
          setErrorMessage(
            <div className="input-error" data-testid="error-message">
              {t('Message.passwordLength')}
            </div>,
          );
        } else if (err.response.data.name === 'InvalidPasswordException') {
          setErrorMessage(
            <div className="input-error" data-testid="error-message">
              {t('Message.passwordCondition')}
            </div>,
          );
        } else if (err.response.data.name === 'LimitExceededException') {
          setErrorMessage(
            <div className="input-error" data-testid="error-message">
              {t('Message.limitExceeded')}
            </div>,
          );
        }
      }
    }
  };

  const handleClick = (event) => {
    event.preventDefault();
    navigate('/login');
  };

  return (
    <div className="auth-screen">
      <div className="auth-container">
        <Form onSubmit={handleSubmit}>
          <div className="auth-logo-container">
            <img className="auth-logo" src={logo} alt="logo-dilepix" />
          </div>
          <div className="confirm-title-container">
            <h3 className="confirm-title">
              {t('ConfirmPasswordPage.titleConfirmPassword')}
            </h3>
          </div>
          <div className="confirm-text-container">
            <p className="confirm-text">
              {t('ConfirmPasswordPage.textConfirmPassword')}
            </p>
          </div>
          <Form.Group size="lg" controlId="code">
            <Form.Label>
              {t('Labels.labelCode')}
            </Form.Label>
            <Form.Control
              autoComplete="off"
              type="code"
              value={code}
              onChange={(e) => setCode(e.target.value)}
            />
          </Form.Group>

          <Form.Group size="lg" controlId="usernameConfirm">
            <Form.Label>
              {t('Labels.labelUsername')}
            </Form.Label>
            <Form.Control
              type="text"
              value={username}
              disabled
              onChange={(e) => setUsername(e.target.value)}
            />
          </Form.Group>

          <Form.Group size="lg" controlId="newPassword">
            <Form.Label>
              {t('Labels.labelNewPassword')}
            </Form.Label>
            <Form.Control
              autoComplete="off"
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </Form.Group>

          <Form.Group size="lg" controlId="confirmNewPassword">
            <Form.Label>
              {t('Labels.labelConfirmNewPassword')}
            </Form.Label>
            <Form.Control
              autoComplete="off"
              type="password"
              value={confirmNewPassword}
              onChange={(e) => setConfirmNewPassword(e.target.value)}
            />
          </Form.Group>

          {(error || errorMessage) && <div>{error || errorMessage}</div>}

          <Button
            className="auth-button"
            variant="contained"
            size="large"
            type="sumbit"
            fullWidth
            disabled={!validateForm()}
          >
            {t('Button.reset')}
          </Button>

          <div className="go-back-container">
            <span
              role="button"
              className="go-back-text"
              onClick={handleClick}
              tabIndex={0}
              onKeyDown={handleClick}
            >
              {t('Message.goBackLogin')}
            </span>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default ConfirmPasswordPage;
