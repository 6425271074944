/* eslint-disable no-shadow */
import React, { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { BsFilter } from 'react-icons/bs/index.js';
import { useDispatch } from 'react-redux';

import './ModalFilters.scss';
import Button from '../button/Button.js';
import FilterOption from '../filter-options/FilterOption.js';
import ModalHook from '../modal/ModalHook.js';
import SearchBar from '../searchbar/SearchBar.js';
import { setPagination } from '../../features/paginationSlice.js';
import useModal from '../../hooks/useModal.js';

export const MultipleFilter = (rows, id, filterValue) => (
  (filterValue.length === 0) ? rows : rows.filter((r) => filterValue.includes(r.values[id]))
);

function ModalFilters({ column: { setFilter, preFilteredRows, id }, page }) {
  const { t } = useTranslation();
  const { isShowing, showModal, closeModal } = useModal();
  const [checkbox, setCheckbox] = useState(false);
  const [input, setInput] = useState('');
  const [count, setCount] = useState(0);
  const [isChecked, setIsChecked] = useState([]);
  const dispatch = useDispatch();

  const setFilteredParams = (filterArr, val) => {
    if (filterArr.includes(val)) {
      filterArr.splice(filterArr.indexOf(val), 1);
    } else {
      filterArr.push(val);
    }
    return filterArr.length === 0 ? undefined : filterArr;
  };

  const handleChange = (event) => {
    const { id, checked, value } = event.target;
    setCheckbox({ ...checkbox, [id]: checked });
    setIsChecked((prevChecked) => setFilteredParams(
      typeof prevChecked !== 'undefined' ? [...prevChecked] : [],
      value,
    ) || undefined);
  };

  useEffect(() => {
    if (page?.length === 0) {
      setFilter(undefined);
      setCheckbox(false);
      setIsChecked([]);
      setCount(0);
    }
  }, [page]);

  // Handle Modal
  const handleFilter = () => {
    setInput('');
    setFilter(isChecked);
    setCount(Object.values(checkbox).includes(true) ? 1 : 0);
    dispatch(setPagination(1));
  };

  const handleSearch = (e) => {
    setInput(e.target.value);
  };

  // reset searchbar input value when modal is closed
  const handleModalClose = () => {
    setInput('');
    closeModal();
  };

  // Calculate the options for filtering
  const options = useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows, input]);

  const getTitle = (id) => {
    switch (id) {
      case 'product':
        return t('DashboardPage.dashboardTable.product');
      case 'tag':
        return t('DashboardPage.dashboardTable.tag');
      case 'status':
        return t('DashboardPage.dashboardTable.status');
      default:
        return '';
    }
  };
  const renderModalTitle = getTitle(id);

  const renderSearchBar = (
    <SearchBar
      className="dashboard-filters-searchbar"
      onChange={(e) => handleSearch(e)}
      placeholder={t('ModalFilters.searchBar')}
    />
  );

  // check for options before rendering the component that uses it
  const renderFiltersList = options && options.length > 0 ? (
    <div className="filters-list">
      {options
        .filter((item) => typeof item !== 'undefined' && item.toLowerCase().includes(input.toLowerCase()))
        .map((option) => (
          <FilterOption
            key={option}
            option={option}
            checkbox={checkbox}
            handleChange={handleChange}
          />
        ))}
    </div>
  ) : null;

  return (
    <>
      <div className={count > 0 ? 'filtered-icon-ctr' : 'filter-icon-ctr'}>
        <BsFilter onClick={showModal} size={25} data-testid="filter_icon" />
      </div>

      <ModalHook
        title={renderModalTitle}
        isShowing={isShowing}
        hide={showModal}
        onClose={handleModalClose}
      >
        <div className="search-bar-container">{renderSearchBar}</div>
        <div>{renderFiltersList}</div>

        <div className="filters-btn-container">
          <Button
            variant="contained"
            className="validate-checkedId"
            action={() => { closeModal(handleFilter()); }}
          >
            {t('Button.validate')}
          </Button>
        </div>
      </ModalHook>
    </>
  );
}

export default ModalFilters;
