import React from 'react';

import { currentLanguage } from '../translate/languages.js';

function ActivityReportCell(value, key, tagsList, tagId, periodStartDate, periodEndDate) {
  return (
    // Format cell depending on data type
    <div className="tab-cell-ctr">
      {(typeof value.value === 'boolean' || typeof value.value === 'string')
        && key !== tagId
        && key !== periodStartDate
        && key !== periodEndDate && (
        <div className="table-item prod-item">{value.value.toString()}</div>
      )}
      {typeof value.value === 'number'
        && key !== periodStartDate
        && key !== periodEndDate && (
        <div className="table-item prod-item">{value.value.toFixed(2)}</div>
      )}
      <div className="table-item prod-item">
        {key === tagId && value.value === '-'
          ? '-'
          : tagsList
            .map((itm) => itm.tagId === value.value && itm.name)
            .filter(Boolean)
            .join('')}
      </div>
      {(key === periodStartDate || key === periodEndDate) && (
        <div className="table-item prod-item">
          {new Intl.DateTimeFormat(currentLanguage().dateTimeFormat, {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
          }).format(new Date(value.value))}
        </div>
      )}
    </div>
  );
}

export default ActivityReportCell;
