import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import React from 'react';
import { Bar, Line } from 'react-chartjs-2';
import { useSelector } from 'react-redux';

import chartTypes from './ChartType.js';

ChartJS.register(
  BarElement,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  Filler,
  Legend,
  Title,
  Tooltip,
);

function GenericChart() {
  const { data, options, chartType } = useSelector((state) => state.charts);

  const chartOptions = {
    ...options,
    plugins: {
      ...options.plugins,
      legend: {
        ...options.plugins.legend,
      },
      title: {
        ...options.plugins.title,
      },
    },
  };

  const plugins = [{
    id: 'chart-color',
    beforeDraw: (chart) => {
      const { ctx } = chart;
      ctx.save();
      ctx.globalCompositeOperation = 'destination-over';
      ctx.fillStyle = 'white';
      ctx.fillRect(0, 0, chart.width, chart.height);
      ctx.restore();
    },
  }];

  return (chartType === chartTypes.Line || chartType === chartTypes.Area) ? (
    <Line id="chartCanva" options={chartOptions} data={data} plugins={plugins} data-testid="chartCanva" />
  ) : (
    <Bar id="chartCanva" options={chartOptions} data={data} plugins={plugins} data-testid="chartCanva" />
  );
}

export default GenericChart;
