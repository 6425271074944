const chartColors = [
  'rgba(0, 110, 0, 1)',
  'rgba(0, 140, 249, 1)',
  'rgba(235, 172, 35, 1)',
  'rgba(184, 0, 88, 1)',
  'rgba(0, 187, 173, 1)',
  'rgba(209, 99, 230, 1)',
  'rgba(178, 69, 2, 1)',
  'rgba(255, 146, 135, 1)',
  'rgba(89, 84, 214, 1)',
  'rgba(0, 198, 248, 1)',
  'rgba(135, 133, 0, 1)',
];

export default chartColors;
