/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { fetchActivityAnalyses } from './api/apiSlice.js';

const initialState = {
  activityAnalyses: [],
  activityAnalysesCsv: [],
  status: null,
  error: null,
};

export const activitySlice = createSlice({
  name: 'activity',
  initialState,
  reducers: {
    resetActivityAnalyses: () => initialState,
    setActivityCsv: (state, { payload }) => {
      state.activityAnalysesCsv = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchActivityAnalyses.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchActivityAnalyses.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.activityAnalyses = action.payload;
      })
      .addCase(fetchActivityAnalyses.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export const { resetActivityAnalyses, setActivityCsv } = activitySlice.actions;
export default activitySlice.reducer;
