/* eslint-disable react/no-array-index-key */
import React from 'react';
import './PrivacyTable.scss';
import translation from '../translate/locales/fr/translation.json';

export function PrivacyPolicyTable() {
  const getHeadings = Object.keys(translation.PrivacyTable[0]);

  return (
    <div className="table-privacy-ctr">
      <table className="privacy-table">
        <thead>
          <tr>
            {getHeadings.map((header) => (
              <th key={header} className="privacy-header">
                {header}
              </th>
            ))}
          </tr>
        </thead>

        <tbody>
          {translation.PrivacyTable.map((row, index) => (
            <tr key={index}>
              {getHeadings.map((key) => (
                <td key={row[key]} className="privacy-table-body">
                  {row[key]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default PrivacyPolicyTable;
