import Swal from 'sweetalert2';
import Icon from '../assets/images/fail.png';
import './swalAlert.scss';

const errorAlert = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-success',
  },
  buttonsStyling: false,
});

const showAlert = (title, text, confirmButtonText, onConfirm) => {
  errorAlert
    .fire({
      imageUrl: `${Icon}`,
      imageHeight: 129,
      imageWidth: 150,
      title,
      text,
      confirmButtonText,
      allowOutsideClick: false,
    })
    .then(() => {
      onConfirm();
    });
};

export default showAlert;
