import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoMdAddCircleOutline } from 'react-icons/io/index.js';
import { useDispatch, useSelector } from 'react-redux';

import './ModalEditTag.scss';
import Button from '../button/Button.js';
import '../dropdown/EditDropdown.scss';
import ModalHook from '../modal/ModalHook.js';
import SearchBar from '../searchbar/SearchBar.js';
import {
  editTagInput, fetchAllAnalyses, fetchAllTags, useAddTagMutation,
} from '../../features/api/apiSlice.js';
import { setPagination } from '../../features/paginationSlice.js';
import { setTag } from '../../features/tagSlice.js';
import useMobileScreen from '../../hooks/useMobileScreen.js';
import useModal from '../../hooks/useModal.js';

function ModalEditTag({ isShowing, closeModal, ...props }) {
  const { t } = useTranslation();
  const { showModal } = useModal();
  const { isMobile } = useMobileScreen();
  const [tagName, setTagName] = useState('');
  const [error, setError] = useState('');
  const [charLimit, setCharLimit] = useState(false);
  const dispatch = useDispatch();
  const [addTag] = useAddTagMutation();
  const { productName } = useSelector((state) => state.products?.product);
  const { groupId } = useSelector((state) => typeof state.groups?.group[0] !== 'undefined' && state.groups?.group[0]);
  const { tagsList, tag } = useSelector((state) => state.tags);
  const { selectedAnalyses } = useSelector((state) => state.filters);
  const limit = parseInt(process.env.REACT_APP_OFFSET_PAGINATION, 10);

  const existingTags = tagsList.filter(
    (item) => item?.groupId === selectedAnalyses[0]?.groupId,
  );

  const onChangeInput = (event) => {
    const { value } = event.target;
    setCharLimit(
      value.length !== 0 && (value.length < 3 || value.length > 100),
    );
    setTagName(value);
  };

  const filteredTags = existingTags.filter((item) => {
    if (tagName === '') {
      return item.name;
    }
    return item.name.toLowerCase().includes(tagName.toLowerCase());
  });

  const selectExistingTag = async (existingTag) => {
    dispatch(setTag(existingTag));
    await dispatch(editTagInput()).unwrap();
    dispatch(fetchAllAnalyses(limit)).unwrap();
    dispatch(setPagination(1));
    props.setShow(false);
  };

  const tagIsExist = filteredTags.some((item) => item.name === tagName);

  const onCreateTag = () => {
    try {
      addTag({ productName, tagName, groupId }).then(async (response) => {
        const newTag = response?.data;
        dispatch(setTag(newTag));
        await dispatch(editTagInput()).unwrap();
        await dispatch(fetchAllTags()).unwrap();
        dispatch(fetchAllAnalyses(limit)).unwrap();
        dispatch(setPagination(1));
        props.setShow(false);
      });
    } catch (err) {
      setError(err);
    }
  };

  const isDisabled = tagName === '' || tag.length < 1 || tagIsExist === false;
  const isCreateDisabled = tagName === '' || tagIsExist === true || charLimit === true;

  return (
    <ModalHook
      title={t('ModalEdit.editTag')}
      isShowing={isShowing}
      hide={showModal}
      onClose={() => closeModal(props.setShow(false))}
    >
      <div className="search-bar-container">
        <SearchBar
          className="dashboard-filters-searchbar"
          placeholder={t('Message.searchBarPlaceholder')}
          onChange={(e) => onChangeInput(e)}
          value={tagName}
        />
        {charLimit && filteredTags.length < 1 && (
          <div className="char_limit">{t('Alerts.tagCharLimit')}</div>
        )}
      </div>

      <div className="filters-list">
        {filteredTags.map((item) => (
          <button
            type="button"
            className="edit-item-filters"
            onClick={() => selectExistingTag(item)}
            key={item.tagId}
          >
            {item.name}
          </button>
        ))}
      </div>

      <div className="filters-btn-container">
        {!isCreateDisabled && isDisabled && (
          <Button
            className={isCreateDisabled !== false ? 'disabled-btn' : 'edit-tag'}
            variant="contained"
            disabled={isCreateDisabled}
            action={() => onCreateTag()}
          >
            <IoMdAddCircleOutline
              size={25}
              title="add-tag"
              className="add-icon"
            />
            {t('Message.create')}
            {!isMobile && <span className="edited-tag">{tagName}</span>}
          </Button>
        )}
        {error && <span>{t('Alerts.createTagError')}</span>}
      </div>
    </ModalHook>
  );
}

export default ModalEditTag;
