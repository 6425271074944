/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import formatCsvData from '../utils/formatCsvData.js';

const initialState = {
  analysesCsvResults: [],
  status: null,
  error: null,
};

export const csvSlice = createSlice({
  name: 'csv',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(formatCsvData.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(formatCsvData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.analysesCsvResults = action.payload;
      })
      .addCase(formatCsvData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default csvSlice.reducer;
