/**
 * Routes where authentication is required.
 * Only accessible after user has accessToken in storage.
 */

import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

import store from '../store/configureStore.js';

export function AuthRoutes({ redirectPath = '/login', children }) {
  const token = useSelector((state) => state.user.accessToken);
  if (token === '') {
    return <Navigate to={redirectPath} replace />;
  }
  return children || <Outlet />;
}

export function ProtectedRoutes({
  redirectPath = '/home', noAuthPath = '/login', children, accessProp,
}) {
  const token = useSelector((state) => state.user.accessToken);
  const isLoggedIn = token !== '';
  if (isLoggedIn && !accessProp) {
    return <Navigate to={redirectPath} replace />;
  }
  if (store.getState().user.accessToken === '') {
    return <Navigate to={noAuthPath} replace />;
  }
  return children || <Outlet />;
}

export default { AuthRoutes, ProtectedRoutes };
