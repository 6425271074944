import React from 'react';

function DashboardProductCell({ value }) {
  return (
    <div className="tab-cell-ctr">
      <div className="table-item prod-item">{value}</div>
    </div>
  );
}

export default DashboardProductCell;
