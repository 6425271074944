import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import './PrivacyPolicy.scss';
import { PrivacyPolicyTable } from '../../components/table/PrivacyTable.js';

export function PrivacyPolicy() {
  const { t } = useTranslation();

  const mailToHref = 'mailto:contact@dilepix.com?subject=SendMail&body=Description';

  return (
    <div className="privacy-policy-ctr">
      <h1 className="privacy-title">{t('PrivacyPolicy.privacyPolicy')}</h1>
      <div className="privacy-border-image" />

      <p className="privacy-content" data-testid="privacy-p">
        {t('PrivacyPolicy.privacyContent1')}
      </p>
      <p className="privacy-content" data-testid="privacy-p">
        {t('PrivacyPolicy.privacyContent2')}
        <a href="https://dilepix.io" target="_blank" rel="noreferrer">
          {t('PrivacyPolicy.dilepixWebsite')}
        </a>
        {t('PrivacyPolicy.privacyContent3')}
      </p>
      <p className="privacy-content" data-testid="privacy-p">
        {t('PrivacyPolicy.privacyContent4')}
      </p>
      <p className="privacy-content" data-testid="privacy-p">
        {t('PrivacyPolicy.privacyContent5')}
      </p>
      <p className="privacy-content bd" data-testid="privacy-p">
        {t('PrivacyPolicy.privacyTitle1')}
      </p>
      <p className="privacy-content" data-testid="privacy-p">
        {t('PrivacyPolicy.privacyContent6')}
      </p>
      <p className="privacy-content" data-testid="privacy-p">
        {t('PrivacyPolicy.privacyContent7')}
      </p>
      <p className="privacy-content" data-testid="privacy-p">
        {t('PrivacyPolicy.privacyContent8')}
      </p>
      <p className="privacy-content" data-testid="privacy-p">
        {t('PrivacyPolicy.privacyContent9')}
      </p>
      <p className="privacy-content" data-testid="privacy-p">
        {t('PrivacyPolicy.privacyContent10')}
      </p>
      <p className="privacy-content" data-testid="privacy-p">
        {t('PrivacyPolicy.privacyContent11')}
      </p>
      <p className="privacy-content bd" data-testid="privacy-p">
        {t('PrivacyPolicy.privacyTitle2')}
      </p>
      <PrivacyPolicyTable />
      <p className="privacy-content bd" data-testid="privacy-p">
        {t('PrivacyPolicy.privacyContent12')}
      </p>
      <p className="privacy-content bd" data-testid="privacy-p">
        {t('PrivacyPolicy.privacyTitle3')}
      </p>
      <p className="privacy-content" data-testid="privacy-p">
        {t('PrivacyPolicy.privacyContent13')}
      </p>
      <p className="privacy-content bd" data-testid="privacy-p">
        {t('PrivacyPolicy.privacyTitle4')}
      </p>
      <p className="privacy-content" data-testid="privacy-p">
        {t('PrivacyPolicy.privacyContent14')}
      </p>
      <p className="privacy-content" data-testid="privacy-p">
        {t('PrivacyPolicy.privacyContent15')}
      </p>
      <p className="privacy-content" data-testid="privacy-p">
        {t('PrivacyPolicy.privacyContent16')}
      </p>
      <p className="privacy-content bd" data-testid="privacy-p">
        {t('PrivacyPolicy.privacyTitle5')}
      </p>
      <p className="privacy-content" data-testid="privacy-p">
        {t('PrivacyPolicy.privacyContent17')}
      </p>
      <p className="privacy-content bd" data-testid="privacy-p">
        {t('PrivacyPolicy.privacyTitle6')}
      </p>
      <p className="privacy-content" data-testid="privacy-p">
        {t('PrivacyPolicy.privacyContent18')}
      </p>
      <p className="privacy-content" data-testid="privacy-p">
        {t('PrivacyPolicy.privacyContent19')}
      </p>
      <p className="privacy-content bd" data-testid="privacy-p">
        {t('PrivacyPolicy.privacyTitle7')}
      </p>
      <p className="privacy-content" data-testid="privacy-p">
        {t('PrivacyPolicy.privacyContent20')}
      </p>
      <p className="privacy-content bd" data-testid="privacy-p">
        {t('PrivacyPolicy.privacyTitle8')}
      </p>
      <p className="privacy-content" data-testid="privacy-p">
        {t('PrivacyPolicy.privacyContent21')}
      </p>
      <p className="privacy-content bd" data-testid="privacy-p">
        {t('PrivacyPolicy.privacyTitle9')}
      </p>
      <p className="privacy-content" data-testid="privacy-p">
        {t('PrivacyPolicy.privacyContent22')}
      </p>
      <p className="privacy-content" data-testid="privacy-p">
        {t('PrivacyPolicy.contactInfo')}
      </p>
      <p className="privacy-content bd" data-testid="privacy-p">
        {t('PrivacyPolicy.dilepix')}
      </p>
      <p className="privacy-content" data-testid="privacy-p">
        {t('PrivacyPolicy.address')}
      </p>
      <p className="privacy-content" data-testid="privacy-p">
        {t('PrivacyPolicy.contactEmail')}
        <a href={mailToHref} target="_blank" rel="noreferrer">
          {t('PrivacyPolicy.contactDilepix')}
        </a>
      </p>

      <div className="privacy-go-back">
        <Link to="/home" className="privacy-go-back-txt">
          {t('Message.back')}
        </Link>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
