import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import './EditTagTable.scss';
import DeleteCell from './DeleteCell.js';
import EditCell from './EditCell.js';
import SimpleCell from './SimpleCell.js';
import Table from './Table.js';
import ModalFilters, { MultipleFilter } from '../app-dashboard/ModalFilters.js';
import Button from '../button/Button.js';
import ModalHook from '../modal/ModalHook.js';
import { fetchTags, useDeleteTagMutation, useEditTagMutation } from '../../features/api/apiSlice.js';
import { setPagination } from '../../features/paginationSlice.js';
import { setTag } from '../../features/tagSlice.js';
import useModal from '../../hooks/useModal.js';
import showAlert from '../../utils/swalAlert.js';

function EditTagTable() {
  const [newTag, setNewTag] = useState();
  const [modalId, setModalId] = useState();
  const [isTagExist, setIsTagExist] = useState(false);
  const [charLimit, setCharLimit] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { showModal, isShowing, closeModal } = useModal();
  const [editTag] = useEditTagMutation();
  const [deleteTag] = useDeleteTagMutation();
  const { productName } = useSelector((state) => state.products?.product);
  const { tagsList } = useSelector((state) => state.tags);
  const {
    tagId, name, tenantId, groupId,
  } = useSelector((state) => typeof state.tags?.tag !== 'undefined' && state.tags?.tag);

  useEffect(() => {
    dispatch(setPagination(1));
  }, [tagsList]);

  useEffect(() => {
    setIsTagExist(false);
    setCharLimit(false);
  }, [isShowing]);

  function handleClick(row) {
    dispatch(setTag(row.original));
    showModal();
  }

  function handleChange(e) {
    const { value } = e.target;
    setIsTagExist(tagsList.reduce((acc, el) => acc || el.name === value, false));
    setCharLimit(value.length !== 0 && (value.length < 3 || value.length > 100));
    setNewTag(value);
  }

  async function onSubmit() {
    editTag({
      productName, tagId, tenantId, groupId, newTag,
    }).unwrap()
      .then(() => {
        dispatch(fetchTags()).unwrap();
      }).catch((error) => {
        if (error?.status === 400) {
          showAlert(
            `${t('Alerts.error')}`,
            `${t('Alerts.createTagError')}`,
            `${t('Message.back')}`,
          );
        }
      });
    dispatch(setPagination(1));
    closeModal();
  }

  async function onDeleteSubmit() {
    deleteTag({ productName, tagId })
      .unwrap()
      .then(() => {
        dispatch(fetchTags()).unwrap();
      })
      .catch((error) => {
        if (error?.status === 409) {
          showAlert(
            `${t('Alerts.error')}`,
            `${t('Alerts.TagUsedByInputError')}`,
            `${t('Message.back')}`,
          );
        }
      });
    dispatch(setPagination(1));
    closeModal();
  }

  const columns = useMemo(() => [
    {
      id: 'tag',
      Header: `${t('EditTagTable.tag')}`,
      accessor: 'name',
      Cell: SimpleCell,
      Filter: ModalFilters,
      filter: MultipleFilter,
    },
    {
      id: 'edit_tag',
      Header: `${t('EditTagTable.edit')}`,
      accessor: 'edit_tag',
      disableSortBy: true,
      Cell: ({ row }) => (
        EditCell(row, handleClick, setModalId)
      ),
    },
    {
      id: 'delete_tag',
      Header: `${t('EditTagTable.delete')}`,
      accessor: 'delete_tag',
      disableSortBy: true,
      Cell: ({ row }) => (
        DeleteCell(row, handleClick, setModalId)
      ),
    },
  ], []);

  const editTagModalContent = (
    <>
      <div className="edit_subtitle">{t('ModalEdit.editSubtitle')}</div>
      <input
        placeholder={name}
        onChange={handleChange}
        className="input_ctr"
      />
      {isTagExist && (<div className="existing_tag">{t('ModalEdit.existingTag')}</div>)}
      {charLimit && <div className="char_limit">{t('Alerts.tagCharLimit')}</div>}
      <div className="edit_btn_ctr">
        <Button variant="contained" className="cancel_btn" action={closeModal}>
          {t('Button.cancel')}
        </Button>
        {(!isTagExist && !charLimit) && (
        <Button variant="contained" className="edit_btn" action={() => onSubmit()}>
          {t('Button.edit')}
        </Button>
        )}
      </div>
    </>
  );

  const deleteTagModalContent = (
    <>
      <div className="delete-warning">
        {`${t('ModalDelete.deleteWarning')} `}
        <span className="tag_name">{name}</span>
        {' ?'}
      </div>
      <div className="delete-btn-ctr">
        <Button variant="contained" className="cancel" action={closeModal}>
          {t('Button.cancel')}
        </Button>
        <Button variant="contained" className="delete-tag" action={() => onDeleteSubmit()}>
          {t('Button.delete')}
        </Button>
      </div>
    </>
  );

  return (
    <>
      {tagsList?.length < 1 ? <div className="no_tag_message">{t('Message.noTag')}</div>
        : (
          <Table
            columns={columns}
            data={tagsList}
            showFilters={false}
            chartOption={false}
            chart={false}
          />
        )}
      <ModalHook
        title={modalId === 'edit' ? t('ModalEdit.editTag') : t('ModalDelete.deleteConfirmation')}
        isShowing={isShowing}
        hide={showModal}
        onClose={closeModal}
      >
        {modalId === 'edit' ? editTagModalContent : deleteTagModalContent}
      </ModalHook>
    </>
  );
}

export default EditTagTable;
