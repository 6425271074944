import i18n from './i18n.js';
import unitedKingdomFlag from './locales/en/gb.png';
import frenchFlag from './locales/fr/fr.png';

export const resources = [
  {
    id: 'fr',
    label: 'Français',
    miniLabel: 'FR',
    icon: [frenchFlag],
    dateTimeFormat: 'fr-FR',
    inputFormat: 'dd/MM/yyyy',
  },
  {
    id: 'en',
    label: 'English',
    miniLabel: 'EN',
    icon: [unitedKingdomFlag],
    dateTimeFormat: 'en-EN',
    inputFormat: 'MM/dd/yyyy',
  },
];

export const currentLanguage = () => resources.find(
  (element) => element.id === i18n.language,
);
