/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { fetchAllGroups } from './api/apiSlice.js';

const initialState = {
  groupList: [],
  group: [],
  status: null,
};

export const groupSlice = createSlice({
  name: 'groups',
  initialState,
  reducers: {
    setGroup: (state, { payload }) => {
      state.group = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllGroups.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchAllGroups.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.groupList = action.payload.flat(Infinity);
      })
      .addCase(fetchAllGroups.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export const { setGroup } = groupSlice.actions;
export default groupSlice.reducer;
