import { translateObjValue } from '../assets/analysesKeys.js';
import { currentLanguage } from '../components/translate/languages.js';
import { setProductEndpoint } from '../services/setProductEndpoint.js';

export const formatCsvAnalysesResults = (item, productName, tagsList) => {
  const productEndpoint = setProductEndpoint(productName);

  const tagName = tagsList.find((key) => key.tagId === item.input.tagId);
  const formatTagName = tagName?.name !== '' && tagName?.name !== null && tagName?.name !== undefined ? tagName?.name : '-';

  const deficiencyTranslation = productEndpoint === 'deficiencies' && translateObjValue(item, currentLanguage());

  const formatCsvData = (productEndpoint === 'countings' && {
    count: item.count,
    tagId: formatTagName,
  })
  || (productEndpoint === 'deficiencies' && {
    deficiency: deficiencyTranslation?.deficiency,
    tagId: formatTagName,
  });

  return formatCsvData;
};

export default formatCsvAnalysesResults;
