/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect } from 'react';
import { GrFormPrevious, GrFormNext } from 'react-icons/gr/index.js';
import { connect, useDispatch, useSelector } from 'react-redux';

import './CustomPagination.scss';
import { fetchAnalyses } from '../../features/api/apiSlice.js';
import { setPagination, setOffsetPagination } from '../../features/paginationSlice.js';
import { usePagination, DOTS } from '../../hooks/usePagination.js';

function CustomPagination(props) {
  const { filteredItems } = props;
  const { startDate, endDate } = filteredItems;
  const dispatch = useDispatch();
  const { totalAnalyses } = useSelector((state) => state.analyses);
  const { limit, offset, currentPage } = useSelector((state) => state.pagination);
  const siblingCount = parseInt(
    process.env.REACT_APP_SIBLING_COUNT_PAGINATION,
    10,
  );

  const pageSize = parseInt(process.env.REACT_APP_OFFSET_PAGINATION, 10);
  const xOffset = parseInt(process.env.REACT_APP_OFFSET_PAGINATION, 10);

  const { paginationRange } = usePagination({
    currentPage,
    totalAnalyses,
    siblingCount,
    pageSize,
  });

  const handleNextPage = () => {
    dispatch(setOffsetPagination(offset + xOffset));
    dispatch(setPagination(currentPage + 1));
  };

  const handlePreviousPage = () => {
    dispatch(setOffsetPagination(offset - xOffset));
    dispatch(setPagination(currentPage - 1));
  };

  const handlePageClick = (pageClicked) => {
    const setOffsetPage = xOffset * pageClicked - xOffset;
    dispatch(setOffsetPagination(setOffsetPage));
    dispatch(setPagination(pageClicked));
  };

  useEffect(() => {
    const asyncAnalyses = async () => {
      await dispatch(fetchAnalyses({
        startDate, endDate, limit, offset,
      })).unwrap();
    };
    try {
      asyncAnalyses();
    } catch (error) {
      throw new Error(error.message);
    }
  }, [currentPage]);

  const lastPage = typeof paginationRange !== 'undefined'
    && paginationRange[paginationRange.length - 1];

  return (
    <div className="custom-pagination-ctr">
      <div
        role="button"
        aria-label="Previous page"
        tabIndex={0}
        className={currentPage === 1 ? 'pagination-disabled' : 'pagination-btn'}
        onClick={handlePreviousPage}
        onKeyPress={handlePreviousPage}
        data-testid="previous"
      >
        <GrFormPrevious />
      </div>

      {typeof paginationRange !== 'undefined'
        && paginationRange.map((page, i) => {
          if (page === DOTS) {
            return (
              <li className="custom-pagination-item dots" key={i}>
                &#8230;
              </li>
            );
          }

          return (
            <li
              className={page === currentPage ? 'custom-pagination-item' : 'total-page'}
              key={i}
              onClick={() => {
                handlePageClick(page);
              }}
              onKeyPress={() => {
                handlePageClick(page);
              }}
            >
              {page}
            </li>
          );
        })}

      <div
        role="button"
        aria-label="Next page"
        tabIndex={0}
        type="button"
        className={currentPage === lastPage ? 'pagination-disabled' : 'pagination-btn'}
        onClick={handleNextPage}
        onKeyPress={handleNextPage}
        data-testid="next"
      >
        <GrFormNext />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  filteredItems: state.filters.filteredItems,
});

export default connect(mapStateToProps)(CustomPagination);
