/* eslint-disable max-len */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch, useSelector } from 'react-redux';

import './DashboardTable.scss';
import ModalFilters, { MultipleFilter } from './ModalFilters.js';
import DashboardProductCell from './DashboardProductCell.js';
import DashboardStatusCell from './DashboardStatusCell.js';
import DateCell from '../table/DateCell.js';
import Table from '../table/Table.js';
import SimpleCell from '../table/SimpleCell.js';
import { translateValue } from '../../assets/analysesKeys.js';
import fileIcon from '../../assets/images/file.png';
import { fetchInput } from '../../features/api/apiSlice.js';
import { resetInput } from '../../features/inputSlice.js';

function DashboardTable(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { tagsList } = props;
  const { analyses } = useSelector((state) => state.allAnalyses);

  const sortItems = (prev, curr, columnId) => prev?.original[columnId]?.toLowerCase().localeCompare(curr?.original[columnId]?.toLowerCase());

  const columns = useMemo(() => [
    {
      id: 'product',
      Header: `${t('DashboardPage.dashboardTable.product')}`,
      accessor: 'product',
      Cell: DashboardProductCell,
      Filter: ModalFilters,
      filter: MultipleFilter,
      sortType: (prev, curr, columnId) => sortItems(prev, curr, columnId),
    },
    {
      id: 'tag',
      Header: `${t('DashboardPage.dashboardTable.tag')}`,
      accessor: 'tag',
      Cell: SimpleCell,
      Filter: ModalFilters,
      filter: MultipleFilter,
      sortType: (prev, curr, columnId) => sortItems(prev, curr, columnId),
    },
    {
      id: 'createdOn',
      Header: `${t('DashboardPage.dashboardTable.creationDate')}`,
      accessor: 'createdOn',
      Cell: DateCell,
      disableFilters: true,
    },
    {
      id: 'file',
      Header: `${t('DashboardPage.dashboardTable.file')}`,
      accessor: 'file',
      disableSortBy: true,
      disableFilters: true,
    },
    {
      id: 'status',
      Header: `${t('DashboardPage.dashboardTable.status')}`,
      accessor: 'status',
      Cell: ({ value, row }) => (
        DashboardStatusCell(value, row)
      ),
      Filter: ModalFilters,
      filter: MultipleFilter,
      sortType: (prev, curr, columnId) => sortItems(prev, curr, columnId),
    },
  ]);

  const downloadFile = (url) => {
    window.open(url, '_blank', 'noreferrer');
  };

  const handleFile = async (item) => {
    dispatch(resetInput());
    const response = await dispatch(
      fetchInput({ product: item.product, inputId: item.input?.id }),
    ).unwrap();
    const { url } = response;
    downloadFile(url);
  };

  const preData = [];
  const sortedAnalysis = [...analyses].sort((a, b) => {
    const dateA = new Date(a.createdOn);
    const dateB = new Date(b.createdOn);
    return dateB - dateA;
  });
  sortedAnalysis.forEach((item) => {
    const tagName = tagsList.find((tag) => tag?.tagId === item?.input?.tagId);
    preData.push({
      product: item.product,
      createdOn: item.createdOn,
      tag: item.input.tagId === null ? '-' : tagName?.name,
      status:
        (item?.status?.value === 'SUCCEEDED'
          && `${t('DashboardPage.dashboardTable.succeeded')}`)
        || (item?.status?.value === 'RUNNING'
          && `${t('DashboardPage.dashboardTable.running')}`)
        || (item?.status?.value === 'FAILED'
          && `${t('DashboardPage.dashboardTable.failed')}`),
      statusDetail: typeof item?.status?.detail !== 'undefined' && translateValue(item?.status?.detail),
      file: (
        <div
          role="button"
          tabIndex={0}
          aria-hidden="true"
          onClick={() => handleFile(item)}
          className="media-icon"
          data-testid="media_icon"
        >
          <img src={fileIcon} alt="file-icon" width={25} />
        </div>
      ),
    });
    return preData;
  });

  const data = useMemo(() => preData, [preData]);

  return (
    <div className="dashboard-table-container">
      <Table
        columns={columns}
        data={data}
        showFilters
        chartOption={false}
        chart={false}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  tagsList: state.tags.tagsList,
});

export default connect(mapStateToProps)(DashboardTable);
