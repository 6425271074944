export const createArrayFiles = (file) => {
  let fileToUpload = [];

  for (let i = 0; i < file.length; i += 1) {
    fileToUpload = [
      ...fileToUpload,
      {
        name: file[i].path,
        size: file[i].size,
        type: file[i].type,
        progress: 0,
        file: file[i],
        cancel: false,
      },
    ];
  }

  return fileToUpload;
};

export default createArrayFiles;
