import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import './EditDropdown.scss';
import { useGetGroupQuery } from '../../features/api/apiSlice.js';
import { setGroup } from '../../features/groupSlice.js';
import { selectProduct } from '../../features/productSlice.js';

function EditDropdown(props) {
  const { selectedAnalyses } = props;
  const { productsList, product } = useSelector((state) => state.products);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [skip, setSkip] = useState(true);
  const { data, isSuccess, isFetching } = useGetGroupQuery(product?.productName, { skip });

  const selectedProduct = productsList.find(
    (p) => p.name === selectedAnalyses[0]?.product && p.productName,
  );

  // all selected products needs to be the same for valid edit
  const isValidEdit = selectedAnalyses.length > 0 && selectedAnalyses
    .map((p) => p.product)
    .every((x) => x === selectedAnalyses[0]?.product);

  // all selected products needs to have a tag
  const isValidDelete = selectedAnalyses
    .map((p) => p?.input?.tagId)
    .some((x) => x !== null);

  useEffect(() => {
    if (isValidEdit) {
      dispatch(selectProduct(selectedProduct));
      setSkip(false);
    }
  }, [isValidEdit]);

  useEffect(() => {
    const fetchData = async () => {
      if (isSuccess && !isFetching) {
        setSkip(true);
        dispatch(setGroup(data));
      }
    };
    fetchData();
  }, [isSuccess, data]);

  function handleClick(e) {
    props.setTargetBtn(e.currentTarget.id);
    props.setShowDelModal(true);
  }

  return (
    <div className="dropdown-ctr">
      <div className="dropdown-title">{t('EditDropdown.editTag')}</div>
      <div className="dropdown-list-element">
        <button
          type="button"
          className={isValidEdit ? 'dropdown-element' : 'disabled-el'}
          onClick={() => props.setShow(true)}
          disabled={!isValidEdit}
        >
          {t('Button.edit')}
        </button>
        <button
          type="button"
          id="tag_action"
          className={isValidDelete ? 'dropdown-element' : 'disabled-el'}
          onClick={(e) => handleClick(e)}
          disabled={!isValidDelete}
        >
          {t('Button.delete')}
        </button>
        <div className="dropdown-divider" role="separator" />
        <button
          type="button"
          className="dropdown-element"
          onClick={() => navigate('/edit-tag')}
        >
          {t('Button.editTag')}
        </button>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  selectedAnalyses: state.filters.selectedAnalyses,
});

export default connect(mapStateToProps)(EditDropdown);
