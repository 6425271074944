import {
  Box,
  Chip,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Switch,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import './ChartDataPicker.scss';
import chartColors from './chartColors.js';
import chartTypes from '../generic-chart/ChartType.js';
import i18n from '../translate/i18n.js';
import { analysesKeysMap, analysesKeysMapEN } from '../../assets/analysesKeys.js';
import { setChartData } from '../../features/chartSlice.js';
import { useUniqueDate } from '../../hooks/useUniqueDate.js';
import { setProductEndpoint } from '../../services/setProductEndpoint.js';

function ChartDataPicker({ hidden }) {
  const analysesKeys = i18n.language === 'fr' ? analysesKeysMap : analysesKeysMapEN;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { product } = useSelector((state) => state.products);
  const { analysesCsvResults } = useSelector((state) => state.csvData);
  const { activityAnalysesCsv } = useSelector((state) => state.activities);
  const { chartType } = useSelector((state) => state.charts);

  const productType = setProductEndpoint(product.productName);

  const analysesData = productType === 'activities' ? activityAnalysesCsv : analysesCsvResults;

  const [xAxis, setXAxis] = useState('');
  const [uniqueLabels, setUniqueLabels] = useState(true);
  const [listDatasets, setListDatasets] = useState([]);
  const [localLanguage, setLocalLanguage] = useState(i18n.language);

  const genericProductData = useUniqueDate(analysesData);

  const localLabels = () => (uniqueLabels ? [...new Set(analysesData.map(
    (analyses) => analyses[[xAxis]],
  ))] : analysesData.map((analyses) => analyses[[xAxis]]));

  const genericProductLabels = () => genericProductData.map((analyses) => analyses[[xAxis]]);

  const datasetGenericProduct = (key) => {
    const datasetArr = [];
    for (let i = 0; i < genericProductData.length; i += 1) {
      datasetArr.push({
        x: genericProductData[i][xAxis],
        y: genericProductData[i][key],
      });
    }
    return datasetArr;
  };

  useEffect(() => {
    const previousLanguageAnalysesKeysMap = localLanguage === 'fr' ? analysesKeysMap : analysesKeysMapEN;
    const translatedXAxis = Object.keys(previousLanguageAnalysesKeysMap).find(
      (key) => previousLanguageAnalysesKeysMap[key] === xAxis,
    );
    const translatedListDatasets = listDatasets.map((value) => analysesKeys[
      Object.keys(previousLanguageAnalysesKeysMap).find(
        (key) => previousLanguageAnalysesKeysMap[key] === value,
      )]);
    setXAxis(analysesKeys[translatedXAxis] || xAxis);
    setListDatasets(translatedListDatasets);
    setLocalLanguage(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    switch (productType) {
      case 'activities':
        setXAxis(analysesKeys.periodStartDate);
        if (product?.productName?.toLowerCase().includes('porcine')) {
          setListDatasets([
            analysesKeys.standingInMotionMeanCount,
            analysesKeys.standingStaticMeanCount,
            analysesKeys.lyingOnTheSideMeanCount,
            analysesKeys.proneLyingMeanCount,
            analysesKeys.sittingMeanCount,
            analysesKeys.eatingMeanCount,
            analysesKeys.drinkingMeanCount,
          ]);
        } else {
          setListDatasets([
            analysesKeys.standingInMotionMeanCount,
            analysesKeys.standingInContactWithBeddingMeanCount,
            analysesKeys.standingStaticMeanCount,
            analysesKeys.lyingMeanCount,
            analysesKeys.atDrinkingTroughMeanCount,
            analysesKeys.atTroughMeanCount,
          ]);
        }
        break;
      case 'countings':
        setXAxis('Date');
        setListDatasets([analysesKeys.count]);
        break;
      case 'deficiencies':
        setXAxis('Date');
        setListDatasets([analysesKeys.deficiency]);
        break;
      default:
    }
  }, []);

  useEffect(() => {
    let indexColor = 0;
    const dataset = [];
    if (productType === 'activities' && analysesData[0] !== undefined) {
      Object.keys(analysesData[0]).forEach((key) => {
        if (analysesData[0][key] !== undefined
          && listDatasets.includes(key)) {
          dataset.push({
            fill: chartType === chartTypes.Area,
            label: localLanguage === 'fr' ? `${key} ` : key,
            data: localLabels().map((uniqueDate) => {
              let value = 0;
              analysesData.forEach((result) => {
                if (result[xAxis] === uniqueDate) {
                  value += result[key];
                }
              });
              return value;
            }),
            backgroundColor: chartColors[indexColor],
          });
          indexColor += 1;
        }
      });
    } else if (genericProductData[0] !== undefined) {
      Object.keys(genericProductData[0]).forEach((key) => {
        if (genericProductData[0][key] && listDatasets.includes(key)) {
          dataset.push({
            fill: chartType === chartTypes.Area,
            label: localLanguage === 'fr' ? `${key} ` : key,
            data: datasetGenericProduct(key),
            backgroundColor: chartColors[indexColor],
          });
          indexColor += 1;
        }
      });
    }
    dispatch(setChartData({ labels: productType === 'activities' ? localLabels() : genericProductLabels(), datasets: dataset }));
  }, [uniqueLabels, xAxis, listDatasets, analysesCsvResults, activityAnalysesCsv]);

  const handleChangeUniqueLabels = (event) => {
    setUniqueLabels(event.target.checked);
  };

  const handleChangeXAxis = (event) => {
    setXAxis(event.target.value);
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setListDatasets(
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const listColumnData = analysesData.length > 0 && Object.keys(analysesData[0]).map((name) => (
    <MenuItem key={name} value={name}>
      {name}
    </MenuItem>
  ));

  return hidden ? (
    <Grid container direction="column" spacing={1.5}>
      <Grid item>
        <FormControl>
          {productType === 'activities'
            && (
              <FormControlLabel
                control={(<Switch checked={uniqueLabels} onChange={handleChangeUniqueLabels} />)}
                label={t('Chart.chartDataPicker.uniqueData')}
              />
            )}
        </FormControl>
      </Grid>
      <Grid item>
        <FormControl fullWidth>
          <InputLabel id="axisX" aria-labelledby="axisX">{t('Chart.chartDataPicker.xAxis')}</InputLabel>
          <Select
            value={(analysesData.length > 0 && analysesData[0][xAxis] !== undefined) ? xAxis : ''}
            label={t('Chart.chartDataPicker.xAxis')}
            onChange={handleChangeXAxis}
          >
            {listColumnData}
          </Select>
        </FormControl>
      </Grid>
      <Grid item>
        <FormControl fullWidth>
          <InputLabel id="data" aria-labelledby="data">{t('Chart.chartDataPicker.data')}</InputLabel>
          <Select
            multiple
            value={listDatasets}
            onChange={handleChange}
            input={<OutlinedInput label={t('Chart.chartDataPicker.data')} />}
            renderValue={() => (
              <Box className="list-datasets-ctn">
                {listDatasets.map((value) => (
                  <Chip key={value} label={value} />
                ))}
              </Box>
            )}
          >
            {listColumnData}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  )
    : null;
}

export default ChartDataPicker;
