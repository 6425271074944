import React from 'react';
import './ApplicationItem.scss';

function ApplicationItem({
  title, icon, action, color, size, ...props
}) {
  return (
    <div
      role="button"
      tabIndex={0}
      className={`application-item ${color}`}
      onClick={action}
      onKeyDown={action}
      {...props}
    >
      <img
        alt="application-icon"
        src={icon}
        width={size}
        className="application-icon"
      />
      <h4 className="application-item-title">{title}</h4>
    </div>
  );
}

export default ApplicationItem;
