import { saveAs } from 'file-saver';
import {
  Download as DownloadIcon,
  Settings as SettingsIcon,
} from '@mui/icons-material';
import {
  Box,
  Divider,
  Grid,
  IconButton,
  Stack,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles/index.js';
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './Chart.scss';
import ChartDrawer from '../chart-drawer/ChartDrawer.js';
import GenericChart from '../generic-chart/GenericChart.js';
import colors from '../../assets/colors.js';
import { fetchAnalyses } from '../../features/api/apiSlice.js';
import { setProductEndpoint } from '../../services/setProductEndpoint.js';
import formatCsvData from '../../utils/formatCsvData.js';

const theme = createTheme({
  palette: {
    primary: {
      main: colors.black,
    },
  },
});

function Chart() {
  const [openDrawer, setOpenDrawer] = useState(false);
  const chartRef = useRef(null);
  const { datasets } = useSelector((state) => state.charts?.data);
  const { product } = useSelector((state) => state.products);
  const { filteredItems } = useSelector((state) => state.filters);
  const { startDate, endDate } = filteredItems;
  const dispatch = useDispatch();
  const { totalAnalyses } = useSelector((state) => state.analyses);

  const counting = 'countings';
  const deficiency = 'deficiencies';

  const productEndpoint = setProductEndpoint(product.productName);

  const fetchAnalysesData = async () => {
    if (filteredItems !== undefined) {
      if (productEndpoint === counting || productEndpoint === deficiency) {
        try {
          await dispatch(fetchAnalyses({
            startDate,
            endDate,
            limit: totalAnalyses,
            offset: 0,
          })).unwrap();
        } catch (error) {
          console.error('Error fetching analyses:', error);
        }
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchAnalysesData();
        dispatch(formatCsvData());
      } catch (error) {
        console.error('Error fetching and formatting data:', error);
      }
    };
    fetchData();
  }, []);

  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  const saveCanvas = () => {
    const canvasSave = document.getElementById('chartCanva');
    canvasSave?.toBlob((blob) => {
      saveAs(blob, 'Chart.png');
    });
  };

  useEffect(() => {
    chartRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Stack>
        <Grid
          container
          spacing={0}
          display="flex"
          direction="column"
          alignItems="center"
          alignContent="center"
          justifyContent="center"
        >
          <div className="chart" ref={chartRef} data-testid="canvas">
            {datasets.length > 0 && <GenericChart />}
          </div>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              width: 'fit-content',
              borderRadius: 1,
              bgcolor: 'background.paper',
              color: 'text.secondary',
              '& svg': {
                m: 0,
              },
              '& hr': {
                mx: 0.5,
              },
            }}
          >
            <IconButton
              className="chart-option-button"
              style={{
                backgroundColor: openDrawer ? colors.green : colors.white,
              }}
              onClick={() => (openDrawer ? handleDrawerClose() : handleDrawerOpen())}
              data-testid="setting"
            >
              <SettingsIcon
                color="primary"
                style={{ color: openDrawer ? colors.white : colors.black }}
                fontSize="small"
              />
            </IconButton>
            <Divider orientation="vertical" variant="middle" flexItem />
            <IconButton
              className="chart-option-button"
              onClick={() => saveCanvas()}
              data-testid="save_chart_icon"
            >
              <DownloadIcon color="primary" fontSize="small" />
            </IconButton>
          </Box>
          <ChartDrawer handleClick={handleDrawerClose} open={openDrawer} />
        </Grid>
      </Stack>
    </ThemeProvider>
  );
}

export default Chart;
