import React from 'react';
import { useTranslation } from 'react-i18next';
import './ProfilePage.scss';
import { useSelector } from 'react-redux';

function ProfilePage() {
  const { t } = useTranslation();
  const { username, email, tenantName } = useSelector((state) => state.user);

  return (
    <div className="profile-screen">
      <div className="profile-title-container">
        <div className="profile-title">{t('ProfilePage.profileTitle')}</div>
        <div className="profile-border bottom-border" />
      </div>

      <div className="input-container">
        <ul className="profile-list">
          <li>
            <span className="span-label">{t('Labels.labelUsername')}</span>
            <span className="span-info">{username}</span>
          </li>

          <li>
            <span className="span-label">{t('Labels.labelEmail')}</span>
            <span className="span-info">{email}</span>
          </li>

          <li>
            <span className="span-label">{t('Labels.labelTenant')}</span>
            <span className="span-info">{tenantName}</span>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default ProfilePage;
