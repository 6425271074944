import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './OffsetFilter.scss';
import { fetchAllAnalyses } from '../../features/api/apiSlice.js';
import { setLimitPerPage, setPagination } from '../../features/paginationSlice.js';

function OffsetFilter() {
  const dispatch = useDispatch();
  const { limit } = useSelector((state) => state.pagination);
  const options = [10, 20, 50, 100, 250];

  const handleChange = async (n) => {
    dispatch(setLimitPerPage(Number(n)));
    dispatch(setPagination(1));
    await dispatch(fetchAllAnalyses(n)).unwrap();
  };

  return (
    <div className="select-ctr">
      <select
        className="value-selected"
        value={limit}
        onChange={(e) => {
          handleChange(e.target.value);
        }}
      >
        {options.map((item) => (
          <option className="value-options" key={item} value={item}>
            {item}
          </option>
        ))}
      </select>
    </div>
  );
}

export default OffsetFilter;
