import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import './ResetPasswordPage.scss';
import logo from '../../assets/images/dilepix_logo_vertical.png';
import Button from '../../components/button/Button.js';
import instanceApi from '../../services/instanceApi.js';

function ResetPasswordPage(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { error } = props;
  const [username, setUsername] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  function validateForm() {
    return username.length > 0;
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await instanceApi.post(`/password/reset?username=${username}`);
      navigate('/reset/confirmation', { state: { username } });
    } catch (err) {
      if (err.response !== undefined && err.response.status === 400) {
        if (err.response.data.name === 'LimitExceededException') {
          setErrorMessage(
            <div className="input-error">
              {t('Message.limitExceeded')}
            </div>,
          );
        } else if (err.response.data.name === 'UserNotFoundException') {
          setErrorMessage(
            <div className="input-error">
              {t('ResetPasswordPage.resetFailed')}
            </div>,
          );
        }
      }
    }
  };

  const handleClick = (event) => {
    event.preventDefault();
    navigate('/login');
  };

  return (
    <div className="auth-screen">
      <div className="auth-container">
        <Form onSubmit={handleSubmit} className="reset-password">
          <div className="auth-logo-container">
            <img className="auth-logo" src={logo} alt="logo-dilepix" />
          </div>
          <div className="reset-title-container">
            <h3 className="reset-title">
              {t('ResetPasswordPage.titleResetPassword')}
            </h3>
          </div>
          <div className="reset-text-container">
            <p className="reset-text">
              {t('ResetPasswordPage.textResetPassword')}
            </p>
          </div>
          <Form.Group size="lg" controlId="username">
            <Form.Label className="label-username">
              {t('Labels.labelUsername')}
            </Form.Label>
            <Form.Control
              autoComplete="off"
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </Form.Group>

          {(error || errorMessage) && (
            <div>
              {error || errorMessage}
            </div>
          )}

          <Button
            className="auth-button"
            variant="contained"
            size="large"
            type="sumbit"
            fullWidth
            disabled={!validateForm()}
          >
            {t('Button.sendCode')}
          </Button>

          <div className="go-back-container">
            <span
              role="button"
              className="go-back-text"
              onClick={handleClick}
              tabIndex={0}
              onKeyDown={handleClick}
            >
              {t('Message.goBackLogin')}
            </span>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default ResetPasswordPage;
