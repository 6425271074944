import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

import ModalProduct from '../modal-product/ModalProduct.js';
import analysisReport from '../../assets/images/analysisReport.png';

export default function AnalysisReportApp({ child }) {
  const { t } = useTranslation();
  const useStyles = makeStyles()({
    analysisReport: {
      backgroundColor: '#33a237',
      borderColor: '#33a237',
      '&:hover': { backgroundColor: '#2f9638' },
    },
  });
  const { classes } = useStyles();

  return (
    <ModalProduct child={child} icon={analysisReport} title={t('AnalysisReportPage.applicationTitle')} color={classes.analysisReport} page="/analysis-report" />
  );
}
