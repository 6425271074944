/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { fetchAnalyses } from './api/apiSlice.js';

const initialState = {
  analysesResults: [],
  totalAnalyses: 0,
  status: null,
  error: null,
};

export const analysisSlice = createSlice({
  name: 'analysis',
  initialState,
  reducers: {
    resetGenericAnalyses: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAnalyses.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchAnalyses.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.analysesResults = action.payload?.data;
        state.totalAnalyses = parseInt(action.payload?.totalCount, 10);
      })
      .addCase(fetchAnalyses.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export const { resetGenericAnalyses } = analysisSlice.actions;
export default analysisSlice.reducer;
