/* eslint-disable consistent-return */
import { useMemo } from 'react';

export const DOTS = '...';

const range = (start, end) => {
  const length = end - start + 1;
  return Array.from({ length }, (_, idx) => idx + start);
};

const siblingCount = parseInt(
  process.env.REACT_APP_SIBLING_COUNT_PAGINATION,
  10,
);

export const usePagination = ({
  totalAnalyses,
  pageSize,
  currentPage,
}) => {
  const paginationRange = useMemo(() => {
    const totalPageCount = Math.ceil(totalAnalyses / pageSize);

    // Page count = 2*siblingCount + firstPage + lastPage + currentPage
    const totalPageNumbers = 2 * siblingCount + 3;

    if (totalPageNumbers >= totalPageCount) {
      return range(1, totalPageCount);
    }

    const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
    const rightSiblingIndex = Math.min(
      currentPage + siblingCount,
      totalPageCount,
    );

    const shouldShowLeftDots = leftSiblingIndex > 2;
    const shouldShowRightDots = rightSiblingIndex < totalPageCount - 1;

    if (!shouldShowLeftDots && shouldShowRightDots) {
      const leftItemCount = Math.max(2 * siblingCount + 1, rightSiblingIndex);
      const leftRange = range(1, leftItemCount);

      return [...leftRange, DOTS, totalPageCount];
    }

    if (shouldShowLeftDots && !shouldShowRightDots) {
      const rightItemCount = Math.min(
        totalPageCount - (2 * siblingCount + 1),
        leftSiblingIndex,
      );
      const rightRange = range(rightItemCount, totalPageCount);

      return [1, DOTS, ...rightRange];
    }

    if (shouldShowLeftDots && shouldShowRightDots) {
      const middleRange = range(leftSiblingIndex, rightSiblingIndex);
      return [1, DOTS, ...middleRange, DOTS, totalPageCount];
    }
  }, [
    totalAnalyses,
    pageSize,
    siblingCount,
    currentPage,
  ]);

  return { paginationRange };
};
