import { BarChart as BarChartIcon, ChevronRight as ChevronRightIcon } from '@mui/icons-material';
import { TabContext } from '@mui/lab';
import TabList from '@mui/lab/TabList/index.js';
import {
  Box,
  Divider,
  Drawer,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Tab,
} from '@mui/material';
import { createTheme, styled, ThemeProvider } from '@mui/material/styles/index.js';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import './ChartDrawer.scss';
import ChartDataPicker from '../chart-data-picker/ChartDataPicker.js';
import ChartLegendPicker from '../chart-legend-picker/ChartLegendPicker.js';
import ChartStylePicker from '../chart-style-picker/ChartStylePicker.js';
import ChartTitlePicker from '../chart-title-picker/ChartTitlePicker.js';
import chartTypes from '../generic-chart/ChartType.js';
import { setChartData, setChartType } from '../../features/chartSlice.js';
import { setProductEndpoint } from '../../services/setProductEndpoint.js';

const theme = createTheme({
  palette: {
    primary: {
      main: '#3a3a3a',
    },
  },
  components: {
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          // Controls default (unchecked) color for the thumb
          color: '#ccc',
        },
        colorPrimary: {
          '&.Mui-checked': {
            // Controls checked color for the thumb
            color: '#33a237',
          },
        },
        track: {
          '.Mui-checked.Mui-checked + &': {
            backgroundColor: '#33a237',
          },
        },
      },
    },
  },
});

const DrawerHeader = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
}));

function ChartDrawer({ handleClick, open }) {
  const { product } = useSelector((state) => state.products);
  const { data, chartType } = useSelector((state) => state.charts);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const counting = 'countings';
  const activity = 'activities';

  const productEndpoint = setProductEndpoint(product.productName);

  useEffect(() => {
    switch (productEndpoint) {
      case activity:
        dispatch(setChartType(chartTypes.StackedBar));
        break;
      case counting:
        dispatch(setChartType(chartTypes.Area));
        break;
      default:
    }
  }, []);

  const [selectedTab, setSelectedTab] = useState('1');

  const handleChangeTabs = (event, value) => {
    setSelectedTab(value);
  };

  const handleChangeChartType = (event) => {
    dispatch(setChartType(event.target.value));
    if (event.target.value === chartTypes.Area) {
      const newDatasets = data.datasets.map((value) => ({
        ...value,
        fill: true,
        backgroundColor: value.backgroundColor.replace(' 1)', ' 0.5)'),
      }));
      dispatch(setChartData({ ...data, datasets: newDatasets }));
    } else {
      const newDatasets = data.datasets.map((value) => ({
        ...value,
        fill: false,
        backgroundColor: value.backgroundColor.replace(' 0.5)', ' 1)'),
      }));
      dispatch(setChartData({ ...data, datasets: newDatasets }));
    }
  };

  const listChart = [
    {
      label: t('Chart.chartDrawer.chartType.line'),
      value: chartTypes.Line,
    },
    {
      label: t('Chart.chartDrawer.chartType.area'),
      value: chartTypes.Area,
    },
    {
      label: t('Chart.chartDrawer.chartType.verticalBar'),
      value: chartTypes.VerticalBar,
    },
    {
      label: t('Chart.chartDrawer.chartType.stackedBar'),
      value: chartTypes.StackedBar,
    },
  ];

  const listChartItem = listChart.map((item) => (
    <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
  ));

  return (
    <ThemeProvider theme={theme}>
      <Drawer
        sx={{
          width: '20em',
          flexShrink: '0',
          '& .MuiDrawer-paper': {
            width: '20em',
          },
        }}
        variant="persistent"
        anchor="right"
        open={open}
      >
        <DrawerHeader>
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            direction="row"
            spacing={7}
            sx={{ p: 2 }}
          >
            <Grid item>
              <BarChartIcon className="icon-title-chart-drawer" />
            </Grid>
            <Grid item>
              <span>
                {t('Chart.chartDrawer.title')}
              </span>
            </Grid>
            <Grid item>
              <IconButton onClick={handleClick}>
                <ChevronRightIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DrawerHeader>
        <Divider />
        <Grid container direction="column" spacing={1.5} sx={{ p: 2 }}>
          <Grid item>
            <FormControl fullWidth>
              <InputLabel id="chart_type" aria-labelledby="chart_type">{t('Chart.chartDrawer.inputChartType')}</InputLabel>
              <Select
                id="chart_select"
                value={chartType}
                label={t('Chart.chartDrawer.inputChartType')}
                onChange={handleChangeChartType}
                displayEmpty
                data-testid="chart_select"
              >
                {listChartItem}
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <TabContext value={selectedTab}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleChangeTabs} data-testid="tabs">
                  <Tab label={t('Chart.chartDrawer.dataTab')} value="1" data-testid="dataTab" />
                  <Tab label={t('Chart.chartDrawer.edit')} value="2" data-testid="editTab" />
                </TabList>
              </Box>
              <ChartDataPicker hidden={selectedTab === '1'} />
              <Grid container direction="column" spacing={2} sx={{ pt: 2 }}>
                <Grid item>
                  <ChartStylePicker hidden={selectedTab === '2'} open={open} />
                </Grid>
                <Grid item>
                  <ChartTitlePicker hidden={selectedTab === '2'} />
                </Grid>
                <Grid item>
                  <ChartLegendPicker hidden={selectedTab === '2'} />
                </Grid>
              </Grid>
            </TabContext>
          </Grid>
        </Grid>
      </Drawer>
    </ThemeProvider>
  );
}

export default ChartDrawer;
