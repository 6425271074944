import clsx from 'clsx';
import { List, SwipeableDrawer } from '@mui/material';
import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { CgMenuGridR } from 'react-icons/cg/index.js';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import './ShortcutMenu.scss';
import DashboardApp from '../app-dashboard/DashboardApp.js';
import AnalysisReportApp from '../app-analysis-report/AnalysisReportApp.js';
import TransmissionApp from '../app-transmission/TransmissionApp.js';
import Button from '../button/Button.js';
import ModalHook from '../modal/ModalHook.js';
import ShortcutItem from '../shortcut-item/ShortcutItem.js';
import analysisReport from '../../assets/images/analysisReport.png';
import transmission from '../../assets/images/transmission.png';
import { selectProduct } from '../../features/productSlice.js';
import useModal from '../../hooks/useModal.js';

function ShortcutMenu(props, { ...rest }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { productsList } = useSelector((state) => state.products);
  const { isShowing, showModal, closeModal } = useModal();

  const useStyles = makeStyles()({
    list: {
      width: 250,
    },
    fullList: {
      width: 'auto',
    },
    defaultAppColor: {
      backgroundColor: '#33a237',
      borderColor: '#33a237',
    },
  });

  const dispatch = useDispatch();
  const { classes } = useStyles();
  const [state, setState] = useState({ left: false });
  const [toScreen, setToScreen] = useState();

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event
      && event.type === 'keydown'
      && (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  // Handle modal on small screen
  const handleOpen = () => {
    showModal();
  };

  const productOptions = productsList.map((product) => (
    <div className="list-item" key={product.productId}>
      <Button
        className="select-button"
        fullWidth
        action={() => {
          dispatch(selectProduct(product));
          navigate(toScreen);
          closeModal();
        }}
      >
        {product.name}
      </Button>
    </div>
  ));

  const modalBody = <div className="app-modal">{productOptions}</div>;

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >

      <List>
        {productsList.length <= 0 && (
          <div className="no-app-shortcut">
            {t('Message.noApp')}
          </div>
        )}
        {productsList.length > 0 && (
          <ShortcutItem
            title={t('UploadPage.applicationTitle')}
            color={classes.defaultAppColor}
            size={30}
            icon={transmission}
            action={() => {
              handleOpen();
              setToScreen('/upload');
            }}
          />
        )}
        {productsList.length > 0 && (
          <DashboardApp child="shortcutApp" {...props} />
        )}
        {productsList.length > 0 && (
          <ShortcutItem
            title={t('AnalysisReportPage.applicationTitle')}
            color={classes.defaultAppColor}
            size={30}
            icon={analysisReport}
            action={() => {
              handleOpen();
              setToScreen('/analysis-report');
            }}
          />
        )}
      </List>
    </div>
  );

  return (
    <div>
      {/* Mobile view */}
      <div>
        {['left'].map((anchor) => (
          <React.Fragment key={anchor}>
            <Button action={toggleDrawer(anchor, true)}>
              <CgMenuGridR className="menu-icon" size={35} />
            </Button>
            <SwipeableDrawer
              {...rest}
              anchor={anchor}
              open={state[anchor]}
              onClose={toggleDrawer(anchor, false)}
              onOpen={toggleDrawer(anchor, true)}
            >
              {list(anchor)}
            </SwipeableDrawer>
          </React.Fragment>
        ))}

        <ModalHook
          title={t('Modal.modalTitle')}
          isShowing={isShowing}
          hide={showModal}
          onClose={closeModal}
        >
          {productsList.length > 0 ? (
            <div>{modalBody}</div>
          ) : (
            <div className="no-products-message">
              {t('Message.noProducts')}
            </div>
          )}
        </ModalHook>
      </div>

      {/* Desktop view */}
      <div>
        <Dropdown className="shortcut-dropdown">
          <Dropdown.Toggle>
            <CgMenuGridR size={35} className="shortcut-icon" />
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {productsList.length <= 0 && (
              <div className="no-app-shortcut">
                {t('Message.noApp')}
              </div>
            )}
            {productsList.length > 0 && <TransmissionApp child="shortcutApp" />}
            {productsList.length > 0 && (
              <DashboardApp child="shortcutApp" {...props} />
            )}
            {productsList.length > 0 && (
              <AnalysisReportApp child="shortcutApp" />
            )}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
}

export default ShortcutMenu;
