/* eslint-disable max-len */
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import './GenericFiltersContainer.scss';
import Button from '../components/button/Button.js';
import DatePicker from '../components/date-picker/DatePicker.js';
import { resetGenericAnalyses } from '../features/analysisSlice.js';
import { fetchAnalyses } from '../features/api/apiSlice.js';
import { resetPagination } from '../features/paginationSlice.js';
import formatCsvData from '../utils/formatCsvData.js';

function GenericFiltersContainer({ handleNoDataMsg, ...props }) {
  const { filteredItems } = props;
  const { startDate, endDate } = filteredItems;
  const { limit, offset } = useSelector((state) => state.pagination);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const today = DateTime.now();

  const location = useLocation();

  const [sDate, setSDate] = useState(null);
  const [eDate, setEDate] = useState(null);
  const [isStartDateValid, setIsStartDateValid] = useState(true);
  const [isEndDateValid, setIsEndDateValid] = useState(true);

  const handleDateChange = (dateSelected, newDate) => {
    if (dateSelected === 'startDate') {
      setSDate(newDate);
      setIsStartDateValid(newDate ? !newDate.invalid : true);
    }
    if (dateSelected === 'endDate') {
      setEDate(newDate);
      setIsEndDateValid(newDate ? !newDate.invalid : true);
    }
  };

  const handleClick = async () => {
    props.setIsLoading(true);
    await props.setShowChart(false);
    dispatch(resetGenericAnalyses());
    dispatch(resetPagination());

    const response = await dispatch(fetchAnalyses({
      startDate, endDate, limit, offset,
    })).unwrap();
    await dispatch(formatCsvData()).unwrap();
    if (response?.data.length < 1) handleNoDataMsg(true);
    props.setIsLoading(false);
  };

  useEffect(() => {
    dispatch(resetGenericAnalyses());
  }, [location]);

  // Handle button disable prop
  const isButtonDisabled = !isStartDateValid || !isEndDateValid || (sDate === null && eDate === null);
  // startDate cannot be higher than endDate
  const maxDateForStart = eDate === null ? today : eDate;

  return (
    <div className="date-picker">
      <DatePicker
        label={t('AnalysisReportPage.startDate')}
        dateSelected="startDate"
        maxDate={maxDateForStart}
        handleDateChange={handleDateChange}
      />
      <DatePicker
        label={t('AnalysisReportPage.endDate')}
        dateSelected="endDate"
        minDate={sDate}
        maxDate={today}
        handleDateChange={handleDateChange}
      />

      <Button
        variant="contained"
        className={isButtonDisabled ? 'disabled-btn' : 'validate-btn'}
        disabled={isButtonDisabled}
        action={handleClick}
      >
        {t('Button.validate')}
      </Button>
    </div>
  );
}

const mapStateToProps = (state) => ({
  filteredItems: state.filters.filteredItems,
});

export default connect(mapStateToProps)(GenericFiltersContainer);
