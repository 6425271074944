const chart = {
  Line: 'Line',
  Area: 'Area',
  VerticalBar: 'VerticalBar',
  StackedBar: 'StackedBar',
  option: {
    Line: {
      scales: {
        x: {
          stacked: false,
          ticks: {
            maxRotation: 90,
            minRotation: 90,
          },
        },
        y: {
          stacked: false,
        },
      },
    },
    Area: {
      scales: {
        x: {
          stacked: false,
          ticks: {
            maxRotation: 90,
            minRotation: 90,
          },
        },
        y: {
          stacked: false,
        },
      },
    },
    VerticalBar: {
      scales: {
        x: {
          stacked: false,
          ticks: {
            maxRotation: 90,
            minRotation: 90,
          },
        },
        y: {
          stacked: false,
        },
      },
    },
    StackedBar: {
      scales: {
        x: {
          stacked: true,
          ticks: {
            maxRotation: 90,
            minRotation: 90,
          },
        },
        y: {
          stacked: true,
        },
      },
    },
  },
};

export default chart;
