import i18n from '../components/translate/i18n.js';

export const analysesKeysMap = {
  bsf_neonate_count: 'Comptage',
  bsf_neonate_count_rounded: 'Comptage arrondi',

  hours_before_sow_ovulation: 'Heures avant ovulation',
  is_ovulating: 'Ovulation',

  imageArea: 'Localisation',
  periodStartDate: 'Date de début',
  periodEndDate: 'Date de fin',
  tagId: 'Tag',
  proneLyingMeanCount: 'Couché sur le ventre',
  lyingOnTheSideMeanCount: 'Couché sur le côté',
  sittingMeanCount: 'Assis',
  eatingMeanCount: 'En train de manger',
  drinkingMeanCount: 'En train de boire',
  count: 'Nombre',
  ratio: 'Ratio',

  lyingMeanCount: 'Couché',
  standingInContactWithBeddingMeanCount: 'Debout au contact de son couchage',
  atTroughMeanCount: "A l'auge",
  atDrinkingTroughMeanCount: "A l'abreuvoir",

  standingInMotionMeanCount: 'Debout en mouvement',
  standingStaticMeanCount: 'Debout statique',

  deficiency: 'Carence',
  EXTREME_DEFICIENCY: 'Carence extrême',
  HIGH_DEFICIENCY: 'Carence élevée',
  MODERATE_DEFICIENCY: 'Carence modérée',
  LOW_DEFICIENCY: 'Carence faible',
  NO_DEFICIENCY: 'Non carencé',

  INFERENCE_TIMEOUT: "Durée maximale d'analyse dépassée.",
  UNKNOWN_ERROR: 'UNKNOWN_ERROR',
  NO_TIMESTAMP_EXTRACTED: 'Horodatage non détecté ou incompatible.',
};

export const analysesKeysMapEN = {
  bsf_neonate_count: 'Counting',
  bsf_neonate_count_rounded: 'Rounded count',

  hours_before_sow_ovulation: 'Hours before sow ovulation',
  is_ovulating: 'Ovulation',

  imageArea: 'Image area',
  periodStartDate: 'Start date',
  periodEndDate: 'End date',
  tagId: 'Tag',
  proneLyingMeanCount: 'Prone lying',
  lyingOnTheSideMeanCount: 'Lying on the side',
  sittingMeanCount: 'Sitting',
  eatingMeanCount: 'Eating',
  drinkingMeanCount: 'Drinking',
  count: 'Count',
  ratio: 'Ratio',

  lyingMeanCount: 'Lying',
  standingInContactWithBeddingMeanCount: 'Standing in contact with bedding',
  atTroughMeanCount: 'At trough',
  atDrinkingTroughMeanCount: 'At drinking trough',

  standingInMotionMeanCount: 'Motion standing',
  standingStaticMeanCount: 'Static standing',

  deficiency: 'Deficiency',
  EXTREME_DEFICIENCY: 'Extreme deficiency',
  HIGH_DEFICIENCY: 'High deficiency',
  MODERATE_DEFICIENCY: 'Moderate deficiency',
  LOW_DEFICIENCY: 'Low deficiency',
  NO_DEFICIENCY: 'No deficiency',

  INFERENCE_TIMEOUT: 'Analysis timeout.',
  UNKNOWN_ERROR: 'UNKNOWN_ERROR',
  NO_TIMESTAMP_EXTRACTED: 'Not detected or incompatible timestamp.',
};

export const translateObjValue = (item, language) => {
  const valTranslation = Object.fromEntries(
    Object.entries(item).map(([key, val]) => (language.id === 'fr'
      ? [key, analysesKeysMap[val]]
      : [key, analysesKeysMapEN[val]])),
  );
  return valTranslation;
};

export const translateObjKey = (obj) => {
  const keyTranslation = Object.keys(obj).reduce(
    (acc, key) => ({
      ...acc,
      ...(i18n.language === 'fr'
        ? { [analysesKeysMap[key] || key]: obj[key] }
        : { [analysesKeysMapEN[key] || key]: obj[key] }),
    }),
    {},
  );
  return keyTranslation;
};

export const translateValue = (value) => {
  const translationsMap = i18n.language === 'fr' ? analysesKeysMap : analysesKeysMapEN;
  if (value in translationsMap) {
    return translationsMap[value];
  }
  return value;
};

export default {
  analysesKeysMap, analysesKeysMapEN, translateObjValue, translateObjKey, translateValue,
};
