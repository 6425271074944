import React from 'react';
import { useTranslation } from 'react-i18next';
import { BsDot } from 'react-icons/bs/index.js';
import { Link, useLocation } from 'react-router-dom';

import './Footer.scss';
import color from '../../assets/colors.js';

function Footer() {
  const { t } = useTranslation();
  const location = useLocation();
  const { pathname } = location;
  const legal = t('Footer.legal');
  const privacyPolicy = t('Footer.privacyPolicy');

  const authPathname = pathname === '/login'
    || pathname === '/reset'
    || pathname === '/reset/confirmation'
    || pathname === '/register/confirmation';

  const footerPath = pathname === '/legal-information' || pathname === '/privacy-policy';

  return !footerPath ? (
    <footer className={authPathname ? 'auth-footer' : undefined}>
      <Link to="/legal-information">
        <div className="legals">{legal}</div>
      </Link>
      <span>
        <BsDot color={color.lightgrey2} size={20} />
      </span>
      <Link to="/privacy-policy">
        <div className="privacy-policy">{privacyPolicy}</div>
      </Link>
    </footer>
  )
    : null;
}

export default Footer;
