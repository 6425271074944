/* eslint-disable max-len */
import { useState, useEffect } from 'react';

import store from '../store/configureStore.js';

const useReportValidation = () => {
  const [isDisabled, setIsDisabled] = useState(true);
  const [sDate, setSDate] = useState(null);
  const [eDate, setEDate] = useState(null);
  const [isStartDateValid, setIsStartDateValid] = useState(true);
  const [isEndDateValid, setIsEndDateValid] = useState(true);
  const { productName } = store.getState().products.product;
  const { filteredItems } = store.getState().filters;
  const {
    tagIds, timeStep, startDate, endDate,
  } = store.getState().filters.filteredItems;

  const handleDateChange = (dateSelected, newDate) => {
    if (dateSelected === 'startDate') {
      setSDate(newDate);
      setIsStartDateValid(newDate ? !newDate.invalid : true);
    }
    if (dateSelected === 'endDate') {
      setEDate(newDate);
      setIsEndDateValid(newDate ? !newDate.invalid : true);
    }
  };

  const activityFilters = { filters: ['startDate', 'endDate', 'tagIds', 'timeStep'] };
  const porcineFilters = activityFilters?.filters?.every((item) => !(
    filteredItems[item] === undefined || filteredItems[item]?.length < 1)
    && !(filteredItems[item] === null || filteredItems[item] === undefined));

  useEffect(() => {
    switch (productName) {
      case 'porcineactivity':
        if (porcineFilters === false) {
          setIsDisabled(true);
        } else {
          setIsDisabled(false);
        }
        break;

      default:
        if (timeStep === undefined) {
          setIsDisabled(true);
        } else if (timeStep !== undefined && (startDate === undefined || endDate === undefined) && (tagIds === undefined || tagIds.length < 1)) {
          setIsDisabled(true);
        } else if (timeStep !== undefined && (startDate === null || endDate === null) && (tagIds === undefined || tagIds.length < 1)) {
          setIsDisabled(true);
        } else if (timeStep !== undefined && (Number.isNaN(startDate) || Number.isNaN(endDate)) && (tagIds === undefined || tagIds.length < 1)) {
          setIsDisabled(true);
        } else {
          setIsDisabled(false);
        }
        break;
    }
  }, [tagIds, startDate, endDate, timeStep]);

  return {
    isDisabled, handleDateChange, sDate, eDate, isStartDateValid, isEndDateValid,
  };
};

export default useReportValidation;
