import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import './LegalPage.scss';
import LanguagePicker from '../../components/language-picker/LanguagePicker.js';

function LegalPage() {
  const { t } = useTranslation();

  const mailToHref = 'mailto:contact@dilepix.com?subject=SendMail&body=Description';

  return (
    <div className="legal-ctr">
      <div className="legal-top-ctr">
        <h1 className="legal-title">{t('Legal.legalInformation')}</h1>
        <LanguagePicker
          className="language-picker-container legal"
          iconSize={25}
          iconHeight={25}
          flagSize={30}
        />
      </div>
      <div className="legal-border-image" />
      <p className="legal-content">{t('Legal.legalInformationContent1')}</p>
      <p className="legal-content">
        {t('Legal.legalInformationContent2')}
        <a href={mailToHref} target="_blank" rel="noreferrer">{t('Legal.contactDilepix')}</a>
      </p>
      <p className="legal-content">
        {t('Legal.legalInformationContent3')}
        <a href="https://help.ovhcloud.com/fr/" target="_blank" rel="noreferrer">{t('Legal.ovhcloudLink')}</a>
      </p>

      <h1 className="legal-title copyrights">{t('Legal.copyrights')}</h1>
      <div className="legal-border-image" />
      <p className="legal-content">{t('Legal.copyrightsContent')}</p>
      <div className="legal-go-back">
        <Link to="/home" className="legal-go-back-txt">
          {t('Message.back')}
        </Link>
      </div>
    </div>
  );
}

export default LegalPage;
