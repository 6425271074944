export const useUniqueDate = (analysesData) => {
  const data = [];
  analysesData.map((item) => {
    data.push(
      { ...item, Date: item.Date },
    );
    return data;
  });

  const genericProductData = data.reverse();
  return genericProductData;
};

export default useUniqueDate;
