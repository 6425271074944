import React from 'react';

import closeRed from '../../assets/images/close_red.png';

function DeleteCell(row, handleClick, setModalId) {
  return (
    <div
      className="table-item"
      role="button"
      onClick={() => {
        handleClick(row);
        setModalId('delete');
      }}
      onKeyDown={() => {
        handleClick(row);
        setModalId('delete');
      }}
      tabIndex="0"
      data-testid="delete_icon"
    >
      <img
        alt="close"
        src={closeRed}
        className="delete_icon"
        draggable="false"
      />
    </div>
  );
}

export default DeleteCell;
