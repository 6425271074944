import React from 'react';

function CustomCell({ value }) {
  return (
    // Format cell depending on data type
    <div className="tab-cell-ctr">
      {(typeof value === 'boolean' || typeof value === 'string') && (
        <div className="table-item prod-item">{value.toString()}</div>
      )}
      {typeof value === 'number' && (
        <div className="table-item prod-item">{value}</div>
      )}
    </div>
  );
}

export default CustomCell;
