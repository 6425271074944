import React from 'react';
import { withStyles } from '@mui/styles';
import { green } from '@mui/material/colors/index.js';
import { Checkbox } from '@mui/material';

const CustomCheckbox = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default function CheckboxLabels({
  id,
  value,
  checked,
  handleChange,
  className,
  ...props
}) {
  return (
    <CustomCheckbox
      checked={checked}
      onChange={handleChange}
      id={id}
      value={value}
      className={className}
      {...props}
    />
  );
}
