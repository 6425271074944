/**
 * Only accessible after user has entered his username in reset input
 */

import React from 'react';
import { Navigate, useLocation, Outlet } from 'react-router-dom';

function ResetPassRoutes({ redirectPath = '/login', children }) {
  const location = useLocation();
  if (location.state === null || undefined) {
    return <Navigate to={redirectPath} replace />;
  }
  return children || <Outlet />;
}

export default ResetPassRoutes;
