import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import './ModalDelete.scss';
import Button from '../button/Button.js';
import ModalHook from '../modal/ModalHook.js';
import { deleteAnalysis, deleteTagInput, fetchAllAnalyses } from '../../features/api/apiSlice.js';
import { setPagination } from '../../features/paginationSlice.js';
import useModal from '../../hooks/useModal.js';

function ModalDelete({ isShowing, closeModal, ...props }) {
  const { targetBtn } = props;
  const { t } = useTranslation();
  const { showModal } = useModal();
  const dispatch = useDispatch();
  const limit = parseInt(process.env.REACT_APP_OFFSET_PAGINATION, 10);

  const onSubmit = async () => {
    const deleteAction = targetBtn === 'delete_analysis' ? deleteAnalysis() : deleteTagInput();
    await Promise.all([
      await dispatch(deleteAction).unwrap(),
      dispatch(fetchAllAnalyses(limit)).unwrap(),
    ]);
    dispatch(setPagination(1));
    props.setShowDelModal(false);
  };

  const onCancel = () => {
    props.setShowDelModal(false);
  };

  return (
    <ModalHook
      title={t('ModalDelete.deleteConfirmation')}
      isShowing={isShowing}
      hide={showModal}
      onClose={() => closeModal(props.setShowDelModal(false))}
    >
      <div className="delete-warning">{targetBtn === 'delete_analysis' ? t('ModalDelete.deleteAnalysisWarning') : t('ModalDelete.deleteWarning')}</div>

      <div className="delete-btn-ctr">
        <Button variant="contained" className="cancel" action={onCancel}>
          {t('Button.cancel')}
        </Button>
        <Button variant="contained" className="delete-tag" action={onSubmit}>
          {t('Button.delete')}
        </Button>
      </div>
    </ModalHook>
  );
}

export default ModalDelete;
