import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { IoMdAddCircleOutline } from 'react-icons/io/index.js';
import { useDispatch, useSelector } from 'react-redux';

import './ModalTag.scss';
import Button from '../button/Button.js';
import ModalHook from '../modal/ModalHook.js';
import { fetchTags, useAddTagMutation } from '../../features/api/apiSlice.js';
import { setTag } from '../../features/tagSlice.js';
import useModal from '../../hooks/useModal.js';

function TagModal() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { tagsList, tag } = useSelector((state) => state.tags);
  const { productName } = useSelector((state) => state.products?.product);
  const { groupId } = useSelector((state) => typeof state.groups?.group[0] !== 'undefined' && state.groups?.group[0]);
  const { isShowing, showModal, closeModal } = useModal();
  const [selectTag, setSelectTag] = useState('');
  const [filteredTags, setFilteredTags] = useState([]);
  const [charLimit, setCharLimit] = useState(false);
  const [addTag, { data, isSuccess, isError }] = useAddTagMutation();

  function handleSubmit(tagName) {
    addTag({ productName, tagName, groupId });
  }

  useEffect(() => {
    if (isSuccess) dispatch(setTag(data));
  }, [isSuccess, data]);

  // Select tag
  const handleChange = (e) => {
    const { value } = e.target;
    setCharLimit(value.length !== 0 && (value.length < 3 || value.length > 100));
    setSelectTag(e.target.value);
  };

  async function handleSelect(item) {
    dispatch(setTag(item));
  }

  // Handle Modal
  const handleOpen = async () => {
    await dispatch(fetchTags()).unwrap();
    showModal();
  };

  const handleClose = () => {
    closeModal();
    setSelectTag('');
  };

  // Search bar
  useEffect(() => {
    const searchResult = () => {
      setFilteredTags(
        tagsList.filter((item) => item.name.toLowerCase().includes(selectTag.toLowerCase())),
      );
    };
    searchResult();
  }, [selectTag, tagsList]);

  const tagIsExist = filteredTags.some((item) => item.name === selectTag);

  const renderTagList = filteredTags.map((item) => (
    <div
      className="modal-element"
      role="button"
      tabIndex={0}
      key={item.tagId}
      onClick={() => {
        handleSelect(item);
        handleClose();
      }}
      onKeyDown={() => {
        handleSelect(item);
        handleClose();
      }}
    >
      {item.name}
    </div>
  ));

  const isCreateDisabled = selectTag === '' || tagIsExist === true || charLimit === true;

  const modalBody = (
    <>
      <div className="search-bar-container">
        <input
          className="search-bar"
          type="text"
          placeholder={t('Message.searchBarPlaceholder')}
          onChange={handleChange}
          value={selectTag}
        />
        {charLimit && filteredTags.length < 1 && <div className="char_limit">{t('Alerts.tagCharLimit')}</div>}
      </div>

      <div>
        {tagsList.length <= 0 && (
          <div className="no-tag-message">
            {t('Message.noTagCreate')}
          </div>
        )}
        <div className="tag-list">{renderTagList}</div>
        {!isCreateDisabled && (
          <div className="filters-btn-container">
            <Button
              className="add-btn"
              variant="contained"
              action={() => {
                handleSubmit(selectTag);
                handleClose();
              }}
            >
              <IoMdAddCircleOutline
                size={25}
                title="add-tag"
                className="add-icon"
                data-testid="add_icon"
              />
              <span className="add-tag-txt" data-testid="create_label">
                {t('Message.create')}
                {selectTag}
              </span>
            </Button>
          </div>
        )}
      </div>
    </>
  );

  return (
    <>
      {tag.length === 0 && (
        <Button
          className="tag-btn"
          variant="contained"
          action={(e) => {
            handleOpen();
            e.currentTarget.blur();
          }}
        >
          {t('Message.addTag')}
        </Button>
      )}
      {isError && <span className="tag-error">{t('Alerts.createTagError')}</span>}
      <ModalHook
        title={t('Message.tagModalTitle')}
        isShowing={isShowing}
        hide={showModal}
        onClose={() => closeModal(setSelectTag(''))}
      >
        {modalBody}
      </ModalHook>
    </>
  );
}

export default TagModal;
