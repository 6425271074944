import ModalUi from '@mui/material/Modal/index.js';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

import './ModalHook.scss';

function ModalHook({
  title, isShowing, hide, isDisabled, children, ...props
}) {
  const useStyles = makeStyles()((theme) => ({
    modal: {
      position: 'absolute',
      width: '60%',
      minHeight: '10%',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #d4d4d4',
      borderRadius: '10px',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(1, 1, 4),
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      outline: 'none !important',
    },
    '@media(max-width: variables.$max_width_medium_phone)': {
      modal: {
        width: '90%',
      },
    },
    '@media(max-width: variables.$max_width_small_phone)': {
      modal: {
        width: '80%',
      },
    },
    backDrop: {
      backgroundColor: 'rgba(169,169,169, 0.8)',
    },
  }));

  const { classes } = useStyles();

  const modalBody = (
    <div className={classes.modal}>
      <h3 className="modal-title">{title}</h3>
      <div className="modal-container">{children}</div>
    </div>
  );

  return isShowing ? (
    <ModalUi open={isShowing} onClose={!isShowing} {...props} className={classes.backDrop}>
      {modalBody}
    </ModalUi>
  ) : null;
}

export default ModalHook;
