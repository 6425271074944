import React from 'react';

import Checkbox from '../checkbox/Checkbox.js';

function TableCheckboxCell(row, getTagId) {
  return (
    <div>
      <Checkbox
        {...row.getToggleRowSelectedProps()}
        className={row.isSelected === false ? 'cells-checkbox' : 'checked-rows'}
        onClick={(e) => getTagId(e, row)}
        data-testid="checkbox_cell"
      />
    </div>
  );
}

export default TableCheckboxCell;
