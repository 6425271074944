import React from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineInfoCircle } from 'react-icons/ai/index.js';

import Tooltip from '../tooltip/Tooltip.js';
import TooltipModal from '../tooltip/TooltipModal.js';
import useMobileScreen from '../../hooks/useMobileScreen.js';

function DashboardStatusCell(value, row) {
  const { t } = useTranslation();
  const { isMobile } = useMobileScreen();

  return (
    <div className="status-item">
      {value === `${t('DashboardPage.dashboardTable.succeeded')}` && (
        <div className="success-analysis">
          <div className="value succeeded">{value}</div>
        </div>
      )}
      {value === `${t('DashboardPage.dashboardTable.running')}` && (
        <div className="running-analysis">
          <div className="value running">{value}</div>
        </div>
      )}
      {value === `${t('DashboardPage.dashboardTable.failed')}` && (
        <div className="failed-analysis">
          <div className="value failed">{value}</div>
          {isMobile ? (
            row?.original?.statusDetail !== 'UNKNOWN_ERROR' && (
              <TooltipModal content={row?.original?.statusDetail} />
            )
          ) : (
            row?.original?.statusDetail !== 'UNKNOWN_ERROR' && (
              <Tooltip content={row?.original?.statusDetail}>
                <div className="icon_info"><AiOutlineInfoCircle size={20} className="info" /></div>
              </Tooltip>
            )
          )}
        </div>
      )}
    </div>
  );
}

export default DashboardStatusCell;
