import React from 'react';
import './ShortcutItem.scss';
import { Dropdown } from 'react-bootstrap';

function ShortcutItem({
  title, icon, action, color, size, ...props
}) {
  const shortcutItemBody = (
    <Dropdown.Item className="side-item" onClick={action}>
      <div className={`side-icon-container ${color}`}>
        <img
          alt="side-icon"
          src={icon}
          width={size}
          className="side-icon"
        />
      </div>
      <span className="side-label">{title}</span>
    </Dropdown.Item>
  );

  return (
    <div {...props}>{shortcutItemBody}</div>
  );
}

export default ShortcutItem;
