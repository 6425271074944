import {
  DesktopDatePicker,
  MobileDatePicker,
  LocalizationProvider,
  PickersDay,
  pickersDayClasses,
} from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon/index.js';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './DatePicker.scss';
import i18n from '../translate/i18n.js';
import { currentLanguage } from '../translate/languages.js';
import colors from '../../assets/colors.js';
import { setFilter } from '../../features/filterSlice.js';
import useMobileScreen from '../../hooks/useMobileScreen.js';

function DatePicker({
  label,
  dateSelected,
  minDate,
  maxDate,
  className,
  handleDateChange, // New prop for date change callback
}) {
  const [value, setValue] = useState(null);
  const { filteredItems } = useSelector((state) => state.filters);
  const { isMobile } = useMobileScreen();
  const dispatch = useDispatch();

  const handleChange = (newDate) => {
    const date = new Date(newDate?.ts);
    const updatedFilter = { ...filteredItems };

    if (dateSelected === 'startDate') {
      date.setHours(0, 0, 0, 0);
      updatedFilter.startDate = newDate?.ts !== null ? date.getTime() : null;
    }

    if (dateSelected === 'endDate') {
      date.setHours(23, 59, 59, 999);
      updatedFilter.endDate = newDate?.ts !== null ? date.getTime() : null;
    }

    setValue(newDate);
    handleDateChange(dateSelected, newDate);
    dispatch(setFilter(updatedFilter));
  };

  const renderWeekPickerDay = (date, selectedDates, pickersDayProps) => (
    <PickersDay
      {...pickersDayProps}
      sx={{
        [`&&.${pickersDayClasses.selected}`]: {
          backgroundColor: `${colors.green}`,
        },
      }}
    />
  );

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={i18n.language}>
      {isMobile !== true && (
        <DesktopDatePicker
          label={label}
          dateSelected={dateSelected}
          inputFormat={currentLanguage().inputFormat}
          minDate={minDate}
          maxDate={maxDate}
          value={value}
          onChange={(newDate) => handleChange(newDate)}
          slotProps={{ textField: { color: 'success', className } }}
          renderDay={renderWeekPickerDay}
        />
      )}

      {isMobile === true && (
        <MobileDatePicker
          label={label}
          dateSelected={dateSelected}
          inputFormat={currentLanguage().inputFormat}
          minDate={minDate}
          maxDate={maxDate}
          value={value}
          onChange={(newDate) => handleChange(newDate)}
          slotProps={{ textField: { color: 'success' } }}
          renderDay={renderWeekPickerDay}
        />
      )}
    </LocalizationProvider>
  );
}

export default DatePicker;
