/* eslint-disable no-underscore-dangle */
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

import i18n from '../components/translate/i18n.js';
import { apiSlice, fetchRefreshToken } from '../features/api/apiSlice.js';
import { fetchLogout } from '../features/userSlice.js';
import axiosInstance from './instanceApi.js';
import store from '../store/configureStore.js';
import showAlert from '../utils/swalAlert.js';

function SetupInterceptors() {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const dispatch = useDispatch();

  const handleExpiredToken = () => {
    dispatch(fetchLogout());
    localStorage.clear();
    dispatch(apiSlice.util.resetApiState());
    window.location.replace('/login');
  };

  axiosInstance.interceptors.request.use(
    (config) => {
      const token = store.getState().user.accessToken;
      const { headers } = config;
      if (token) {
        headers.Authorization = `Bearer ${token}`;
        headers['Content-Type'] = 'application/json';
      }
      return config;
    },
    (error) => {
      Promise.reject(error);
    },
  );

  let refreshingToken = null;

  // Handle errors
  axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const { message, httpStatus } = error?.response?.data || {};
      const originalConfig = error.config;

      function isNetworkError() {
        return ['ECONNABORTED', 'NS_BINDING_ABORTED', 'CORS Failed'].includes(error.code);
      }

      switch (httpStatus) {
        case 400:
          if (
            pathname === '/reset'
            || pathname === '/reset/confirmation'
            || pathname === '/register/confirmation'
          ) {
            return Promise.reject(error);
          }
          if (message === 'Error validating request data.') {
            handleExpiredToken();
          }
          if (message === 'Too much data requested, use filters to slim your request') {
            showAlert(
              '',
              `${i18n.t('Alerts.queryExceededMemoryLimit')}`,
              `${i18n.t('Alerts.retry')}`,
              () => {},
            );
          }
          break;

        // Handle 401 errors and refresh token
        case 401:
          if (message === 'Missing bearer authorization header') {
            handleExpiredToken();
          }
          if (originalConfig.url !== '/login' && httpStatus) {
            if (!originalConfig._retry && originalConfig.url !== '/refresh') {
              originalConfig._retry = true;
              try {
                refreshingToken = refreshingToken || dispatch(fetchRefreshToken());
                await refreshingToken;
                refreshingToken = null;
                return axiosInstance(originalConfig);
              } catch (err) {
                return Promise.reject(err);
              }
            }
            if (originalConfig.url === '/refresh') {
              handleExpiredToken();
            }
          }
          break;

        case 403:
          dispatch(fetchLogout());
          dispatch(apiSlice.util.resetApiState());
          navigate('/login');
          break;

        case 404:
          showAlert(
            '404',
            `${i18n.t('Alerts.noRouteFound')}`,
            `${i18n.t('Alerts.retry')}`,
            () => {
              navigate('/home');
            },
          );
          break;

        // Handle server errors
        case 500:
          showAlert(
            '',
            `${i18n.t('Alerts.serverErrors')}`,
            `${i18n.t('Alerts.reconnect')}`,
            () => {
              navigate('/login');
            },
          );
          break;

        case undefined:
          if (isNetworkError(error)) {
            showAlert(
              '',
              `${i18n.t('Alerts.noNetwork')}`,
              `${i18n.t('Alerts.back')}`,
              () => {
                navigate('/home');
              },
            );
          } else {
            showAlert(
              '',
              `${i18n.t('Alerts.noNetwork')}`,
              `${i18n.t('Alerts.reconnect')}`,
              () => {
                navigate('/login');
              },
            );
          }
          break;

        default:
          showAlert(
            '',
            `${i18n.t('Alerts.defaultError')}`,
            `${i18n.t('Alerts.back')}`,
            () => {
              navigate('/home');
            },
          );
          break;
      }
      return Promise.reject(error);
    },
  );
  return null;
}

export default SetupInterceptors;
