/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  filteredItems: [],
  selectedAnalyses: [],
  error: null,
  status: null,
};

export const filterSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setFilter: (state, { payload }) => {
      state.filteredItems = payload;
    },
    resetFilters: () => initialState,
    selectAnalyses: (state, { payload }) => {
      state.selectedAnalyses = payload;
    },
  },
});

export const { setFilter, resetFilters, selectAnalyses } = filterSlice.actions;
export default filterSlice.reducer;
