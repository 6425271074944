import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import './ProductList.scss';
import Button from '../button/Button.js';
import ModalHook from '../modal/ModalHook.js';
import { fetchTags, useGetGroupQuery } from '../../features/api/apiSlice.js';
import { setGroup } from '../../features/groupSlice.js';
import { selectProduct } from '../../features/productSlice.js';
import useModal from '../../hooks/useModal.js';

function ProductList() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { productsList, product } = useSelector((state) => state.products);
  const [skip, setSkip] = useState(true);
  const { data, isSuccess, isFetching } = useGetGroupQuery(
    product?.productName,
    { skip },
  );
  const { isShowing, showModal, closeModal } = useModal();

  useEffect(() => {
    const fetchData = async () => {
      if (isSuccess && !isFetching) {
        setSkip(true);
        dispatch(setGroup(data));
        await dispatch(fetchTags()).unwrap();
      }
    };
    fetchData();
  }, [isSuccess, data]);

  async function handleClick(p) {
    dispatch(selectProduct(p));
    closeModal();
    setSkip(false);
  }

  const productList = productsList.map((p) => (
    <div className="list-item" key={p.productId}>
      <Button
        variant="contained"
        type="button"
        className="product_element"
        fullWidth
        action={() => handleClick(p)}
        key={p.productId}
      >
        {p.name}
      </Button>
    </div>
  ));

  return (
    <>
      <div className="select_product_ctr">
        <span className="selected_product">
          {t('ProductList.selectedProduct')}
        </span>
        <span>{product.name}</span>
        {typeof product?.productId === 'undefined' ? (
          <Button
            variant="contained"
            className="select_product_btn"
            action={showModal}
          >
            {t('Button.select')}
          </Button>
        ) : (
          <Button
            variant="contained"
            className="modify_product_btn"
            action={showModal}
          >
            {t('Button.modify')}
          </Button>
        )}
      </div>

      <ModalHook
        title={t('Modal.modalTitle')}
        isShowing={isShowing}
        hide={showModal}
        onClose={closeModal}
      >
        <div className="app-modal">{productList}</div>
      </ModalHook>
    </>
  );
}

export default ProductList;
