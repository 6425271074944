import { useEffect, useState } from 'react';

export default function useDropdown(ref) {
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsOpen(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

  const toggleDropdown = () => setIsOpen(!isOpen);

  return { toggleDropdown, isOpen };
}
