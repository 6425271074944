import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiFillTag, AiFillDelete } from 'react-icons/ai/index.js';
import { IoMdArrowDropdown } from 'react-icons/io/index.js';
import { connect, useDispatch, useSelector } from 'react-redux';

import './Toolbar.scss';
import ModalDelete from '../app-dashboard/ModalDelete.js';
import ModalEditTag from '../app-dashboard/ModalEditTag.js';
import Checkbox from '../checkbox/Checkbox.js';
import EditDropdown from '../dropdown/EditDropdown.js';
import OffsetFilter from '../offset-filter/OffsetFilter.js';
import colors from '../../assets/colors.js';
import refreshIcon from '../../assets/images/refresh.png';
import { resetAnalyses } from '../../features/allAnalysesSlice.js';
import { fetchAllAnalyses, fetchAllTags } from '../../features/api/apiSlice.js';
import { selectAnalyses } from '../../features/filterSlice.js';
import { resetInput } from '../../features/inputSlice.js';
import { resetPagination, setPagination } from '../../features/paginationSlice.js';
import useDropdown from '../../hooks/useDropdown.js';
import useModal from '../../hooks/useModal.js';

function Toolbar({ ...props }) {
  const {
    page,
    nbSelectedRows,
    toggleAllPageRowsSelected,
    selectedFlatRows,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const wrapperRef = useRef(null);
  const { toggleDropdown, isOpen } = useDropdown(wrapperRef);
  const { isShowing, showModal, closeModal } = useModal();
  const [show, setShow] = useState(false);
  const [showDelModal, setShowDelModal] = useState(false);
  const { analyses } = useSelector((state) => state.allAnalyses);
  const [targetBtn, setTargetBtn] = useState('');
  const indeterminate = nbSelectedRows > 0 && nbSelectedRows !== page.length && true;
  const checked = nbSelectedRows === page.length;
  const limit = parseInt(process.env.REACT_APP_OFFSET_PAGINATION, 10);

  const onChange = (event) => {
    if (indeterminate && event.target.checked === true) {
      toggleAllPageRowsSelected(false);
    }
    if (checked === true) toggleAllPageRowsSelected(false);
    if (checked === false && !indeterminate) toggleAllPageRowsSelected(true);
  };

  const refreshTable = async () => {
    dispatch(resetAnalyses());
    dispatch(resetInput());
    dispatch(resetPagination());
    await dispatch(fetchAllTags()).unwrap();
    dispatch(fetchAllAnalyses(limit)).unwrap();
    dispatch(setPagination(1));
  };

  // show modals from child component
  useEffect(() => {
    if (show === true) showModal();
  }, [show]);
  useEffect(() => {
    if (showDelModal === true) showModal();
  }, [showDelModal]);

  const handleSelectedAll = selectedFlatRows.map((item) => analyses[item.id]);
  useEffect(() => {
    dispatch(selectAnalyses(handleSelectedAll));
  }, [selectedFlatRows]);

  function handleClick(e) {
    setTargetBtn(e.currentTarget.id);
    setShowDelModal(true);
  }

  return (
    <div className="filters-container">
      <div className="icon_ctr">
        <Checkbox
          title={t('Button.select')}
          checked={checked}
          indeterminate={indeterminate}
          className={(nbSelectedRows === page.length) !== false
            || (nbSelectedRows > 0 && nbSelectedRows !== page.length)
            ? 'checked-rows'
            : 'checkbox_icon'}
          onChange={(e) => {
            onChange(e, checked);
          }}
        />

        <button
          type="button"
          title={t('Button.refresh')}
          className="action_icon"
          tabIndex={0}
          onClick={refreshTable}
          data-testid="refresh_icon"
        >
          <img
            src={refreshIcon}
            height={20}
            width={20}
            alt="refresh"
          />
        </button>

        <button
          type="button"
          aria-label="Delete analysis"
          id="delete_analysis"
          title={t('Button.delete')}
          className="action_icon"
          disabled={nbSelectedRows < 1}
          onClick={(e) => handleClick(e)}
        >
          <AiFillDelete size={25} className={nbSelectedRows > 0 ? 'action-icon' : 'disabled-icon'} />
        </button>

        <button
          type="button"
          aria-label="Edit tag"
          title={t('Button.editTag')}
          className="action_icon"
          onClick={toggleDropdown}
        >
          <AiFillTag size={22} color={colors.dark_grey} />
          <IoMdArrowDropdown size={22} color={colors.dark_grey} />
        </button>
        {isOpen && (
        <div ref={wrapperRef} className="dropdown_content">
          <EditDropdown
            setShow={setShow}
            setShowDelModal={setShowDelModal}
            setTargetBtn={setTargetBtn}
          />
        </div>
        )}
        {show && (
        <ModalEditTag
          isShowing={isShowing}
          closeModal={closeModal}
          setShow={setShow}
        />
        )}
        {showDelModal && (
        <ModalDelete
          isShowing={isShowing}
          closeModal={closeModal}
          setShowDelModal={setShowDelModal}
          targetBtn={targetBtn}
        />
        )}
      </div>
      <div className="offset-ctr">
        <div className="offset-txt">
          {t('DashboardPage.nbAnalysesPerProduct')}
        </div>
        <OffsetFilter />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  analysesCSVResults: state.activities.analysesCSVResults,
  productsList: state.products.productsList,
});

export default connect(mapStateToProps)(Toolbar);
