/* eslint-disable max-len */
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import './ActivityFiltersContainer.scss';
import Button from '../components/button/Button.js';
import Checkbox from '../components/checkbox/Checkbox.js';
import DatePicker from '../components/date-picker/DatePicker.js';
import ModalHook from '../components/modal/ModalHook.js';
import SearchBar from '../components/searchbar/SearchBar.js';
import { resetActivityAnalyses } from '../features/activitySlice.js';
import { fetchActivityAnalyses } from '../features/api/apiSlice.js';
import { setFilter } from '../features/filterSlice.js';
import { setPagination } from '../features/paginationSlice.js';
import useModal from '../hooks/useModal.js';
import useReportValidation from '../hooks/useReportValidation.js';

function ActivityFiltersContainer(props) {
  const { t } = useTranslation();
  const { tagsList } = props;
  const { activityAnalyses } = useSelector((state) => state.activities);
  const { filteredItems } = useSelector((state) => state.filters);
  const {
    checkboxT, tagIds, timeStep, startDate, endDate,
  } = filteredItems;
  // timeStep = minutes
  const timeSteps = [30, 60, 120, 360, 720, 1440];

  const today = DateTime.now();

  const { isShowing, showModal, closeModal } = useModal();
  const location = useLocation();
  const dispatch = useDispatch();
  const {
    isDisabled, handleDateChange, eDate, isStartDateValid, isEndDateValid,
  } = useReportValidation();

  const [modalId, setModalId] = useState();
  const [input, setInput] = useState('');
  const [checkbox, setCheckbox] = useState(false);
  const [isValidBtn, setIsValidBtn] = useState(false);

  const handleSearch = (e) => {
    setInput(e.target.value);
  };

  // Handle button disable prop
  const isButtonDisabled = !isStartDateValid || !isEndDateValid;
  // startDate cannot be higher than endDate
  const maxDateForStart = eDate === null ? today : eDate;
  const disabled = !!(isDisabled || isButtonDisabled);

  const handleClick = async () => {
    props.setIsLoading(true);
    await props.setShowChart(false);
    dispatch(resetActivityAnalyses());
    await dispatch(fetchActivityAnalyses({
      tagIds, startDate, endDate, timeStep,
    })).unwrap();
    dispatch(setPagination(1));
    props.setIsFiltered(true);
    props.setIsLoading(false);
  };

  useEffect(() => {
    setIsValidBtn(false);
    dispatch(resetActivityAnalyses());
  }, [location]);

  useEffect(() => {
    // set button disabled prop based on tagIds array
    if (typeof tagIds === 'object' && tagIds.length > 0) {
      setIsValidBtn(true);
    } else {
      setIsValidBtn(false);
    }
  }, [tagIds]);

  useEffect(() => {
    if (activityAnalyses.length < 1) props.setNoDataMsg(true);
  }, [activityAnalyses]);

  const modalTitle = (
    <>
      {modalId === 'tag' && <>{t('ActivityFiltersContainer.tags')}</>}
      {modalId === 'timeStep' && <>{t('ActivityFiltersContainer.timeStep')}</>}
    </>
  );

  const handleClose = () => {
    setInput('');
    if (typeof filteredItems?.tagIds !== 'undefined' && tagIds.length > 0) {
      setIsValidBtn(true);
    } else {
      setIsValidBtn(false);
    }
    closeModal();
  };

  const handleChangeMultiSelect = (event) => {
    const { id, checked } = event.target;
    setCheckbox({ ...checkbox, [id]: checked });

    if (!checked) {
      dispatch(setFilter({
        ...filteredItems,
        tagIds: tagIds.filter((x) => x !== id),
        checkboxT: { ...checkboxT, [id]: false },
      }));
    } else {
      dispatch(setFilter({
        ...filteredItems,
        tagIds: typeof tagIds !== 'undefined' ? [...tagIds, id] : [id],
        checkboxT: { ...checkboxT, [id]: checked },
      }));
    }
  };

  const modalTag = tagsList
    .filter((item) => item.name.toLowerCase().includes(input.toLowerCase()))
    .map((item) => (
      <div className="item-filters" key={item.tagId}>
        <Checkbox
          checked={
            (typeof checkboxT !== 'undefined' && checkboxT[item.tagId]) || false
          }
          onChange={handleChangeMultiSelect}
          id={item.tagId}
          value={item.tagId}
          className="checkbox-filters"
        />
        <span className="item-list-filters">{item.name}</span>
      </div>
    ));

  const selectTimeStep = (time) => {
    dispatch(setFilter({ ...filteredItems, timeStep: time }));
    closeModal();
  };

  // Format timeStep
  const minutesToTime = (e) => {
    const h = Math.floor(e / 60)
      .toString()
      .padStart(2);
    const m = Math.floor(e).toString().padStart(2);

    if (h < 1) {
      return `${m} min`;
    }
    if (h > 0) {
      return `${h} h`;
    }
    return null;
  };

  const modalTimeStep = timeSteps.map((item) => (
    <div
      className={timeStep !== item ? 'modal-element' : 'selected-tag'}
      role="button"
      tabIndex={0}
      key={item}
      onClick={() => {
        handleClose();
        selectTimeStep(item);
      }}
      onKeyPress={() => {
        selectTimeStep(item);
      }}
    >
      {minutesToTime(item)}
    </div>
  ));

  const modalBody = (
    <>
      {modalId === 'tag' && (
        <div className="search-bar-container">
          <SearchBar
            className="dashboard-filters-searchbar"
            input={input}
            onChange={handleSearch}
            placeholder={t('ActivityFiltersContainer.searchBar')}
          />
        </div>
      )}
      {modalId === 'tag' && (
        <>
          <div className="filters-list">{modalTag}</div>
          <div className="filters-btn-container">
            <Button
              variant="contained"
              className="validate-checkedId"
              action={() => {
                handleClose();
              }}
            >
              {t('Button.validate')}
            </Button>
          </div>
        </>
      )}

      {modalId === 'timeStep' && (
        <div className="filters-list">{modalTimeStep}</div>
      )}
    </>
  );

  return (
    <>
      <div className="date-picker">
        <DatePicker
          label={t('AnalysisReportPage.startDate')}
          dateSelected="startDate"
          maxDate={maxDateForStart}
          datatestid="startDate"
          handleDateChange={handleDateChange}
        />
        <DatePicker
          label={t('AnalysisReportPage.endDate')}
          dateSelected="endDate"
          minDate={startDate}
          maxDate={today}
          datatestid="endDate"
          handleDateChange={handleDateChange}
        />
      </div>

      <Button
        variant="contained"
        className={isValidBtn ? 'activity-filtered-btn' : 'activity-btn'}
        action={() => {
          showModal();
          setModalId('tag');
        }}
      >
        <span
          className={isValidBtn ? 'activity-filtered-label' : 'activity-filter-label'}
        >
          {t('ActivityFiltersContainer.tags')}
        </span>
      </Button>

      <Button
        variant="contained"
        className={filteredItems?.timeStep ? 'activity-filtered-btn' : 'activity-btn'}
        action={() => {
          showModal();
          setModalId('timeStep');
        }}
      >
        <span
          className={filteredItems?.timeStep > 0
            ? 'activity-filtered-label'
            : 'activity-filter-label'}
        >
          {t('ActivityFiltersContainer.timeStep')}
        </span>
      </Button>

      <ModalHook title={modalTitle} isShowing={isShowing} hide={showModal} onClose={closeModal}>
        {modalBody}
      </ModalHook>

      <Button
        variant="contained"
        className={disabled !== false ? 'disabled-btn' : 'validate-btn'}
        disabled={disabled}
        action={handleClick}
      >
        {t('Button.validate')}
      </Button>
    </>
  );
}

const mapStateToProps = (state) => ({
  tagsList: state.tags.tagsList,
});

export default connect(mapStateToProps)(ActivityFiltersContainer);
