import React from 'react';
import { Pagination } from '@mui/material';

import './TablePagination.scss';

function TablePagination({ totalItems, paginate, page }) {
  return (
    <div className="pagination-root">
      <Pagination
        className="pagination-item"
        count={totalItems}
        size="small"
        page={page}
        onChange={paginate}
      />
    </div>
  );
}

export default TablePagination;
