import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import {
  AccordionDetails,
  AccordionSummary,
  FormControl,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import MuiAccordion from '@mui/material/Accordion/index.js';
import { styled } from '@mui/material/styles/index.js';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { setChartOptions } from '../../features/chartSlice.js';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  border: '1px solid #b9c0c4',
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

function ChartTitlePicker({ hidden }) {
  const { options } = useSelector((state) => state.charts);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [mainTitle, setMainTitle] = useState(t('Chart.title'));
  const [axisXTitle, setAxisXTitle] = useState('');
  const [axisYTitle, setAxisYTitle] = useState('');

  const handleChangeChartMainTitle = (event) => {
    setMainTitle(event.target.value);
  };

  const handleChangeChartAxisXTitle = (event) => {
    setAxisXTitle(event.target.value);
  };

  const handleChangeChartAxisYTitle = (event) => {
    setAxisYTitle(event.target.value);
  };

  const handleChangeChartTitle = () => {
    if (options.plugins.title.text !== mainTitle
      || options.scales.x.title.text !== axisXTitle
      || options.scales.y.title.text !== axisYTitle) {
      dispatch(setChartOptions({
        ...options,
        plugins: {
          ...options.plugins,
          title: {
            ...options.plugins.title,
            text: mainTitle,
            display: true,
          },
        },
        scales: {
          ...options.scales,
          x: {
            ...options.scales.x,
            display: true,
            title: {
              ...options.scales.x.title,
              display: true,
              text: axisXTitle,
              font: {
                size: 17,
              },
            },
          },
          y: {
            ...options.scales.y,
            display: true,
            title: {
              ...options.scales.y.title,
              display: true,
              text: axisYTitle,
              font: {
                size: 17,
              },
            },
          },
        },
      }));
    }
  };

  return hidden ? (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>{t('Chart.chartTitlePicker.title')}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container direction="column" spacing={1.5} sx={{ p: 2 }}>
          <Grid item>
            <FormControl fullWidth>
              <TextField
                id="title"
                value={mainTitle}
                label={t('Chart.chartTitlePicker.inputTitle')}
                onKeyDown={(ev) => {
                  if (ev.key === 'Enter') {
                    ev.preventDefault();
                    setMainTitle(ev.target.value);
                    handleChangeChartTitle();
                  }
                }}
                onChange={handleChangeChartMainTitle}
                variant="outlined"
                onBlur={handleChangeChartTitle}
              />
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl fullWidth>
              <TextField
                id="axisX"
                value={axisXTitle}
                label={t('Chart.chartTitlePicker.inputXAxisTitle')}
                onKeyDown={(ev) => {
                  if (ev.key === 'Enter') {
                    ev.preventDefault();
                    setAxisXTitle(ev.target.value);
                    handleChangeChartTitle();
                  }
                }}
                onChange={handleChangeChartAxisXTitle}
                variant="outlined"
                onBlur={handleChangeChartTitle}
              />
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl fullWidth>
              <TextField
                id="axisY"
                value={axisYTitle}
                label={t('Chart.chartTitlePicker.inputYAxisTitle')}
                onKeyDown={(ev) => {
                  if (ev.key === 'Enter') {
                    ev.preventDefault();
                    setAxisYTitle(ev.target.value);
                    handleChangeChartTitle();
                  }
                }}
                onChange={handleChangeChartAxisYTitle}
                variant="outlined"
                onBlur={handleChangeChartTitle}
              />
            </FormControl>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
    : null;
}

export default ChartTitlePicker;
