import React from 'react';

function SearchBar({
  className,
  isFiltered,
  placeholder,
  handleSearch,
  ...props
}) {
  return (
    <input
      className={className}
      type="text"
      placeholder={placeholder}
      onChange={handleSearch}
      {...props}
    />
  );
}

export default SearchBar;
