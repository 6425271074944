/* eslint-disable max-len */
export const fileUploadProgress = (fileIndex, progress, setTransmission) => {
  setTransmission((prevTransmission) => {
    const updatedTransmission = prevTransmission.map((file, index) => {
      if (index === fileIndex) {
        return {
          ...file,
          progress,
        };
      }
      return file;
    });
    return updatedTransmission;
  });
};

export const calculateUploadProgress = (transmission) => {
  const activeFiles = transmission.filter((file) => !file.cancel && typeof file.progress === 'number' && !Number.isNaN(file.progress));
  const canceledFiles = transmission.filter((file) => file.cancel);

  if (canceledFiles.length === transmission.length) {
    const averageProgress = canceledFiles.reduce((sum, file) => sum + (file.progress || 0), 0) / transmission.length;
    return Number.isNaN(averageProgress) ? 0 : parseInt(averageProgress, 10);
  }

  const totalSize = transmission.reduce((sum, file) => sum + file.size, 0);
  const currentUpload = activeFiles.reduce((sum, file) => sum + (file.size * file.progress) / 100, 0);
  const progressPercentage = (currentUpload / totalSize) * 100;
  return Number.isNaN(progressPercentage) ? 0 : parseInt(progressPercentage, 10);
};

export const cancelFile = (index, setTransmission) => {
  setTransmission((prevTransmission) => {
    if (index >= prevTransmission.length
        || (index >= 0 && index < prevTransmission.length)) {
      const updatedTransmission = prevTransmission.map((file, fileIndex) => {
        if ((index >= prevTransmission.length)
        || (fileIndex === index && file.progress < 100)) {
          return {
            ...file,
            cancel: true,
          };
        }
        return file;
      });
      return updatedTransmission;
    }
    return prevTransmission;
  });
};

export const uncancelFile = (index, setTransmission) => {
  setTransmission((prevTransmission) => {
    if (index >= prevTransmission.length
        || (index >= 0 && index < prevTransmission.length)) {
      const uncancelTransmission = prevTransmission.map((file, fileIndex) => {
        if ((index >= prevTransmission.length)
        || (fileIndex === index && file.progress < 100)) {
          return {
            ...file,
            cancel: false,
          };
        }
        return file;
      });
      return uncancelTransmission;
    }
    return prevTransmission;
  });
};
