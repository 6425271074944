import React from 'react';

import { currentLanguage } from '../translate/languages.js';

function DateCell({ value }) {
  return (
    <div className="date-item">
      {new Intl.DateTimeFormat(currentLanguage().dateTimeFormat, {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      }).format(new Date(value))}
    </div>
  );
}

export default DateCell;
