import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ActivityReportCell from './ActivityReportCell.js';
import './AnalysisReportTable.scss';
import CustomCell from '../table/CustomCell.js';
import Table from '../table/Table.js';
import i18n from '../translate/i18n.js';
import { currentLanguage } from '../translate/languages.js';
import { analysesKeysMap, analysesKeysMapEN } from '../../assets/analysesKeys.js';
import { setActivityCsv } from '../../features/activitySlice.js';

function ActivityReportTable() {
  const { activityAnalyses } = useSelector((state) => state.activities);
  const { product } = useSelector((state) => state.products);
  const { tagsList } = useSelector((state) => state.tags);
  const dispatch = useDispatch();
  const {
    tagId, periodStartDate, periodEndDate, imageArea,
  } = i18n.language === 'fr' ? analysesKeysMap : analysesKeysMapEN;

  // Translate object keys with french names for columns header
  const objD = activityAnalyses.length > 0 && activityAnalyses[0];
  const translatedHeader = Object.keys(objD).reduce(
    (acc, key) => ({
      ...acc,
      ...(i18n.language === 'fr'
        ? { [analysesKeysMap[key] || key]: objD[key] }
        : { [analysesKeysMapEN[key] || key]: objD[key] }),
    }),
    {},
  );

  const porcineObjOrder = {
    [tagId]: null,
    [periodStartDate]: null,
    [periodEndDate]: null,
    [imageArea]: null,
  };

  const defaultObjOrder = {
    [tagId]: null,
  };

  const sortedHeader = product.productName?.includes('porcineactivity')
    ? Object.assign(porcineObjOrder, translatedHeader)
    : Object.assign(defaultObjOrder, translatedHeader);

  // Translate activities
  const objActivities = activityAnalyses.length > 0 && activityAnalyses[0].activities;
  const renameActivities = Object.keys(objActivities).reduce(
    (acc, key) => ({
      ...acc,
      ...(i18n.language === 'fr'
        ? { [analysesKeysMap[key] || key]: objActivities[key] }
        : { [analysesKeysMapEN[key] || key]: objActivities[key] }),
    }),
    {},
  );

  // Flatten activities obj and concat header + count and ratio
  const flattenObj = (obj, parent, res = {}) => {
    Object.keys(obj).map((key) => {
      const translateKey = i18n.language === 'fr'
        && (key === 'count' || key === 'ratio')
        && analysesKeysMap[key];
      const propName = parent ? `${parent} (${translateKey || key})` : key;
      if (typeof obj[key] === 'object') {
        flattenObj(obj[key], propName, res);
      } else {
        res[propName] = obj[key];
      }
      return res;
    });
    return res;
  };

  const isEmpty = Object.values(renameActivities).some(
    (x) => x === null && x === '',
  );
  const flattenActivitiesHeader = isEmpty === false && flattenObj(renameActivities);

  // Map columns depending on selected product
  const columns = useMemo(() => [
    activityAnalyses.length > 0
        && Object.keys(sortedHeader).map((key) => ({
          Header: key,
          accessor: key,
          show: key === 'activities' && false,
          Cell: (value) => (
            ActivityReportCell(value, key, tagsList, tagId, periodStartDate, periodEndDate)
          ),
        })),
    activityAnalyses.length > 0
        && Object.keys(flattenActivitiesHeader).map((key) => ({
          Header: key,
          accessor: key,
          Cell: CustomCell,
        })),
  ].flat());

  const preData = [];
  // set table data for activity product
  activityAnalyses
    .map((item, i) => {
      // Loop on activities and rename keys
      const obj = activityAnalyses.length > 0 && activityAnalyses[i];
      const renameKeys = Object.keys(obj).reduce(
        (acc, key) => ({
          ...acc,
          ...(i18n.language === 'fr'
            ? { [analysesKeysMap[key] || key]: obj[key] }
            : { [analysesKeysMapEN[key] || key]: obj[key] }),
        }),
        {},
      );
      // Loop on activities and rename keys
      const objDataActivities = activityAnalyses.length > 0 && activityAnalyses[i].activities;
      const renameDataActivities = Object.keys(objDataActivities).reduce(
        (acc, key) => ({
          ...acc,
          ...(i18n.language === 'fr'
            ? { [analysesKeysMap[key] || key]: objDataActivities[key] }
            : { [analysesKeysMapEN[key] || key]: objDataActivities[key] }),
        }),
        {},
      );
      // Flatten data activities obj and concat header + count and ratio
      const flattenActivities = flattenObj(renameDataActivities);

      preData.push(
        // Flatten concat objects and rendering data in table
        Object.assign(
          flattenActivities,
          ...(function _flatten(o) {
            return [].concat(
              ...Object.keys(o).map((k) => {
                if (o[k] === null) {
                  return { [k]: '-' };
                }
                if (typeof o[k] === 'object') {
                  return _flatten(o[k]);
                }
                return { [k]: o[k] };
              }),
            );
          }(renameKeys, flattenActivities)),
        ),
      );
      return preData;
    })
    .filter((item, i, a) => a.indexOf(item) === i);

  const data = useMemo(() => preData);

  // sort data for csv output
  const sortedCSVData = [];
  data.map((item) => {
    const newObj = product.productName.includes('porcineactivity')
      ? Object.assign(porcineObjOrder, item)
      : Object.assign(defaultObjOrder, item);
    const {
      activities,
      lyingMeanCount,
      standingInContactWithBeddingMeanCount,
      atTroughMeanCount,
      atDrinkingTroughMeanCount,
      standingInMotionMeanCount,
      standingStaticMeanCount,
      proneLyingMeanCount,
      lyingOnTheSideMeanCount,
      sittingMeanCount,
      eatingMeanCount,
      drinkingMeanCount,
      ...popObj
    } = newObj;

    // Translate tagId into tag name
    if (popObj.Tag !== '-') {
      popObj.Tag = tagsList
        .map((itm) => itm.tagId === item.Tag && itm.name)
        .filter(Boolean)
        .join('');
    }

    if (typeof popObj[periodStartDate] === 'number') {
      popObj[periodStartDate] = new Date(
        popObj[periodStartDate],
      ).toLocaleDateString(currentLanguage().dateTimeFormat, {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      });
    }

    if (typeof popObj[periodEndDate] === 'number') {
      popObj[periodEndDate] = new Date(
        popObj[periodEndDate],
      ).toLocaleDateString(currentLanguage().dateTimeFormat, {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      });
    }

    sortedCSVData.push(popObj);

    return sortedCSVData;
  });

  useEffect(() => {
    dispatch(setActivityCsv(sortedCSVData));
  }, [activityAnalyses, i18n.language]);

  return activityAnalyses.length > 0 ? (
    <div className="analysisReport-table-container">
      <Table
        columns={columns}
        data={data}
        showFilters={false}
        chart
        data-testid="activity-table"
      />
    </div>
  )
    : null;
}

export default ActivityReportTable;
