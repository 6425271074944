import React from 'react';
import { AiOutlineInfoCircle } from 'react-icons/ai/index.js';

import './TooltipModal.scss';
import ModalHook from '../modal/ModalHook.js';
import colors from '../../assets/colors.js';
import useModal from '../../hooks/useModal.js';

function TooltipModal({ content }) {
  const { showModal, isShowing, closeModal } = useModal();

  return (
    <>
      <AiOutlineInfoCircle
        color={colors.white}
        size={20}
        onClick={showModal}
        onKeyDown={showModal}
        tabIndex={0}
        alt="error_info"
        data-testid="error_info"
      />
      <ModalHook
        isShowing={isShowing}
        hide={showModal}
        onClose={closeModal}
      >
        <div className="modal_content">
          <div className="icon_info"><AiOutlineInfoCircle color={colors.lightgrey2} size={25} /></div>
          <div className="error_detail">{content}</div>
        </div>
      </ModalHook>
    </>
  );
}

export default TooltipModal;
