import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/es/storage/index.js';

import activityReducer from '../features/activitySlice.js';
import allAnalysesReducer from '../features/allAnalysesSlice.js';
import analysesReducer from '../features/analysisSlice.js';
import { apiSlice } from '../features/api/apiSlice.js';
import chartsReducer from '../features/chartSlice.js';
import csvReducer from '../features/csvSlice.js';
import filterReducer from '../features/filterSlice.js';
import groupsReducer from '../features/groupSlice.js';
import inputReducer from '../features/inputSlice.js';
import paginationReducer from '../features/paginationSlice.js';
import productsReducer from '../features/productSlice.js';
import tagsReducer from '../features/tagSlice.js';
import userReducer from '../features/userSlice.js';

const rootPersistConfig = {
  key: 'root',
  storage,
  blacklist: ['upload', 'products', 'tags', 'results', 'filters', 'pagination', 'user', 'charts'],
};

const authPersistConfig = {
  key: 'user',
  storage,
  blacklist: ['error'],
};

const productsPersistConfig = {
  key: 'products',
  storage,
  blacklist: ['error', 'product'],
};

const tagsPersistConfig = {
  key: 'tags',
  storage,
  blacklist: ['tagsList', 'error', 'errorCreate'],
};

const allAnalysesPersistConfig = {
  key: 'allAnalyses',
  storage,
  blacklist: ['analyses', 'error'],
};

const inputsPersistConfig = {
  key: 'inputs',
  storage,
  blacklist: ['input', 'error'],
};

const activitiesPersistConfig = {
  key: 'activities',
  storage,
  blacklist: ['analysesResults', 'analysesCSVResults', 'error'],
};

const chartsPersistConfig = {
  key: 'charts',
  storage,
  blacklist: ['error'],
};

const analysesPersistConfig = {
  key: 'analyses',
  storage,
  blacklist: ['analysesResults', 'totalAnalyses', 'error'],
};

const appReducer = combineReducers({
  user: persistReducer(authPersistConfig, userReducer),
  products: persistReducer(productsPersistConfig, productsReducer),
  groups: groupsReducer,
  tags: persistReducer(tagsPersistConfig, tagsReducer),
  allAnalyses: persistReducer(allAnalysesPersistConfig, allAnalysesReducer),
  inputs: persistReducer(inputsPersistConfig, inputReducer),
  activities: persistReducer(activitiesPersistConfig, activityReducer),
  analyses: persistReducer(analysesPersistConfig, analysesReducer),
  charts: persistReducer(chartsPersistConfig, chartsReducer),
  csvData: csvReducer,
  filters: filterReducer,
  pagination: paginationReducer,
  [apiSlice.reducerPath]: apiSlice.reducer,
});

const rootReducer = (state, action) => appReducer(state, action);

export default persistReducer(rootPersistConfig, rootReducer);
