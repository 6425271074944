import { Button } from '@mui/material';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

export default function ContainedButtons({
  children,
  title,
  variant,
  className,
  color,
  size,
  action,
  disabled,
  type,
  fullWidth,
  name,
}) {
  const { classes } = useStyles();
  const disabledProp = Boolean(disabled);

  return (
    <div className={classes.root}>
      <Button
        sx={{ color: '#000000', margin: '8px', textTransform: 'none' }}
        title={title}
        variant={variant}
        className={className}
        color={color}
        size={size}
        onClick={action}
        disabled={disabledProp}
        type={type}
        fullWidth={fullWidth}
        name={name}
        disableElevation
      >
        {children}
      </Button>
    </div>
  );
}
