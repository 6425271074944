const colors = {
  green: '#33A237',
  green_hover: '#2f9638',
  white: '#ffffff',
  light_grey: '#f2f2f2',
  lightgrey2: '#707070',
  border_grey: '#e5e5e5',
  dark_grey: '#3a3a3a',
  loader: '#595959',
  errors: '#f20505',
  black: '#000000',
};
export default colors;
