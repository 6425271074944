/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import instance from '../services/instanceApi.js';

const initialState = {
  productsList: [],
  product: [],
  status: null,
  error: null,
};

export const fetchProducts = createAsyncThunk(
  'products/fetch',
  async () => {
    try {
      const response = await instance.get('/products?executable=true');
      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  },
);

export const productSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    selectProduct: (state, { payload }) => {
      state.product = payload;
    },
    resetProduct: (state) => {
      state.product = initialState.product;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProducts.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.productsList = action.payload;
      })
      .addCase(fetchProducts.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export const { setProducts, selectProduct, resetProduct } = productSlice.actions;
export default productSlice.reducer;
