import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/es/integration/react.js';

import './App.scss';
import Footer from './components/footer/Footer.js';
import Navbar from './components/navbar/Navbar.js';
import './components/translate/i18n.js';
import BrowserBtnHandler from './navigation/BrowserBtnHandler.js';
import RouterConfig from './navigation/RouterConfig.js';
import SetupInterceptors from './services/axiosApi.js';
import store from './store/configureStore.js';

const persistor = persistStore(store);

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <PersistGate loading={null} persistor={persistor}>
          <div className="App">
            <main className="main-content">
              <Navbar />
              <RouterConfig />
            </main>
            <Footer />
            <SetupInterceptors />
            <BrowserBtnHandler />
          </div>
        </PersistGate>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
