/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import { IconButton } from '@mui/material';
import { BarChart as BarChartIcon, Download as DownloadIcon } from '@mui/icons-material';
import React, { useState, useEffect } from 'react';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';
import {
  IoMdArrowDropright,
  IoMdArrowDropup,
  IoMdArrowDropdown,
} from 'react-icons/io/index.js';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  useTable, usePagination, useSortBy, useFilters, useRowSelect,
} from 'react-table';

import './Table.scss';
import TableCheckboxCell from './TableCheckboxCell.js';
import Chart from '../chart/Chart.js';
import TablePagination from '../table-pagination/TablePagination.js';
import Toolbar from '../table-toolbar/Toolbar.js';
import colors from '../../assets/colors.js';
import { resetChart } from '../../features/chartSlice.js';
import { selectAnalyses } from '../../features/filterSlice.js';
import { setPagination } from '../../features/paginationSlice.js';

function TableAdvanced({
  columns,
  data,
  showFilters,
  showPagination,
  chart,
}) {
  const { t } = useTranslation();
  const [itemPerPage] = useState(10);
  const [showChart, setShowChart] = useState(false);
  const [isPage, setPage] = useState(1);
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const location = useLocation();
  const { pathname } = location;
  const { analyses } = useSelector((state) => state.allAnalyses);
  const { activityAnalysesCsv } = useSelector((state) => state.activities);
  const { currentPage } = useSelector((state) => state.pagination);

  // push selected analyses into array
  let selected = [];
  const getTagId = async (event, row) => {
    const { checked } = event.target;
    if (!checked) {
      selected = selected.filter((x) => x?.id !== analyses[row.id]?.id);
    } else {
      selected = [...selected, analyses[row.id]];
    }
    dispatch(selectAnalyses(selected));
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    pageOptions,
    toggleAllRowsSelected,
    toggleAllPageRowsSelected,
    selectedFlatRows,
    state: { selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: parseInt(process.env.REACT_APP_OFFSET_PAGINATION, 10),
        hiddenColumns: ['activities'],
      },
      autoResetFilters: false,
      autoResetSortBy: false,
      autoResetSelectedRows: true,
      stateReducer: (newState, action) => {
        switch (action.type) {
          case 'toggleAllRowsSelected':
            return {
              ...newState,
              selectedRowIds: {},
            };
          default:
            return newState;
        }
      },
    },
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      // eslint-disable-next-line no-unused-expressions
      showFilters && hooks.visibleColumns.push(() => [
        {
          id: 'selection',
          Header: '',
          Cell: ({ row }) => (
            TableCheckboxCell(row, getTagId)
          ),
          disableSortBy: true,
        },
        ...columns,
      ]);
    },
  );

  const nbSelectedRows = Object.keys(selectedRowIds).length;

  const paginate = (event, value) => {
    dispatch(setPagination(value));
    gotoPage(value - 1);
  };

  const handlePaginationChange = (event, value) => {
    paginate(undefined, value);
    setPage(value);
    gotoPage(value - 1);
  };

  const handleClickPopper = (e) => {
    if (chart) {
      setAnchorEl(anchorEl ? null : e.currentTarget);
    } else {
      setAnchorEl(null);
    }
  };

  const handleChart = async () => {
    setShowChart(!showChart);
  };

  useEffect(() => {
    paginate(undefined, currentPage);
    setPage(currentPage);
  }, [currentPage]);

  useEffect(() => {
    toggleAllRowsSelected(false);
  }, [page]);

  useEffect(() => {
    dispatch(resetChart());
  }, []);

  const handleClick = () => dispatch(setPagination(1));

  return (
    <>
      {showFilters !== false && (
        <div>
          <Toolbar page={page} nbSelectedRows={nbSelectedRows} toggleAllPageRowsSelected={toggleAllPageRowsSelected} selectedFlatRows={selectedFlatRows} />
        </div>
      )}
      <div className="table-ctr" role="button" ref={anchorEl} tabIndex={0} onClick={handleClickPopper} onKeyPress={handleClickPopper}>
        <table {...getTableProps()} className="table">
          <thead>
            {headerGroups.map((headerGroup, i) => (
              <tr key={headerGroup.headers[i].id} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    valign="middle"
                    className="table-header"
                    key={column.id}
                    {...column.getHeaderProps()}
                  >
                    <div className="table-header-group">
                      <div>{column.render('Header')}</div>
                      {column.disableSortBy !== true && (
                        <div
                          role="button"
                          tabIndex={0}
                          onClick={handleClick}
                          onKeyDown={handleClick}
                        >
                          <span {...column.getHeaderProps(column.getSortByToggleProps())}>
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <IoMdArrowDropdown data-testid="sort_icon_down" />
                              ) : (
                                <IoMdArrowDropup data-testid="sort_icon_up" />
                              )
                            ) : (
                              <IoMdArrowDropright data-testid="sort_icon" />
                            )}
                          </span>
                        </div>
                      )}
                      <div className="filters-btn-ctr">
                        {column.Filter ? (<div key={column.id}>{column.render('Filter')}</div>) : null}
                      </div>
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {page.length === 0 && data.length > 0 && (
              <tr>
                <td className="noRes-found" data-testid="no-results">
                  {t('DashboardPage.dashboardTable.noResultsFound')}
                </td>
              </tr>
            )}
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} className="table-row" data-testid="data_cells">
                  {row.cells.map((cell) => (
                    <td className="table-cell" {...cell.getCellProps()} data-testid="cell">
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {showPagination !== false && (
      <div className="footer-table-ctr">
        <div className="footer-tbl-pagination">
          <TablePagination
            paginate={handlePaginationChange}
            page={isPage}
            itemPerPage={itemPerPage}
            totalItems={pageOptions.length}
          />
        </div>
        <div className="option-footer-table-ctr">
          {chart && (
          <IconButton onClick={handleChart} className="chart-button" style={{ backgroundColor: showChart ? colors.green : colors.white }}>
            <BarChartIcon style={{ color: showChart ? colors.white : colors.dark_grey }} fontSize="small" />
          </IconButton>
          )}

          {pathname === '/analysis-report' && (
            <CSVLink
              filename="AnalysisReport.csv"
              data={activityAnalysesCsv}
              target="_blank"
            >
              <IconButton className="csv-icon">
                <DownloadIcon fontSize="small" />
              </IconButton>
            </CSVLink>
          )}
        </div>
      </div>
      )}
      {showChart && chart && (<Chart />)}
    </>
  );
}

export default TableAdvanced;
